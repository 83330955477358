<div class="main-section">
    <h1>{{ 'users.title_list' | translate }}</h1>
    
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'users.filter' | translate }}">
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.id' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
            </ng-container>

            <ng-container matColumnDef="headquarter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.headquarter' | translate }} </th>
                <td mat-cell *matCellDef="let element" translate> users.{{element.headquarter}} </td>
            </ng-container>

            <ng-container matColumnDef="username">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.username' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.username}} </td>
            </ng-container>

            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.email' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.email}} </td>
            </ng-container>

            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'users.role' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.role}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="accent" routerLink="/user-edit/{{element.id}}">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>