import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProjectListComponent } from '../projects/project-list/project-list.component';

export interface ProjectTable {
  id: number;
  code: string;
  name: string;
  task_amount: string;
  closed: string;
  up: string;
  up_id: any;
  fund: any;
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  private API_URL = environment.API_URL;
  public projectList: ProjectListComponent;
  public projectCloseEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public projectOpenEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient
  ) { }

  getProjects(): Observable<ProjectTable[]> {
    return this.http.get<ProjectTable[]>(`${this.API_URL}/projects`);
  }

  getProjectById(id: string) {
    return this.http.get(`${this.API_URL}/projects/` + id);
  }

  addProject(event: any) {
    return this.http.post<any>(`${this.API_URL}/projects`, {
      event
    });
  }

  updateProject(id: string, event: any) {
    let code = event.code;
    let name = event.name;
    const up = event.up;

    return this.http.put<any>(`${this.API_URL}/projects/` + id, {
      code,
      name,
      up
    });
  }

  deleteProject(id: string) {
    return this.http.delete<any>(`${this.API_URL}/projects/` + id);
  }

  closeProjectById(id) {
    return this.http.get(`${this.API_URL}/projects/closeProjectById/` + id);
  }

  openProjectById(id) {
    return this.http.get(`${this.API_URL}/projects/openProjectById/` + id);
  }

  unfundProjectById(id) {
    return this.http.get(`${this.API_URL}/projects/unfundProjectById/` + id);
  }

  fundProjectById(id) {
    return this.http.get(`${this.API_URL}/projects/fundProjectById/` + id);
  }

  setProjectList(projectList: ProjectListComponent) {
    this.projectList = projectList;
  }

  getProjectList() {
    return this.projectList;
  }

  getProjectCloseEvent() {
    return this.projectCloseEvent;
  }

  getProjectOpenEvent() {
    return this.projectOpenEvent;
  }

  changeUPToProjectById(up_id, id){
    return this.http.post(`${this.API_URL}/projects/changeUPToProjectById/` + id,
    {
      up_id
    });
  }

}


