<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="section-header" *ngIf="!loadingFlag">
        <h1>{{ 'invoices.title_add' | translate }}</h1>
    </div>

    <div class="form-container" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                <mat-card fxFlex="50%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" >
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='user' placeholder="{{ 'invoices.user' | translate }}"
                                name="user" readonly required>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="date" matInput [(ngModel)]="invoice_date" placeholder="{{ 'invoices.invoice_date' | translate }}"
                                name="invoice_date" required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto" 
                                   name="project" #projectField="ngModel" 
                                   placeholder="{{ 'invoices.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'invoices.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>
                        
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <mat-select placeholder="{{ 'invoices.task' | translate }}" name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let task of tasks" [value]="task.id"> 
                                    {{ task.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="description"
                                placeholder="{{ 'invoices.description' | translate }}" name="description"
                                value="{{description}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="pay_to"
                                placeholder="{{ 'invoices.pay_to' | translate }}" name="pay_to"
                                value="{{pay_to}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field class="input-width">
                            <input type="number" matInput [(ngModel)]="amountWithTax"
                                placeholder="{{ 'invoices.amount_with_tax' | translate }}" name="amountWithTax"
                                value="{{amountWithTax}}" (ngModelChange)='calculateAmountWithoutTax()' required>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-select placeholder="{{ 'invoices.tax' | translate }}" name="tax" [(ngModel)]="tax" (ngModelChange)='calculateAmountWithoutTax()'>
                                <mat-option *ngFor="let tax_type of tax_types" [value]="tax_type.id"> 
                                    {{ tax_type.type }} %
                                </mat-option>
                            </mat-select>
                            <span>
                                <mat-icon class="tooltip-info-title" aria-hidden="false" aria-label="help"
                                    matTooltip="{{ 'invoices.info_tax_type' | translate }}" matTooltipClass="tooltip" matTooltipPosition="above">
                                    help_outline
                                </mat-icon>
                            </span>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <input type="number" matInput [(ngModel)]="amountNoTax"
                                placeholder="{{ 'invoices.amount_no_tax' | translate }}" name="amountNoTax"
                                value="{{amountNoTax}}" readonly required>
                            <mat-hint class="error-hint" *ngIf="!validateNoTax">{{'invoices.error_no_tax' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column">
                        <mat-form-field>
                            <mat-label>{{ 'invoices.invoice_type' | translate }} *</mat-label>
                            <mat-select name="invoice_type" [(ngModel)]="type">
                                <mat-option *ngFor="let value of types" [value]="value.id">
                                    {{ 'invoices.' + value.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="comments"
                                placeholder="{{ 'invoices.comments' | translate }}" name="comments"
                                value="{{comments}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" class="checkbox-wrapper">
                        <mat-checkbox [(ngModel)]="review" name="review" (ngModelChange)="reviewChange()">{{'invoices.review' | translate}}</mat-checkbox>
                        <span>
                            <mat-icon class="tooltip-info-title-review" aria-hidden="false" aria-label="help"
                                matTooltip="{{ 'invoices.info_review' | translate }}" matTooltipClass="tooltip" matTooltipPosition="above">
                                help_outline
                            </mat-icon>
                        </span>
                    </div>
                    


                    <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-raised-button class="top-buffer bottom-buffer" type="submit" color="accent"
                            [disabled]="!f.valid || !validateProject || !type || !image">{{ 'invoices.save_button' | translate }}</button>
                    </div>
                </mat-card>

                <mat-card fxFlex="50%" class="image-card" *ngIf="!noImage">
                    <img class="invoice-image" [src]="image" *ngIf="!isPDF">
                    <!-- pdf component -->
                    <ngx-extended-pdf-viewer class="overflow-pdf"
                        [src]="pdf"
                        [showToolbar]="true"
                        [showSidebarButton]="false"
                        [showFindButton]="false"
                        [showPagingButtons]="false"
                        [showZoomButtons]="true"
                        [showPresentationModeButton]="false"
                        [showOpenFileButton]="false"
                        [showPrintButton]="true"
                        [showDownloadButton]="true"
                        [showBookmarkButton]="false"
                        [showSecondaryToolbarButton]="false"
                        [showRotateButton]="false"
                        [showHandToolButton]="false"
                        [showScrollingButton]="false"
                        [showSpreadButton]="false"
                        [showPropertiesButton]="false"
                        [useBrowserLocale]="true" style="display: block; height: 500px;" zoom="75%"
                        *ngIf="isPDF">
                    </ngx-extended-pdf-viewer>
                    <div class="section-footer" id="flex-start">
        

                        <p *ngIf="imageError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                        <p *ngIf="extensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                        <div>
                            <input type="file" id="invoiceImage" [hidden]=true name="invoiceImage" ngModel
                                (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                            <label class="upload-label" for="invoiceImage">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image_add' | translate
                                }}
                            </label>
                            <input type="file" id="invoicePDF" [hidden]=true name="invoicePDF" ngModel
                                (change)="loadPDF($event)" accept=".pdf">
                            <label class="upload-label label-background-pdf" for="invoicePDF">
                                <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf_add' | translate
                                }}
                            </label>
                        </div>
                    </div>
                </mat-card>

                <mat-card fxFlex="50%" class="no-image-card" *ngIf="noImage">
                    <span>{{ 'invoices.no_image' | translate }}</span>
                    <div class="section-footer" id="flex-start">
        

                        <p *ngIf="imageError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                        <p *ngIf="extensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                        <div>
                            <input type="file" id="invoiceImage" [hidden]=true name="invoiceImage" ngModel
                                (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                            <label class="upload-label" for="invoiceImage">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image_add' | translate
                                }}
                            </label>
                            <input type="file" id="invoicePDF" [hidden]=true name="invoicePDF" ngModel
                                (change)="loadPDF($event)" accept=".pdf">
                            <label class="upload-label label-background-pdf" for="invoicePDF">
                                <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf_add' | translate
                                }}
                            </label>
                        </div>
                    </div>
                </mat-card>
                
            </div>
        </form>
    </div>

    <div class="section-footer" id="flex-end" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag" >
    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
