import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../_services/role.service';
import { LanguageService } from '../_services/language.service';
import { ManagerService } from '../_services/manager.service';
import { HeadquartersService } from '../_services/headquarters.service';

export interface RolesData {
  id: number;
  name: string;
}

export interface LanguageData {
  id: number;
  name: string;
  code: string;
}

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  protected id: string;
  username: string;
  email: string;
  role: number;
  newPassword: string;
  loadingFlag: boolean = false;
  roles: any;
  languages: any;
  isAdmin: boolean = false;
  headquarters: any [] = [];
  headquarter: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private roleService: RoleService,
    private languageService: LanguageService,
    private managerService: ManagerService,
    private headquarterService: HeadquartersService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadingFlag = true;
    const routeParams = this.activatedRoute.snapshot.params;
    this.id = routeParams.id;
    this.getUserById(routeParams.id);
    this.managerService.setVoidFilter();

    if (this.id == '1') {
      this.isAdmin = true;
    }

    this.roleService.getRoles()
      .subscribe(res => {
        let roles: RolesData[] = res['roles'];
        this.roles = roles.map(function (obj) {
          let temp = {};
          temp['id'] = obj.id;
          temp['name'] = obj.name.toLowerCase();
          return temp;
        });
      });

    this.languageService.getLanguages()
      .subscribe(res => {
        let languages: LanguageData[] = res['languages'];
        this.languages = languages;
      });

    this.headquarterService.getHeadquarters()
      .subscribe(res => {
        this.headquarters = res['headquarters'];
      });
  }

  getUserById(id: string) {
    this.userService.getUserById(id)
      .subscribe(res => {
        this.username = res['username'];
        this.email = res['email'];
        this.role = res['role'];
        this.headquarter = res['headquarter'];

        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.userService.updateUser(this.id, event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            
            this.toastr.success(this.translate.instant('users.success_user_edit'), '', { timeOut: 3000 });
            this.router.navigate(['/user-list']);
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('users.error_user_edit'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }
}
