import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from 'src/app/_services/invoices.service';
import {Location} from '@angular/common';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { Observable } from 'rxjs';
import { Role } from 'src/app/_models/role';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';
import blobPdfFromBase64String from 'src/app/_helpers/blobPdfFromBase64String';


@Component({
  selector: 'app-invoice-view',
  templateUrl: './invoice-view.component.html',
  styleUrls: ['./invoice-view.component.scss']
})
export class InvoiceViewComponent implements OnInit {
  protected invoiceId: string;
  loadingFlag: boolean = false;

  user: string;
  project: string;
  date: string;
  task: string;
  description: string;
  amountWithTax: string;
  tax: string;
  amountNotax: string;
  currentUser: Observable<any>;
  invoice: any[] = [];

  image: string;
  noImage: boolean = false;
  username: string;
  role: any;
  isPDF: boolean = false;
  pdf: Blob;
  invoice_type: any;
  comments: any;
  type_name: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private invoicesService: InvoicesService,
    private toastr: ToastrService,
    private location: Location,
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    this.invoiceId = routeParams.id;

    this.noImage = false;
    this.loadingFlag = true;

    this.getInvoiceById(this.invoiceId);
    this.currentUser = this.authService.currentUser;
    this.getUserAndRole();
  }

  backClicked() {
    this.location.back();
  }

  getUserAndRole() {
    this.currentUser.subscribe(data => {
      this.username = data.username;
      this.role = data.role;
    });
  }

  getInvoiceById(id: string) {
    this.invoicesService.getInvoiceById(id)
      .subscribe(res => {
        this.invoice.push(res);
        this.user = res['user'];
        this.project = res['project'];
        this.date = res['invoice_date'];
        this.task = res['task'];
        this.description = res['description'];
        this.amountWithTax = res['amount_with_tax'];
        this.tax = res['tax'];
        this.amountNotax = res['amount_no_tax'];
        this.invoice_type = res['invoice_type_id'];
        this.type_name = res['type'];
        this.comments = res['comments'];
        this.image = res['image_url'];

        //check if image is a pdf
        if(this.image.indexOf("data:application/pdf;base64,") !== -1){
          this.isPDF = true;
          this.pdf = blobPdfFromBase64String(this.image);
        }
        if (this.image) {
          this.noImage = false;
        } else {
          this.noImage = true;
        }

        this.loadingFlag = false;
      },
        (err) => {
          this.toastr.error(err, '', { timeOut: 3000 });
          this.loadingFlag = false;
        });
  }

  redirectToEditInvoice() {
    this.router.navigate(["/invoice-edit/" + this.invoiceId]);
  }

  showEditButton() {
    if(this.user === this.username || this.role === Role.Admin)
      return true;
    else
      return false;
  }

  getCSV() {
    let data = this.invoice.map(el => {
      let tObj = {};
      tObj[this.translate.instant('manager.up')] = el.up;
      tObj[this.translate.instant('invoices.project')] = el.project;
      tObj[this.translate.instant('manager.closed')] = el.closed == '1'? 'Sí': 'No';
      tObj[this.translate.instant('invoices.task')] = el.task;
      tObj[this.translate.instant('invoices.user')] = el.user;
      tObj[this.translate.instant('invoices.invoice_date')] = el.invoice_date;
      tObj[this.translate.instant('invoices.amount_with_tax')] = (+el.amount_with_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.tax')] = (+el.tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.amount_no_tax')] = (+el.amount_no_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.description')] = el.description;
      tObj[this.translate.instant('manager.comments')] = el.comments;
      tObj[this.translate.instant('manager.paid')] = el.paid == "1"?'Si': "No";
      tObj[this.translate.instant('invoices.unique_identifier')] = el.unique_identifier;
      return tObj;
    });

    let fields = this.invoice[0].length;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    // Get CSV:
    let output = this.getCSV();
    // Create ZIP:
    var zip = new JSZip();
    // Add CSV:
    zip.file('facturas.csv', output);
    // Create image folder:
    var img = zip.folder("invoices_files");
    // Get all images by Id:
    let ids = Array(this.invoiceId);
    this.invoicesService.downloadInvoicesImages(ids.toString())
      .subscribe(res => {
        // Loop res:
        res['results'].forEach(el => {
          let el_string = el.image_url.toString();
          //Dowload pdf
          if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + ext, el.image_url.replace('data:application/pdf;base64,', ""), { base64: true });
          //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });

        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "facturas.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 3000 });
      });
  }
}
