import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ManagerService } from '../_services/manager.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from '../_services/invoices.service';
import { Location } from '@angular/common';
import { UpService } from '../_services/up.service';
import { BehaviorSubject } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ExpendService } from '../_services/expend.service';
import { HeadquartersService } from '../_services/headquarters.service';
import { CartripService } from '../_services/cartrip.service';

@Component({
  selector: 'paid-cost-dialog',
  templateUrl: 'paid-cost-dialog.html',
  styleUrls: ['./manager-list.component.scss']
})
export class PaidCostDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<PaidCostDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: PaidCostDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'unpaid-cost-dialog',
  templateUrl: 'unpaid-cost-dialog.html',
  styleUrls: ['./manager-list.component.scss']
})
export class UnpaidCostDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<UnpaidCostDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: UnpaidCostDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

export interface ManagerTable {
  id: number;
  invoice_id: number;
  overnight_id: number;
  cartrip_id: number;
  project: string;
  task: string;
  user: string;
  date: string;
  amount_with_tax: string;
  tax: string;
  amount_no_tax: string;
  description: string;
  invoice_type: string;
  unique_identifier: string;
  comments: string;
  can_edit: boolean;
  is_admin: boolean;
  is_manager: boolean;
  nr_nights_int: number;
  nr_nights_nat: number;
  paid: string;
  payroll: string;
  place: string;
  nr_km_pe: number;
  nr_km_can: number;
  module: string;
  closed: string;
  headquarter: string;
  up: string;
  project_id: any;
  user_id: any;
  tax_type: any;
  pay_to: any;
  review: any;
  velazquez_input: any;
  select: boolean;
  time: any;
  paid_mode: any;
  fund: any;
}

@Component({
  selector: 'app-manager-list',
  templateUrl: './manager-list.component.html',
  styleUrls: ['./manager-list.component.scss'],
})
export class ManagerListComponent implements OnInit, OnDestroy {

  loadingFlag: boolean;
  searchForm: FormGroup;
  user: string = '';
  project: string = '';
  task: string = '';
  amountFrom: string = '';
  amountTo: string = '';
  dateFrom: string = '';
  dateTo: string = '';
  dataSource = new MatTableDataSource<ManagerTable>();
  totalAmount: number;
  totalAmountWithoutTax: number;
  module: string = '';
  ups: any[] = [];
  private upsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleArray: Object[] = [
    {name: 'Factura', value: 'Factura'},
    {name: 'Pernocta', value: 'Pernocta'},
    {name: 'Km', value: 'Km'},
    {name: 'Todos', value: ''}
  ];

  closedArray: Object[] = [
    {
      name: this.translate.instant('manager.yes'),
      value: this.translate.instant('manager.yes')
    },
    {
      name: this.translate.instant('manager.no'),
      value: this.translate.instant('manager.no')
    },
    {
      name: this.translate.instant('manager.all'),
      value: ''
    }
  ];

  closed: string = this.translate.instant('manager.no');

  paidArray: Object[] = [
    {
      name: this.translate.instant('manager.yes'),
      value: this.translate.instant('manager.yes')
    },
    {
      name: this.translate.instant('manager.no'),
      value: this.translate.instant('manager.no')
    },
    {
      name: this.translate.instant('manager.all'),
      value: ''
    }
  ];

  paid: string = '';
  downloadColumns: string[] = [
    this.translate.instant('dashboard.headquarter'),
    this.translate.instant('dashboard.up'),
    this.translate.instant('dashboard.project'),
    this.translate.instant('dashboard.closed'),
    this.translate.instant('dashboard.task'),
    this.translate.instant('dashboard.user'),
    this.translate.instant('dashboard.date'),
    this.translate.instant('dashboard.velazquez_input'),
    this.translate.instant('dashboard.pay_to'),
    this.translate.instant('dashboard.review'),
    this.translate.instant('dashboard.fund'),
    this.translate.instant('dashboard.amount_with_tax'),
    this.translate.instant('dashboard.tax_type'),
    this.translate.instant('dashboard.amount_no_tax'),
    this.translate.instant('dashboard.description'),
    this.translate.instant('dashboard.invoice_type'),
    this.translate.instant('dashboard.module'),
    this.translate.instant('dashboard.comments'),
    this.translate.instant('dashboard.nr_nights_int'),
    this.translate.instant('dashboard.nr_nights_nat'),
    this.translate.instant('dashboard.paid'),
    this.translate.instant('dashboard.time'),
    this.translate.instant('dashboard.paid_mode'),
    this.translate.instant('dashboard.payroll'),
    this.translate.instant('dashboard.place'),
    this.translate.instant('dashboard.nr_km_can'),
    this.translate.instant('dashboard.nr_km_pe'),
    this.translate.instant('dashboard.unique_identifier'),
    this.translate.instant('dashboard.unique_identifier_just'),
    this.translate.instant('dashboard.unique_identifier_fact')
  ];
  objDateFrom: Date;
  up: any = 'Todas';
  upsFilter: any[];
  projectClass: boolean;
  userClass: boolean;
  taskClass: boolean;
  amountToClass: boolean;
  dateFromClass: boolean;
  dateToClass: boolean;
  amountFromClass: boolean;
  headquarters: any[] = [];

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild('dateFrom') dateFromElement: ElementRef;

  @ViewChild('dateTo') dateToElement: ElementRef;

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  displayedColumns: string[] = ['headquarter','up','project', 'closed', 'task', 'description', 'user', 'module', 'date', 'amount_with_tax', 'amount_no_tax', 'paid', 'actions'];

  constructor(
    private managerService: ManagerService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private invoicesService: InvoicesService,
    private upService: UpService,
    private dialog: MatDialog,
    private expendService: ExpendService,
    private headquarterService: HeadquartersService,
    private cartripService: CartripService
  ) {
    if(this.expendService.getIsManagerDashboard()){
      this.managerService.setVoidFilter();
    }
    let filters: string[] = this.managerService.getFilters();
    this.user = filters[0];
    this.project = filters[1];
    this.task = filters[2];
    this.amountFrom = filters[3];
    this.amountTo = filters[4];
    this.dateFrom = filters[5];
    this.dateTo = filters[6];
    this.module = filters[7] ? filters[7]: '';
    this.up = filters[8]? filters[8]: 'Todas';
    this.closed = filters[9]? filters[9]: 'No';
    this.paid = filters[10]? filters[10]: '';
   }

  ngOnInit(): void {
    this.expendService.setIsManagerDashboard(false);
    this.loadingFlag = true;
    this.headquarterService.getHeadquarters()
      .subscribe(res => {
        this.headquarters = res['headquarters'];
      });
    this.upService.getUps()
      .subscribe(res => {
        this.ups = res['ups'].map(element => {
          if(element.name == 'no_defined'){
            element.name = this.translate.instant('manager.no_defined_up');
          }
          return element;

        });

        this.upsFilter = this.ups;
        this.upsFilter.push({id: this.ups.length.toString(), name: 'Todas'});

        this.upsEvent.next(true);
      });
    this.upsEvent.asObservable()
      .subscribe( res => {
        if(res) {
          this.getExpends();
        }
      });

  }

  getExpends() {
    this.loadingFlag = true;
    this.managerService.getExpends()
      .subscribe(res => {
        const EXPEND_DATA: ManagerTable[] = res['expends'] ? res['expends']: [];
        this.dataSource.data = EXPEND_DATA.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }).map( element => {
          if(element.up == 'no_defined') {
            element.up = this.translate.instant('dashboard.no_defined_up');
          }
          element.closed = element.closed == '0'? 'No': 'Sí';
          element.paid = element.paid == '0'? 'No': 'Sí';
          element.tax_type = element.tax_type ? element.tax_type + ' %': 'Sin valor';
          element.review = element.review == '1'? 'Sí': 'No';
          element.fund = element.fund == '1'? 'Sí': 'No';
          const auxHeadquarter = this.headquarters.filter(headquarter => {
            return headquarter.id == element.headquarter;
          })

          if(auxHeadquarter.length == 0){
            element.headquarter = 'Sin CT'
          } else {
            element.headquarter = this.translate.instant('dashboard.' + auxHeadquarter[0].name);
          }

          return element;
        });

        this.totalAmount = res['expends'].map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
        this.totalAmountWithoutTax = res['expends'].map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
        this.searchFormInit();
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();
        this.loadingFlag = false;
        setTimeout(() => {
          if(this.dateFrom)
            this.dateFromElement.nativeElement.value = new Date(this.dateFrom).toLocaleDateString('es-ES');
          if(this.dateTo)
            this.dateToElement.nativeElement.value = new Date(this.dateTo).toLocaleDateString('es-ES');
        }, 300)
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  searchFormInit() {
    this.objDateFrom = new Date(this.dateFrom);
    this.searchForm = new FormGroup({
      user: new FormControl(this.user == '' ? '' : this.user),
      project: new FormControl(this.project == '' ? '' : this.project),
      task: new FormControl(this.task == '' ? '' : this.task),
      amountFrom: new FormControl(this.amountFrom == '' ? '' : this.amountFrom),
      amountTo: new FormControl(this.amountTo == '' ? '' : this.amountTo),
      dateFrom: new FormControl(this.dateFrom == '' ? '' : new Date(this.dateFrom).toLocaleDateString('es-ES')),
      dateTo: new FormControl(this.dateTo == '' ? '' : new Date(this.dateTo).toLocaleDateString('es-ES')),
      module: new FormControl(this.module == '' ? '': this.module),
      closed: new FormControl(this.closed == '' ? '': this.closed),
      paid: new FormControl(this.paid == '' ? '': this.paid),
      up: new FormControl(this.up == '' ? '': this.up)
    });
  }

  applyFilter() {
    const user = this.searchForm.get('user').value;
    const project = this.searchForm.get('project').value;
    const task = this.searchForm.get('task').value;
    const amountFrom = this.searchForm.get('amountFrom').value;
    const amountTo = this.searchForm.get('amountTo').value;
    const dateFrom = this.searchForm.get('dateFrom').value;
    const dateTo = this.searchForm.get('dateTo').value;
    const module = this.searchForm.get('module').value;
    const closed = this.searchForm.get('closed').value;
    const paid = this.searchForm.get('paid').value;
    const up = this.searchForm.get('up').value;

    if(project) {
      this.projectClass = true;
    } else {
      this.projectClass = false;
    }
    if(user != '') {
      this.userClass = true;
    } else {
      this.userClass = false;
    }
    if(task) {
      this.taskClass = true;
    } else {
      this.taskClass = false;
    }
    if(amountFrom) {
      this.amountFromClass = true;
    } else {
      this.amountFromClass = false;
    }
    if(amountTo) {
      this.amountToClass = true;
    } else {
      this.amountToClass = false;
    }
    if(dateFrom != 'Invalid Date' && dateFrom) {
      this.dateFromClass = true;
    } else {
      this.dateFromClass = false;
    }
    if(dateTo != 'Invalid Date' && dateTo) {
      this.dateToClass = true;
    } else {
      this.dateToClass = false;
    }
    this.user = user === null ? '' : user;
    this.project = project === null ? '' : project;
    this.task = task === null ? '' : task;
    this.amountFrom = amountFrom === null ? '' : amountFrom;
    this.amountTo = amountTo === null ? '' : amountTo;
    this.module = module === null ? '' : module;
    this.closed = closed === null ? '' : closed;
    this.paid = paid === null ? '' : paid;
    this.up = up === null ? '' : up;

    if(typeof dateFrom != 'string'){
      this.dateFrom = dateFrom === null ? '' : dateFrom.format('YYYY-MM-DD');
    }

    if(typeof dateTo != 'string') {
      this.dateTo = dateTo === null ? '' : dateTo.format('YYYY-MM-DD');
    }

    // Create string of our searching values and split if by '$'
    const filterValue = this.user + '$' + this.project + '$' + this.task + '$' + this.amountFrom + '$' + this.amountTo + '$' + this.dateFrom + '$' + this.dateTo;
    this.dataSource.filter = filterValue.trim().toLowerCase() +  '$' + this.module + '$' + this.closed + '$'+ this.up + '$' + this.paid;

    // Recalculate total:
    this.totalAmount = this.dataSource.filteredData.map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
    this.totalAmountWithoutTax = this.dataSource.filteredData.map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
  }

  getFilterPredicate() {
    return (row: ManagerTable, filters: string) => {
      // Split string per '$' to array
      const filterArray = filters.split('$');

      const user = filterArray[0];
      const project = filterArray[1];
      const task = filterArray[2];
      let amountFrom = filterArray[3].replace(",", ".");
      let amountTo = filterArray[4].replace(",", ".");
      let dateFrom = filterArray[5];
      let dateTo = filterArray[6];
      let module = filterArray[7];
      let closed = filterArray[8];
      let up = filterArray[9];
      let paid = filterArray[10];

      const matchFilter = [];

      // Fetch data from row
      const columnUser = row.user;
      const columnProject = row.project;
      const columnTask = row.task;
      const columnAmount = row.amount_no_tax;
      const columnDate = row.date;
      const columnModule = row.module;
      const columnClosed = row.closed;
      const columnUp = row.up;
      const columnPaid = row.paid;

      // Verify fetching data by our searching values

      const customFilterUser = columnUser.toLowerCase().includes(user);
      const customFilterPoject = columnProject.toLowerCase().includes(project);
      const customFilterTask = columnTask.toLowerCase().includes(task);
      let customFilterAmountFrom;
      let customFilterAmountTo;
      let customFilterDateFrom;
      let customFilterDateTo;
      let customFilterModule;
      let customFilterClosed;
      let customFilterUp;
      let customFilterPaid;

      // Add maximum and minimum dates if fields are blank
      if (!Date.parse(dateFrom)) {
        dateFrom = '2000-01-01';
      }
      if (!Date.parse(dateTo)) {
        dateTo = Date.now().toString();
      }

      // Check amount_with_tax:
      if (+amountFrom <= +columnAmount) {
        customFilterAmountFrom = true;
      }
      if (+amountTo >= +columnAmount) {
        customFilterAmountTo = true;
      }

      // Check date limits
      if (Date.parse(dateFrom) <= Date.parse(columnDate)) {
        customFilterDateFrom = true;
      }
      // Add miliseconds to just be that dateTo 23:59:59
      if (Date.parse(columnDate) <= Date.parse(dateTo) + 86399999) {
        customFilterDateTo = true;
      }

      if(columnModule == module) {
        customFilterModule = true;
      }

      if(columnClosed == closed) {
        customFilterClosed = true;
      }

      if(columnPaid == paid) {
        customFilterPaid = true;
      }

      if(columnUp == up) {
        customFilterUp = true;
      }

      if(up == 'Todas') {
        customFilterUp = true;
      }

      // Push boolean values into array
      matchFilter.push(customFilterUser);
      matchFilter.push(customFilterPoject);
      matchFilter.push(customFilterTask);
      if(module) {
        matchFilter.push(customFilterModule);
      }
      if(closed) {
        matchFilter.push(customFilterClosed);
      }
      if(paid) {
        matchFilter.push(customFilterPaid);
      }
      if(up) {
        matchFilter.push(customFilterUp);
      }
      if (amountFrom) {
        matchFilter.push(customFilterAmountFrom);
      }
      if (amountTo) {
        matchFilter.push(customFilterAmountTo);
      }
      if (Date.parse(dateFrom)) {
        matchFilter.push(customFilterDateFrom);
      }
      if (Date.parse(dateTo)) {
        matchFilter.push(customFilterDateTo);
      }

      // Return true if all values in array is true
      // else return false
      return matchFilter.every(Boolean);
    };
  }

  getCSV() {
    let data = this.dataSource.filteredData.map(el => {
      let tObj = {};
      tObj[this.translate.instant('dashboard.project')] = el.project;
      tObj[this.translate.instant('dashboard.task')] = el.task;
      tObj[this.translate.instant('dashboard.user')] = el.user;
      tObj[this.translate.instant('dashboard.date')] = el.date;
      tObj[this.translate.instant('dashboard.amount_with_tax')] = (+el.amount_with_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.tax_type')] = el.tax_type;
      tObj[this.translate.instant('dashboard.amount_no_tax')] = (+el.amount_no_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.description')] = el.description;
      tObj[this.translate.instant('dashboard.invoice_type')] = el.invoice_type ? this.translate.instant('invoices.' + el.invoice_type): '';
      tObj[this.translate.instant('dashboard.unique_identifier')] = el.unique_identifier;
      tObj[this.translate.instant('dashboard.unique_identifier_just')] = el.unique_identifier + '_just';
      tObj[this.translate.instant('dashboard.unique_identifier_fact')] = el.unique_identifier + '_fact';
      tObj[this.translate.instant('dashboard.comments')] = el.comments;
      tObj[this.translate.instant('dashboard.nr_nights_int')] = (+el.nr_nights_int).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.nr_nights_nat')] = (+el.nr_nights_nat).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.paid')] = el.paid;
      tObj[this.translate.instant('dashboard.payroll')] = el.payroll;
      tObj[this.translate.instant('dashboard.place')] = el.place;
      tObj[this.translate.instant('dashboard.nr_km_can')] = (+el.nr_km_can).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.nr_km_pe')] = (+el.nr_km_pe).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.module')] = el.module;
      tObj[this.translate.instant('dashboard.closed')] = el.closed;
      tObj[this.translate.instant('dashboard.up')] = el.up;
      tObj[this.translate.instant('dashboard.headquarter')] = this.translate.instant('dashboard.' + (el.headquarter !== 'Sin CT' ? el.headquarter : 'no_headquarter'));
      tObj[this.translate.instant('dashboard.velazquez_input')] = el.velazquez_input;
      tObj[this.translate.instant('dashboard.review')] = el.review;
      tObj[this.translate.instant('dashboard.fund')] = el.fund;
      tObj[this.translate.instant('dashboard.pay_to')] = el.pay_to;
      tObj[this.translate.instant('dashboard.time')] = el.time;
      tObj[this.translate.instant('dashboard.paid_mode')] = el.paid_mode;
      return tObj;
    });

    let fields = this.downloadColumns;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

  downloadCSV() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    let output = this.getCSV();
    saveAs(output, 'gastos.csv');

    this.loadingFlag = false;
    this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
  }

  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    // Get CSV:
    let output = this.getCSV();
    // Create ZIP:
    var zip = new JSZip();
    // Add CSV:
    zip.file('gastos.csv', output);
    // Create image folder:
    var img = zip.folder("invoices_img");
    // Get all images by Id:
    let ids = this.dataSource.filteredData.map(el => {
      let tObj = [];
      if(el.invoice_id){
        tObj.push(el.invoice_id);
      }

      return tObj;
    });let idKMs = this.dataSource.filteredData.map(el => {
      let tObj = [];
      if(el.cartrip_id){
        tObj.push(el.cartrip_id);
      }

      return tObj;
    });

    ids = ids.filter(n => n.length != 0);
    idKMs = idKMs.filter(n => n.length != 0);

    this.cartripService.downloadCartripImages(idKMs.toString())
        .subscribe(res => {
          res['cartrip_just'].forEach(el => {
            let el_string = el.just_url.toString();
            //Dowload pdf
            if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
              const ext = '.pdf';
              img.file(el.unique_identifier + '_km_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
            //Download img
            } else {
              let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
              img.file(el.unique_identifier + '_km_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
            }
          });
        });

      this.invoicesService.downloadInvoicesImages(ids.toString())
        .subscribe(res => {
          // Loop res:
          if(res['invoices'])
          res['invoices'].forEach(el => {
            let el_string = el.image_url.toString();
            //Dowload pdf
            if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
              const ext = '.pdf';
              img.file(el.unique_identifier + '_fact' + ext, el.image_url.replace('data:application/pdf;base64,', ""), { base64: true });
            //Download img
            } else {
              let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
              img.file(el.unique_identifier + '_fact' + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
            }
          });

          if(res['justifications'])
          res['justifications'].forEach(el => {
            let el_string = el.just_url.toString();
            //Dowload pdf
            if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
              const ext = '.pdf';
              img.file(el.unique_identifier + '_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
            //Download img
            } else {
              let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
              img.file(el.unique_identifier + '_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
            }
          });


        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "gastos.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 6000 });
      });
  }

  clickUnpaid(id) {
    const expend = this.dataSource.data.filter(element => {
      return element.id == id;
    });

    if(expend[0].invoice_id){
      this.unpaidCostDialog(expend[0].invoice_id, 'invoice');
    } else if(expend[0].overnight_id) {
      this.unpaidCostDialog(expend[0].overnight_id, 'overnight');
    } else if(expend[0].cartrip_id) {
      this.unpaidCostDialog(expend[0].cartrip_id, 'cartrip');
    }
  }



  unpaidCostDialog(id, type) {
    const dialogRef = this.dialog.open(UnpaidCostDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unpaidCostById(id, type);
      }
    });
  }

  unpaidCostById(id, type) {
    this.loadingFlag = true;
    this.expendService.unpaidCostById(id, type)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('manager.success_unpaid'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000})
      });
  }

  resetFilters() {
    this.searchForm.get('user').setValue('');
    this.searchForm.get('project').setValue('');
    this.searchForm.get('task').setValue('');
    this.searchForm.get('amountFrom').setValue('');
    this.searchForm.get('amountTo').setValue('');
    this.searchForm.get('dateFrom').setValue(null);
    this.searchForm.get('dateTo').setValue(null);
    this.searchForm.get('module').setValue('');
    this.searchForm.get('closed').setValue('No');
    this.searchForm.get('paid').setValue('');
    this.searchForm.get('up').setValue('Todas');
    this.applyFilter();
  }

  ngOnDestroy(): void {
    this.managerService.setFilters(
      this.user,
      this.project,
      this.task,
      this.amountFrom,
      this.amountTo,
      this.dateFrom,
      this.dateTo,
      this.module,
      this.up,
      this.closed,
      this.paid,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      ''
    );
  }

}
