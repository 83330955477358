import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '../_models/user';
import { SidenavService } from '../_services/sidenav.service';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {

  appTitle: string = 'FacTool';
  currentUser: User;
  currentLg: string = 'es';
  toggleActive: boolean = false;
  username: string;
  userLogged = false;

  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private sidenav: SidenavService,
    private managerService: ManagerService
  ) {
    this.auth.currentUser.subscribe(x => this.currentUser = x);

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadUser();
      }
    })
  }

  ngOnInit() {
    if (localStorage.getItem('currentUser')) {
      this.currentLg = JSON.parse(localStorage.getItem('currentUser')).lg;
    }
    this.managerService.setVoidFilter();
  }

  loadUser() {
    this.auth.currentUser.subscribe(x => this.currentUser = x);

    if (this.currentUser && this.currentUser.id) {
      this.userLogged = true;
      this.username = this.currentUser.username;
    }
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.currentLg = language;
    let jsonLg = { "lg": language };
    localStorage.setItem('currentUser', JSON.stringify(jsonLg));
  }

  sidenavToggle() {
    this.toggleActive = !this.toggleActive;
    this.sidenav.toggle();
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/login']);
  }
}
