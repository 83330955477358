<div class="main-section">
    <h1>{{ 'profile.title' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <form #f="ngForm">
            <mat-form-field>
                <input type="text" disabled matInput placeholder="{{ 'profile.username' | translate }}"
                    value="{{username}}">
            </mat-form-field>

            <mat-form-field>
                <input type="text" disabled matInput placeholder="{{ 'profile.role' | translate }}" value="{{role}}">
            </mat-form-field>

            <mat-form-field>
                <input type="email" disabled matInput placeholder="{{ 'profile.email' | translate }}" value="{{email}}">
            </mat-form-field>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>