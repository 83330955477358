import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/_services/project.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { ManagerService } from 'src/app/_services/manager.service';
import { UpService } from 'src/app/_services/up.service';

@Component({
  selector: 'app-project-add',
  templateUrl: './project-add.component.html',
  styleUrls: ['./project-add.component.scss']
})
export class ProjectAddComponent implements OnInit {
  code: string;
  name: string;
  loadingFlag: boolean = false;
  ups: any[] = [];
  up: any;

  constructor(
    private projectService: ProjectService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private managerService: ManagerService,
    private upService: UpService
  ) { }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.managerService.setVoidFilter();
    this.upService.getUps()
      .subscribe(res => {
        this.ups = res['ups'].map(element => {
          if(element.name == 'no_defined'){
            element.name = this.translate.instant('projects.no_defined_up');
            this.up = element.id;
          }
          return element;
          
        });

        this.loadingFlag = false;
      });
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.projectService.addProject(event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('projects.success_project_add'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => this.router.navigate(['/project-list'])
              );
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('projects.error_project_exist'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('projects.error_project_add'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
    
  }

}
