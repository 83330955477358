<mat-toolbar color="primary">
  <mat-icon class="menu-icon" *ngIf="userLogged" (click)="sidenavToggle()">menu</mat-icon>

  <a *ngIf='!userLogged; else modules' routerLink="/"><img class="header-logo" src="assets/img/logo.png" alt="{{ appTitle }}"></a>
  <ng-template #modules>
    <a routerLink="/invoice-list"><img class="header-logo" src="assets/img/logo.png" alt="{{ appTitle }}"></a>
  </ng-template>

  <span class="toolbar-spacer"></span>

  <span *ngIf="userLogged" class="username">{{ 'nav.hello' | translate }}, {{ username }}</span>
  <a class="menu-item" *ngIf="userLogged" (click)="logout()">{{ 'nav.logout' | translate }}</a>
</mat-toolbar>