<div class="main-section">
    <h1>{{ 'users.title_edit' | translate }}: {{username}}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <input type="text" matInput [(ngModel)]="username" placeholder="{{ 'users.username' | translate }}"
                    value="{{username}}" name="username" disabled>
            </mat-form-field>

            <mat-form-field>
                <input type="email" matInput [(ngModel)]="email" placeholder="{{ 'users.email' | translate }}"
                    value="{{email}}" name="email" disabled>
            </mat-form-field>

            <mat-form-field>
                <mat-select placeholder="{{ 'users.role' | translate }}" name="role" [(ngModel)]="role" required>
                    <mat-option *ngFor="let role of roles" [value]="role.id">
                        <span>{{ role.name | titlecase }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <mat-select placeholder="{{ 'users.headquarter' | translate }}" name="headquarter" [(ngModel)]="headquarter" required>
                    <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.id">
                        <span translate>users.{{ headquarter.name }}</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="accent"
                    [disabled]="!f.valid">{{ 'users.user_send_button' | translate }}</button>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>