<div class="main-section">
    <h1>{{ 'languages.title_edit' | translate }}: {{name}}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <p>{{ 'languages.note_edit' | translate }}</p>
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <input type="text" matInput [(ngModel)]="name" placeholder="{{ 'languages.name' | translate }}"
                    value="{{name}}" name="name" required>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="code" placeholder="{{ 'languages.code' | translate }}"
                    value="{{code}}" name="code" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="accent"
                    [disabled]="!f.valid">{{ 'languages.send_button' | translate }}</button>
            </div>
        </form>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <p [innerHTML]="'languages.note_delete' | translate"></p>
        <form (ngSubmit)="onSubmitDelete(f_del.value)" #f_del="ngForm">
            <p>
                {{ 'languages.delete_message' | translate }}
            </p>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="delete" placeholder="{{ 'languages.delete' | translate }}"
                    value="{{delete}}" name="delete" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="warn"
                    [disabled]="!(f_del.valid && delete == 'eliminar')">{{ 'languages.send_button' | translate }}</button>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>