import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface UserTable {
  id: number;
  username: string;
  name: string;
  email: string;
  role: number;
  language: string;
  active: string;
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private API_URL = environment.API_URL

  constructor(
    private http: HttpClient
  ) { }

  getUsers(): Observable<UserTable[]> {
    return this.http.get<UserTable[]>(`${this.API_URL}/users`);
  }

  getUserById(id: string) {
    return this.http.get(`${this.API_URL}/users/` + id);
  }

  updateUser(id: string, event: any) {
    let role = event.role;
    const headquarter = event.headquarter;

    return this.http.put<any>(`${this.API_URL}/users/` + id, {
      role,
      headquarter
    });
  }
}
