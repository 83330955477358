import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';
import {Location} from '@angular/common';
import { Role } from '../_models/role';
import { OvernightService } from '../_services/overnight.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProjectService } from '../_services/project.service';
import { TasksService } from '../_services/tasks.service';
import { ConfigurationService } from '../_services/configuration.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { PaidCostDialog, UnpaidCostDialog } from '../manager-list/manager-list.component';
import { ExpendService } from '../_services/expend.service';
import { PaidManagerDialog, UnpaidManagerDialog } from '../manager-dashboard/manager-dashboard.component';

@Component({
  selector: 'delete-overnight-dialog',
  templateUrl: 'delete-overnight-dialog.html',
  styleUrls: ['./overnight-view.component.scss']
})
export class DeleteOvernightDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteOvernightDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteOvernightDialog,
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-overnight-view',
  templateUrl: './overnight-view.component.html',
  styleUrls: ['./overnight-view.component.scss']
})
export class OvernightViewComponent implements OnInit {
  protected overnight_id: string;
  loadingFlag: boolean = false;

  currentUser: Observable<any>;
  
  projects: any[];
  username: string;
  role: any;
  overnight: any;
  user: any;
  date: Date;
  project: any;
  task: string = '';
  tasks: any[] = [];
  description: any;
  nr_nights_int: any;
  amount_no_tax: any;
  nr_nights_nat: any;
  paid: any;
  payroll: any;
  projectId: any;
  can_edit: boolean;
  is_admin: boolean;
  config: any;
  nightChanged: boolean;
  validateProject: boolean = true;
  projectArrayObs: Observable<any[]>;
  system_date: any = new Date();
  paid_modes: any[] = ['Sin valor','Transferencia', 'Domiciliación', 'Efectivo'];
  paid_mode: any = 'Transferencia';
  paids: any[] = [
    {name: 'Sí', value: '1'},
    {name: 'No', value: '0'}
  ];
  disbledPaid: boolean;
  auxTime: any;
  time: any;
  auxPaidMode: any;
  timeValidate: boolean;
  paidModeValidate: boolean;
  changeSelectionProject: boolean = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private overnightService: OvernightService,
    private toastr: ToastrService,
    private location: Location,
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private projectService: ProjectService,
    private taskService: TasksService,
    private configService: ConfigurationService,
    private expendService: ExpendService
  ) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    this.overnight_id = routeParams.id;
    this.loadingFlag = true;
    this.configService.getConfig()
      .subscribe(res => {
        this.config = res;
      });
    this.currentUser = this.authService.currentUser;
    this.getUserAndRole();
    this.getProjects();
  }

  getProjects() {
    this.projectService.getProjects()
      .subscribe(res => {
        this.projects = res['projects'].map(el => {
          let tObj = {};
          tObj['id'] = el.id;
          tObj['name'] = el.code + ' - ' + el.name;
          return tObj;
        });

        this.getOvernightById(this.overnight_id);
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  onSelectionChange(event: any) {
    this.task = '';
    this.changeSelectionProject = true;
    let name = event.option.value;
    let tempProjId = this.projects.filter(el => el.name == name);
    this.projectId = tempProjId[0].id;
    this.getTasksByProjectId(this.projectId);
  }

  getTasksByProjectId(id: string) {
    this.loadingFlag = true;
    const object = JSON.stringify({
      id: id,
      offset: false
    });
    this.taskService.getTasksByProjectId(object)
      .subscribe(res => {
        this.tasks = res['tasks'];
        if(this.changeSelectionProject) {
          this.task = this.tasks[0].id;
        }
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }


  backClicked() {
    this.location.back();
  }

  getUserAndRole() {
    this.currentUser.subscribe(data => {
      this.username = data.username;
      this.role = data.role;
    });
  }

  getOvernightById(id: string) {
    this.overnightService.getOvernightById(id)
      .subscribe((res: any) => {
        this.user = res.user;
        this.date = new Date(res.date_end);
        this.project = res.project;
        this.projectId = res.project_id;
        this.task = res.task_id;
        this.description = res.description;
        this.nr_nights_int = res.nr_nights_int;
        this.nr_nights_nat = res.nr_nights_nat;
        this.amount_no_tax = res.amount_no_tax;
        this.paid = res.paid;
        this.payroll = res.payroll;
        this.can_edit = res.can_edit == 'true'? true: false;
        this.is_admin = res.is_admin == 'true'? true: false;
        this.auxTime = res['time'] ? new Date(res['time']): '';
        this.auxPaidMode = res['paid_mode'] ? res['paid_mode']: 'Transferencia';
        this.paidChange();

        this.getTasksByProjectId(this.projectId);
      },
        (err) => {
          this.toastr.error(err, '', { timeOut: 3000 });
          this.loadingFlag = false;
        });
  }

  showEditManager() {
    if( this.role == Role.Admin || this.role == Role.Manager)
      return true;
    else
      return false;
  }

  showEditInputs() {
    if( this.can_edit || this.is_admin || this.role === Role.Manager)
      return true;
    else
      return false;
  }

  onSubmit(form: NgForm) {
    if(!form.valid)
      return;

    if(!form.value.amount_no_tax)
      form.value.amount_no_tax = this.amount_no_tax;

    form.value.date = this.date.toISOString().split('T')[0];
    if(this.time) {
      const auxTime = new Date(this.time); 
      form.value.time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T',' ').replace('Z','');
    }

    if(this.paid_mode == 'Sin valor') {
      form.value.paid_mode = null;
    }
    
    this.overnightService.updateOvernight(this.overnight_id, this.projectId, form.value)
      .subscribe((res: any) => {
        if (res && res['status'] == 1) {
          this.toastr.success(this.translate.instant('overnight.success_overnight_edit'), '', { timeOut: 3000 });
          if(!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
        } else {
          this.toastr.error(this.translate.instant('overnight.error_overnight_edit'), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      },
      error => {
        this.toastr.error(String(error), '', { timeOut: 3000 });
        this.loadingFlag = false;
      }
    );
  }

  deleteOvernightDialog() {
    const dialogRef = this.dialog.open(DeleteOvernightDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteOvernight(this.overnight_id);
      }
    });
  }

  deleteOvernight(overnight_id: string) {
    this.loadingFlag = true;

    this.overnightService.deleteOvernight(overnight_id)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('overnight.success_overnight_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  if(!this.expendService.getIsManagerDashboard())
                    this.router.navigate(['manager-list']);
                  else
                    this.router.navigate(['manager-dashboard']);
                }
              );
          } else {
            this.toastr.error(this.translate.instant('overnight.error_overnight_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  nightsChange() {
    this.amount_no_tax = this.nr_nights_int*this.config.price_int_night + this.nr_nights_nat*this.config.price_nat_night;
  }

  doFilter() {
    this.projectArrayObs = of(this.projects);
    this.projectArrayObs = this.projectArrayObs
      .pipe(
        map(res => this.filter(res))
      )

      this.onBlurProject();
     
  }
  
  filter(projects) {
    return projects.filter(project => 
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name.toLowerCase().includes(this.project.toLowerCase()))
    
  }

  filterValidate(projects) {
    return projects.filter(project => 
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name == this.project
    );
  }

  onBlurProject() {
    const project = this.filterValidate(this.projects);
    if(project.length == 1) {
      this.getTasksByProjectId(project[0].id);
      this.projectId = project[0].id;
      this.validateProject = true;
    } else{
      
      this.validateProject = false;
    } 
       
  }

  paidInvoiceDialog() {
    const dialogRef = this.dialog.open(PaidManagerDialog, {
      width: '500px',
      data: {
        system_date: this.system_date,
        paid_modes: this.paid_modes,
        paid_mode: this.paid_mode,
        number: 1
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paid_mode = result.paid_mode;
        const auxTime = new Date(result.system_date);
        const time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T',' ').replace('Z','');
        this.paidInvoiceById(this.overnight_id, 'overnight', time);
        
      } 
    });
  }

  paidInvoiceById(id, type, time) {
    this.loadingFlag = true;
    this.expendService.paidCostById(id, type, time, this.paid_mode)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('manager.success_paid'), '', {timeOut: 3000});
          if(!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectCloseEvent.next(true);
        } else {
          
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_paid'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_paid'), '', {timeOut: 3000})
      });
  }

  unpaidInvoiceDialog() {
    const dialogRef = this.dialog.open(UnpaidManagerDialog, {
      width: '500px',
      data: {number: 1}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unpaidInvoiceById(this.overnight_id, 'overnight');
      } 
    });
  }

  unpaidInvoiceById(id, type) {
    this.loadingFlag = true;
    this.expendService.unpaidCostById(id, type)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('manager.success_unpaid'), '', {timeOut: 3000});
          if(!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectOpenEvent.next(true);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000})
      });
  }

  paidChange() {
    
    if(this.paid == '0') {
      this.disbledPaid = true;
      this.auxTime = this.time;
      this.auxPaidMode = this.paid_mode;
      this.time = '';
      this.paid_mode = this.paid_modes[0];
    } else {
      this.time = this.auxTime? this.auxTime: new Date();
      this.paid_mode = this.auxPaidMode? this.auxPaidMode: '';
      this.disbledPaid = false;
    }
    this.validatePaid();
  }

  validatePaid() {
    if(this.paid == '1') {
      if(!this.time) {
        this.timeValidate = false;
      } else {
        this.timeValidate = true;
      }

      if(this.paid_mode == 'Sin valor') {
        this.paidModeValidate = false;
      } else {
        this.paidModeValidate = true;
      }
    } else {
      this.timeValidate = true;
      this.paidModeValidate = true;
    }
  }

}
