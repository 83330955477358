import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjectService } from 'src/app/_services/project.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { InvoicesService } from 'src/app/_services/invoices.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TaxService } from 'src/app/_services/tax.service';
import blobPdfFromBase64String from 'src/app/_helpers/blobPdfFromBase64String';

@Component({
  selector: 'app-invoice-add',
  templateUrl: './invoice-add.component.html',
  styleUrls: ['./invoice-add.component.scss']
})
export class InvoiceAddComponent implements OnInit {

  loadingFlag: boolean = false;

  user: string;
  project: string;
  projects: any[];
  projectId: string;
  invoice_date: string;
  task: string = '';
  tasks: any[] = [];
  description: string;
  amountWithTax: number;
  tax: any = '1';
  amountNoTax: number;
  projectArrayObs: Observable<any[]>;
  image: string = '';
  pdf: Blob;
  noImage: boolean = false;
  imageError: boolean = false;
  extensionError: boolean = false;
  isPDF: boolean = false;
  type_id: any;
  type: any = '';
  types: any;
  comments: any;
  typeId: number;
  type_name: any;
  can_edit: boolean;
  is_admin: boolean;
  invoice: any[] = [];
  validateProject: boolean = true;
  dataUrl: string;
  validateNoTax : boolean = true;
  tax_types: any[] = [];
  pay_to: any;
  review: boolean = false;

  constructor(
    private taskService: TasksService,
    private projectService: ProjectService,
    private authService: AuthenticationService,
    private invoicesService: InvoicesService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private taxService: TaxService
  ) { }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.getProjects();
    this.user = this.authService.currentUserValue.username;
    this.invoicesService.getInvoiceType()
      .subscribe(res => {
        this.types = res.invoice_types
          .map(element => {
            element.id = +element.id
            return element;
          });

      });
    this.taxService.getTaxTypes()
      .subscribe(res => {
        this.tax_types = res['tax_types'];
      });
  }

  onSubmit(form: NgForm) {
    this.loadingFlag = true;
    form.value.review = form.value.review == true ? 1 : 0;

    const date = new Date().toISOString().replace('T',' ').replace('z', '').slice(0, -5);
    this.invoicesService.createInvoice(this.image, form.value, this.projectId, date)
      .subscribe(res => {
        if(res['status'] == 1)
          this.toastr.success(this.translate.instant('invoices.success_invoice_add'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.router.navigate(['/manager-list']);
                }
              );
      }, error => {
        this.toastr.error(this.translate.instant('invoices.error_invoice_add'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.router.navigate(['/manager-list']);
                }
              );
      });
  }

  getProjects() {
    this.projectService.getProjects()
      .subscribe(res => {
        this.projects = res['projects'].map(el => {
          let tObj = {};
          tObj['id'] = el.id;
          tObj['name'] = el.code + ' - ' + el.name;
          return tObj;
        });
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  onSelectionChange(event: any) {
    this.task = '';
    let name = event.option.value;
    let tempProjId = this.projects.filter(el => el.name == name);
    this.projectId = tempProjId[0].id;
    this.getTasksByProjectId(this.projectId);
  }

  getTasksByProjectId(id: string) {

    const object = JSON.stringify({
      id: id,
      offset: false
    });
    this.taskService.getTasksByProjectId(object)
      .subscribe(res => {
        this.tasks = res['tasks'] ? res['tasks']: [];
        if(this.tasks.length > 0){
          this.task = this.tasks[0].id;
        }
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  imageChanged(event: any) {
    this.image = '';
    this.isPDF = false;
    this.noImage = true;
    let fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    let fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {


      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.onload = () => {
        this.image = fr.result.toString();

        this.noImage = false;
      }
      fr.readAsDataURL(fileContent);

    } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
      this.extensionError = true;
    }
  }

  loadPDF(event) {
    this.image = '';
    this.isPDF = true;
    this.noImage = true;
    const fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    const fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if(fileExtension == 'pdf') {


      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.readAsDataURL(fileContent);
      fr.onload = () => {

        this.pdf = blobPdfFromBase64String(fr.result.toString());
        this.image = fr.result.toString();

        this.noImage = false;
      }


    } else {
      this.extensionError = true;
    }
  }

  calculateAmountWithoutTax() {
    if((this.amountWithTax || this.amountWithTax === 0)){
      const auxTax = this.tax_types.filter(element => element.id == this.tax);
      if(auxTax.length != 0) {
        const result = this.roundToTwo(this.amountWithTax/(1 + auxTax[0].type/100));
        if(result < 0) {
          this.amountNoTax = null;
          this.validateNoTax = false;
        } else {
          this.amountNoTax = result;
          this.validateNoTax = true;
        }
      }
    }

  }

  reviewChange() {
  }

  roundToTwo(num) {
    return Math.round( num * 100 + Number.EPSILON ) / 100;
  }

  doFilter() {
    this.projectArrayObs = of(this.projects);
    this.projectArrayObs = this.projectArrayObs
      .pipe(
        map(res => this.filter(res))
      )

      this.onBlurProject();

  }

  filter(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name.toLowerCase().includes(this.project.toLowerCase()))

  }

  filterValidate(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name == this.project
    );
  }

  onBlurProject() {
    const project = this.filterValidate(this.projects);
    if(project.length == 1) {
      this.getTasksByProjectId(project[0].id);
      this.projectId = project[0].id;
      this.validateProject = true;
    } else{

      this.validateProject = false;
    }

  }
}
