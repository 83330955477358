import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appStopChangePropagation]'
})
export class StopChangePropagationDirective {

  constructor() { }

  @HostListener("change", ["$event"])
    public onChange(event: any): void
    {
        event.stopPropagation();
    }
}
