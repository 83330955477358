import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { LanguageService } from '../_services/language.service';
import { ManagerService } from '../_services/manager.service';

export interface LanguageTable {
  id: number;
  name: string;
  code: string;
}

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss']
})
export class LanguageListComponent implements OnInit {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['id', 'name', 'code', 'actions'];
  dataSource = new MatTableDataSource<LanguageTable>();

  constructor(
    private languageService: LanguageService,
    private managerService: ManagerService
  ) { }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit() {
    this.loadingFlag = true;
    this.languageService.getLanguages()
      .subscribe(res => {
        const LANG_DATA: LanguageTable[] = res['languages'];
        this.dataSource.data = LANG_DATA;
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
    this.managerService.setVoidFilter();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
