<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="section-header" *ngIf="!loadingFlag">
        <h1>{{ 'invoices.title_edit' | translate }}</h1>
        <button mat-stroked-button (click)="backClicked()">
            <mat-icon aria-hidden="false" aria-label="chevron_left">chevron_left</mat-icon>
            {{ 'invoices.back_button' | translate }}
        </button>
    </div>

    <div class="form-container" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                <mat-card fxFlex="50%" class="info-card" *ngIf="!showEditInputs()">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.user' | translate }}"
                                name="user" value="{{user}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.date' | translate }}"
                                name="date" value="{{invoice_date | date:'shortDate' }}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.project' | translate }}"
                                name="project" value="{{project}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.task' | translate }}"
                                name="task" value="{{task}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.description' | translate }}"
                                name="description" value="{{description}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel="pay_to"
                                placeholder="{{ 'invoices.pay_to' | translate }}" name="pay_to"
                                value="{{pay_to}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px">
                        <mat-form-field class="input-width">
                            <input type="text" matInput ngModel
                                placeholder="{{ 'invoices.amount_with_tax' | translate }}" name="amountWithTax"
                                value="{{amountWithTax | currency:'EUR'}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label class="label-position">{{ 'invoices.tax_type' | translate }}

                            </mat-label>
                            <mat-select aria-label="{{ 'invoices.tax_type' | translate }}" [(ngModel)]="tax_type_id" name="tax_type_id" (ngModelChange)="calculateAmountWithoutTax()">
                                <mat-option *ngFor="let tax_type of tax_types" [value]="tax_type.id" [disabled]="tax_type.id == tax_types.length" [disabled]="true">
                                    <span *ngIf="tax_type.type != 'Sin valor'">{{tax_type.type}} %</span>
                                    <span *ngIf="tax_type.type == 'Sin valor'">{{tax_type.type}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.amount_no_tax' | translate }}"
                                name="amountNoTax" value="{{amountNoTax | currency:'EUR'}}" readonly>
                        </mat-form-field>

                    </div>

                    <div fxLayout="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.invoice_type' | translate }}"
                                name="invoice_type" value="{{'invoices.' + type_name | translate}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.comments' | translate }}"
                                name="comments" value="{{comments}}" readonly>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!showPaidButton()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value" [disabled]="true">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="true"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="true"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="true">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="column" class="checkbox-wrapper">
                        <mat-checkbox [(ngModel)]="review" name="review" disabled>{{'invoices.review' | translate}}</mat-checkbox>
                    </div>
                    <br>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="control-buttons">
                        <button mat-raised-button color="primary" type="button" (click)="downloadFull()">
                            {{ "invoices.download_invoice" | translate }}
                        </button>

                    </div>
                </mat-card>
                <mat-card fxFlex="50%" class="info-card" *ngIf="showEditInputs()">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" >
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='user' placeholder="{{ 'invoices.user' | translate }}"
                                name="user" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="date" matInput [(ngModel)]="invoice_date" placeholder="{{ 'invoices.invoice_date' | translate }}"
                                name="invoice_date" required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto"
                                   name="project" #projectField="ngModel"
                                   placeholder="{{ 'invoices.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'invoices.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <mat-select placeholder="{{ 'invoices.task' | translate }}" name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let task of tasks" [value]="task.id">
                                    {{ task.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="description"
                                placeholder="{{ 'invoices.description' | translate }}" name="description"
                                value="{{description}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="pay_to"
                                placeholder="{{ 'invoices.pay_to' | translate }}" name="pay_to"
                                value="{{pay_to}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" class="flex-alignment">
                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.amount_with_tax' | translate }}</mat-label>
                            <input type="number" matInput [(ngModel)]="amountWithTax"
                                name="amountWithTax"
                            value="{{amountWithTax}}" (ngModelChange)='calculateAmountWithoutTax()' required>
                        </mat-form-field>
                        <!-- placeholder="{{ 'invoices.amount_with_tax' | translate }}" -->

                        <mat-form-field  class="input-width">
                            <mat-label class="label-position">{{ 'invoices.tax_type' | translate }}

                            </mat-label>
                            <mat-select aria-label="{{ 'invoices.tax_type' | translate }}" [(ngModel)]="tax_type_id" name="tax_type_id" (ngModelChange)="calculateAmountWithoutTax()">
                                <mat-option *ngFor="let tax_type of tax_types" [value]="tax_type.id" [disabled]="tax_type.id == tax_types.length">
                                    <span *ngIf="tax_type.type != 'Sin valor'">{{tax_type.type}} %</span>
                                    <span *ngIf="tax_type.type == 'Sin valor'">{{tax_type.type}}</span>
                                </mat-option>
                            </mat-select>
                            <span>
                                <mat-icon class="tooltip-info-title" aria-hidden="false" aria-label="help"
                                    matTooltip="{{ 'invoices.info_tax_type' | translate }}" matTooltipClass="tooltip" matTooltipPosition="above">
                                    help_outline
                                </mat-icon>
                            </span>
                        </mat-form-field>
                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.amount_no_tax' | translate }}</mat-label>
                            <input type="number" matInput [(ngModel)]="amountNoTax" name="amountNoTax"
                                value="{{amountNoTax}}" readonly required>
                                <mat-hint class="error-hint" *ngIf="!validateNoTax">{{'invoices.error_no_tax' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column">
                        <mat-form-field>
                            <mat-label>{{ 'invoices.invoice_type' | translate }} *</mat-label>
                            <mat-select name="invoice_type" [(ngModel)]="type">
                                <mat-option *ngFor="let value of types" [value]="value.id">
                                    {{ 'invoices.' + value.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="comments"
                                placeholder="{{ 'invoices.comments' | translate }}" name="comments"
                                value="{{comments}}">
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!showPaidButton()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value" [disabled]="true">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="true"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="true"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="true">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="showPaidButton()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="disbledPaid"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="disbledPaid"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="disbledPaid">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column" class="checkbox-wrapper">
                        <mat-checkbox [(ngModel)]="review" name="review">{{'invoices.review' | translate}}</mat-checkbox>
                        <span>
                            <mat-icon class="tooltip-info-title-review" aria-hidden="false" aria-label="help"
                                matTooltip="{{ 'invoices.info_review' | translate }}" matTooltipClass="tooltip" matTooltipPosition="above">
                                help_outline
                            </mat-icon>
                        </span>
                    </div>
                    <br>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center" class="control-buttons">
                        <button mat-raised-button type="button" color="warn" (click)="deleteInvoiceDialog()">{{ 'invoices.delete_button' | translate
                        }}</button>
                        <button mat-raised-button color="primary" type="button" (click)="downloadFull()">
                            {{ "invoices.download_invoice" | translate }}
                        </button>
                        <button mat-raised-button class="top-buffer bottom-buffer" type="submit" color="accent"
                            [disabled]="!f.valid || !validateProject || !type || !timeValidate || !paidModeValidate">{{ 'invoices.save_button' | translate }}</button>
                    </div>

                </mat-card>
                  <mat-card fxFlex="50%" class="image-card" *ngIf="!noImage">
                    <div class="button-flex">
                      <button mat-raised-button class="border-button"  type="button" [ngClass]="{'highlight-button': !isJustification, 'regular-button': isJustification}" (click)="changeTiquet('Factura')">{{'invoices.invoice' |translate}}</button>
                      <button mat-raised-button class="border-button" type="button" [ngClass]="{'highlight-button': isJustification, 'regular-button': !isJustification}" (click)="changeTiquet('Justificante')">{{'invoices.justification' | translate}}</button>
                    </div>
                    <!-- <mat-chip color="accent" *ngIf="!fund" class="chip-position">{{'invoices.invoice_chip' | translate}}</mat-chip> -->
                    <img class="invoice-image" [src]="src" *ngIf="(!isPDF && !isJustification) || (!isJustificationPDF && isJustification)">
                    <!-- pdf component -->
                    <ngx-extended-pdf-viewer class="overflow-pdf"
                      [src]="src"
                      [showToolbar]="true"
                      [showSidebarButton]="true"
                      [showFindButton]="true"
                      [showPagingButtons]="true"
                      [showZoomButtons]="true"
                      [showPresentationModeButton]="true"
                      [showOpenFileButton]="true"
                      [showPrintButton]="true"
                      [showDownloadButton]="true"
                      [showBookmarkButton]="true"
                      [showSecondaryToolbarButton]="true"
                      [showRotateButton]="true"
                      [showHandToolButton]="true"
                      [showScrollingButton]="true"
                      [showSpreadButton]="true"
                      [showPropertiesButton]="true"
                      [useBrowserLocale]="true" style="display: block; height: 500px;" zoom="75%"
                      *ngIf="(isPDF && !isJustification) || (isJustificationPDF && isJustification)">
                    </ngx-extended-pdf-viewer>
                    <div *ngIf="!isJustification && showEditInputs()">
                      <p *ngIf="imageError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                      <p *ngIf="extensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                      <div class="image-buttons">
                          <input type="file" id="invoiceImage" [hidden]=true name="invoiceImage" ngModel
                              (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                          <label class="upload-label" for="invoiceImage">
                              <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image' | translate
                              }}
                          </label>
                          <input type="file" id="invoicePDF" [hidden]=true name="invoicePDF" ngModel
                              (change)="loadPDF($event)" accept=".pdf">
                          <label class="upload-label label-background-pdf" for="invoicePDF">
                              <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf' | translate
                              }}
                          </label>
                      </div>
                    </div>
                    <div *ngIf="isJustification && showEditInputs()">
                      <p *ngIf="justificationError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                      <p *ngIf="justificationExtensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                      <div class="image-buttons">
                        <input type="file" id="justificationImage" [hidden]=true name="justificationImage" ngModel
                        (change)="justificationChanged($event)" accept=".jpg, .jpeg, .png">
                        <label class="upload-label" for="justificationImage">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image_just' | translate
                            }}
                        </label>
                        <input type="file" id="justificationPDF" [hidden]=true name="justificationPDF" ngModel
                            (change)="loadJustificationPDF($event)" accept=".pdf">
                        <label class="upload-label label-background-pdf" for="justificationPDF">
                            <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf_just' | translate
                            }}
                        </label>
                      </div>
                    </div>
                </mat-card>
                <mat-card fxFlex="50%" class="no-image-card" *ngIf="noImage && showEditInputs()">
                    <span>{{ 'invoices.no_image' | translate }}</span>
                    <p *ngIf="imageError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                    <p *ngIf="extensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                    <div [ngClass]="{'justification': fund}">
                        <input type="file" id="invoiceImage" [hidden]=true name="invoiceImage" ngModel
                            (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                        <label class="upload-label" for="invoiceImage">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image' | translate
                            }}
                        </label>
                        <input type="file" id="invoicePDF" [hidden]=true name="invoicePDF" ngModel
                            (change)="loadPDF($event)" accept=".pdf">
                        <label class="upload-label label-background-pdf" for="invoicePDF">
                            <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf' | translate
                            }}
                        </label>
                    </div>
                    <div *ngIf="fund" [ngClass]="{'justification': fund}">
                      <input type="file" id="justificationImage" [hidden]=true name="justificationImage" ngModel
                          (change)="justificationChanged($event)" accept=".jpg, .jpeg, .png">
                      <label class="upload-label" for="justificationImage">
                          <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image' | translate
                          }}
                      </label>
                      <input type="file" id="justificationPDF" [hidden]=true name="justificationPDF" ngModel
                          (change)="loadJustificationPDF($event)" accept=".pdf">
                      <label class="upload-label label-background-pdf" for="justificationPDF">
                          <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf' | translate
                          }}
                      </label>
                  </div>
                </mat-card>

            </div>
        </form>
    </div>

    <div class="section-footer" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag" >



    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
