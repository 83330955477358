import { Location } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from 'src/app/_services/invoices.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TasksService } from 'src/app/_services/tasks.service';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';
import { map } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { ExpendService } from 'src/app/_services/expend.service';
import { PaidManagerDialog, UnpaidManagerDialog } from 'src/app/manager-dashboard/manager-dashboard.component';
import { TaxService } from 'src/app/_services/tax.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import blobPdfFromBase64String from 'src/app/_helpers/blobPdfFromBase64String';

@Component({
  selector: 'delete-invoice-dialog',
  templateUrl: 'delete-invoice-dialog.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class DeleteInvoiceDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteInvoiceDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteInvoiceDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class InvoiceEditComponent implements OnInit {
  protected invoiceId: string;
  loadingFlag: boolean = false;
  defaultTiquet = 'Factura';
  isJustification: boolean = false;
  user: string;
  project: string;
  projects: any[];
  projectId: string;
  currentUser: Observable<any>;
  invoice_date: string;
  task: string = '';
  tasks: any[];
  description: string;
  amountWithTax: number;
  tax: number;
  amountNoTax: number;
  projectArrayObs: Observable<any[]>;
  image: string;
  pdf: Blob;
  noImage: boolean = false;
  imageError: boolean = false;
  extensionError: boolean = false;
  isPDF: boolean = false;
  type_id: any;
  type: any = '';
  types: any;
  comments: any;
  typeId: number;
  type_name: any;
  can_edit: boolean;
  is_admin: boolean;
  invoice: any[] = [];
  validateProject: boolean = true;
  sourceImg: string;
  validateNoTax : boolean = true;
  up: any;
  closed: any;
  paid: any;
  system_date: any = new Date();
  paid_modes: any[] = ['Sin valor','Transferencia', 'Domiciliación', 'Efectivo'];
  paid_mode: any = 'Transferencia';
  tax_types: any[] = [];
  tax_type: any;
  tax_type_id: any;
  private taxEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  pay_to: any;
  review: boolean;
  username: any;
  role: any;
  tiquets: string[] = [];
  paids: any[] = [
    {name: 'Sí', value: '1'},
    {name: 'No', value: '0'}
  ];
  time: any;
  auxTime: any;
  auxPaidMode: any;
  timeValidate: boolean = true;
  paidModeValidate: boolean;
  disbledPaid: boolean;
  changeSelectionProject: boolean = false;
  datestamp: any;
  justificationError: boolean;
  justificationExtensionError: boolean;
  isJustificationPDF: boolean;
  sourceJustificationImg: any;
  fund: boolean;
  noJustificationImage: boolean;
  auxUrlImg: any;
  auxUrlJust: any;
  src: string = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private invoicesService: InvoicesService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private taskService: TasksService,
    private projectService: ProjectService,
    public dialog: MatDialog,
    private router: Router,
    private location: Location,
    private expendService: ExpendService,
    private taxService: TaxService,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    this.invoiceId = routeParams.id;

    this.noImage = false;
    this.noJustificationImage = false;
    this.loadingFlag = true;
    this.invoicesService.getInvoiceType()
      .subscribe(res => {
        this.types = res.invoice_types
          .map(element => {
            element.id = +element.id
            return element;
          });

      });

    this.taxService.getTaxTypes()
      .subscribe(res => {
        this.tax_types = res['tax_types'];
        this.tax_types.push({id: this.tax_types.length + 1 + '', type: 'Sin valor'});
        this.taxEvent.next(true);
      });

    this.taxEvent.asObservable()
      .subscribe(res => {
        if(res)
          this.getProjects();
      });

      this.currentUser = this.authService.currentUser;
      this.getUserAndRole();
  }

  getUserAndRole() {
    this.currentUser.subscribe(data => {
      this.username = data.username;
      this.role = data.role;
    });
  }

  backClicked() {
    this.location.back();
  }

  getProjects() {
    this.projectService.getProjects()
      .subscribe(res => {
        this.projects = res['projects'].map(el => {
          let tObj = {};
          tObj['id'] = el.id;
          tObj['name'] = el.code + ' - ' + el.name;
          return tObj;
        });
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  getInvoiceById(id: string) {
    this.invoicesService.getInvoiceById(id).subscribe(async res => {
          this.invoice.push(res);
          this.user = res['user'];
          this.project = res['project'];
          this.projectId = res['project_id'];
          this.invoice_date = res['invoice_date'].substring(0,10);
          this.datestamp = res['invoice_date'];
          this.task = res['task_id'];
          this.description = res['description'];
          this.amountWithTax = +res['amount_with_tax'];
          this.tax = +res['tax'];
          this.amountNoTax = +res['amount_no_tax'];
          this.type = +res['invoice_type_id'];
          this.type_name = res['type'];
          this.comments = res['comments'];
          this.can_edit = res.can_edit == 'true'? true: false;
          this.is_admin = res.is_admin == 'true'? true: false;
          this.up = res['up'];
          this.closed = res['closed'];
          this.paid = res['paid'];
          this.tax_type = res['tax_type'];
          this.tax_type_id = res['tax_type_id'] ? res['tax_type_id']: this.tax_types.length + '';
          this.pay_to = res['pay_to'];
          this.review = res['review'] == 1 ? true: false;
          this.auxTime = res['time'] ? new Date(res['time']): '';
          this.fund = res['fund'] == 1 ? true: false;
          if(!this.fund) {
            this.tiquets = ['Factura'];
          } else {
            this.tiquets = ['Factura', 'Justificante'];
          }
          this.auxPaidMode = res['paid_mode'] ? res['paid_mode']: 'Transferencia';
          this.paidChange();
          this.auxUrlImg = res['url_image'];
          this.auxUrlJust = res['url_just'];
          if(this.auxUrlImg && this.auxUrlImg.indexOf('.pdf') !== -1)
            this.isPDF = true;

          this.sourceImg = this.auxUrlImg;
            if (this.sourceImg) {
              this.noImage = false;
            } else {
              this.noImage = true;
            }


          if(this.auxUrlJust && this.auxUrlJust.indexOf('.pdf') !== -1)
            this.isJustificationPDF = true;

          this.sourceJustificationImg = this.auxUrlJust;
            if (this.sourceJustificationImg) {
              this.noJustificationImage = false;
            } else {
              this.noJustificationImage = true;
            }
          setTimeout(() => {
            this.src = this.sourceImg;
          }, 2000);

          this.getTasksByProjectId(this.projectId);
        },
        (err) => {
          this.toastr.error(err, '', { timeOut: 3000 });
          this.loadingFlag = false;
        });
  }

  ngAfterViewInit(): void {
    this.getInvoiceById(this.invoiceId);
  }

  onSelectionChange(event: any) {
    this.changeSelectionProject = true;
    let name = event.option.value;
    let tempProjId = this.projects.filter(el => el.name == name);
    this.projectId = tempProjId[0].id;
    this.getTasksByProjectId(this.projectId);
  }

  getTasksByProjectId(id: string) {
    const object = JSON.stringify({
      id: id,
      offset: false
    });
    this.taskService.getTasksByProjectId(object)
      .subscribe(res => {
        this.tasks = res['tasks'];
        if(this.changeSelectionProject) {
          this.task = this.tasks[0].id;
        }
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  validatePaid() {
    if(this.paid == '1') {
      if(!this.time) {
        this.timeValidate = false;
      } else {
        this.timeValidate = true;
      }

      if(this.paid_mode == 'Sin valor') {
        this.paidModeValidate = false;
      } else {
        this.paidModeValidate = true;
      }
    } else {
      this.timeValidate = true;
      this.paidModeValidate = true;
    }
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    // this.loadingFlag = true;

    form.value.tax_type_id = form.value.tax_type_id == (this.tax_types.length + '') ? null: form.value.tax_type_id;
    form.value.review = form.value.review == true ? 1: 0;
    if(this.time) {
      const auxTime = new Date(this.time);
      form.value.time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T',' ').replace('Z','');
    }

    if(this.paid_mode == 'Sin valor') {
      form.value.paid_mode = null;
    }

    this.invoicesService.updateInvoice(this.invoiceId, this.projectId, form.value)
      .subscribe(
        (res: any) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('invoices.success_invoice_edit'), '', { timeOut: 3000 });
            if(!this.expendService.getIsManagerDashboard())
              this.router.navigate(['manager-list']);
            else
              this.router.navigate(['manager-dashboard']);
          } else {
            this.toastr.error(this.translate.instant('invoices.error_invoice_edit'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  deleteInvoiceDialog() {
    const dialogRef = this.dialog.open(DeleteInvoiceDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteInvoice(this.invoiceId);
      }
    });
  }

  deleteInvoice(invoiceId: string) {
    this.loadingFlag = true;

    this.invoicesService.deleteInvoice(invoiceId)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('invoices.success_invoice_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  if(!this.expendService.getIsManagerDashboard())
                    this.router.navigate(['manager-list']);
                  else
                    this.router.navigate(['manager-dashboard']);
                }
              );
          } else {
            this.toastr.error(this.translate.instant('invoices.error_invoice_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  changeTiquet(value) {

    if(value == 'Factura') {
      this.isJustification = false;
      this.src = this.sourceImg;
    } else {
      this.isJustification = true;
      this.src = this.sourceJustificationImg;
    }
  }

  imageChanged(event: any) {
    let fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    let fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {
      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.updateImage(this.invoiceId, dataUrl).
          subscribe(res => {
            if (res['status'] == 1) {
              this.imageError = false;
              this.isPDF = false;
              this.sourceImg = dataUrl;
              this.src = this.sourceImg;
              this.toastr.success(this.translate.instant('invoices.success_image_upload'), '', { timeOut: 3000 });
            } else {
              this.imageError = true;
            }
          }, () => {
            this.imageError = true;
          });
      }
      fr.readAsDataURL(fileContent);
    } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
      this.extensionError = true;
    }
  }

  justificationChanged(event: any) {
    let fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.justificationError = true;
      return;
    }

    let fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {
      this.justificationError = false;
      this.justificationExtensionError = false;
      const fr = new FileReader();
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.saveJustification(dataUrl, this.project, new Date().toISOString(), this.invoiceId, '').
          subscribe(res => {
            if (res['status'] == 1) {
              this.justificationError = false;
              this.isJustificationPDF = false;
              this.sourceJustificationImg = dataUrl;
              this.src = this.sourceJustificationImg;
              this.toastr.success(this.translate.instant('invoices.success_justification_upload'), '', { timeOut: 3000 });
            } else {
              this.justificationError = true;
            }
          }, () => {
            this.justificationError = true;
          });
      }
      fr.readAsDataURL(fileContent);
    } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
      this.justificationExtensionError = true;
    }
  }

  loadPDF(event) {
    const fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    const fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if(fileExtension == 'pdf') {
      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.readAsDataURL(fileContent);
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.updateImage(this.invoiceId, dataUrl).
          subscribe(res => {
            if (res['status'] == 1) {
              this.imageError = false;
              this.image = dataUrl;
              this.isPDF = true;
              this.sourceImg = URL.createObjectURL(blobPdfFromBase64String(this.image));
              this.src = this.sourceImg;
              this.toastr.success(this.translate.instant('invoices.success_image_upload'), '', { timeOut: 3000 });
            } else {
              this.imageError = true;
            }
          }, () => {
            this.imageError = true;
          });
      }

    } else {
      this.extensionError = true;
    }
  }

  loadJustificationPDF(event) {
    const fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.justificationError = true;
      return;
    }

    const fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if(fileExtension == 'pdf') {
      this.justificationError = false;
      this.justificationExtensionError = false;

      const fr = new FileReader();
      fr.readAsDataURL(fileContent);
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.saveJustification(dataUrl, this.project, new Date().toISOString(), this.invoiceId, '').
          subscribe(res => {
            if (res['status'] == 1) {
              this.justificationError = false;
              this.isJustificationPDF = true;
              this.sourceJustificationImg = URL.createObjectURL(blobPdfFromBase64String(dataUrl));
              this.src = this.sourceJustificationImg
              this.toastr.success(this.translate.instant('invoices.success_justification_upload'), '', { timeOut: 3000 });
            } else {
              this.justificationError = true;
            }
          }, () => {
            this.justificationError = true;
          });
      }

    } else {
      this.justificationExtensionError = true;
    }
  }

 showEditInputs() {
    if( this.can_edit || this.is_admin || this.role == 3)
      return true;
    else
      return false;
  }

  showPaidButton() {
    if( this.is_admin || this.role == 3)
      return true;
    else
      return false;
  }

  getTaxTypeName(id) {
    const taxEl = this.tax_types.filter(tax => tax.id == id);
    return taxEl[0].type;
  }

  getCSV() {
    let data = this.invoice.map(el => {
      let tObj = {};
      tObj[this.translate.instant('dashboard.project')] = el.project;
      tObj[this.translate.instant('dashboard.task')] = el.task;
      tObj[this.translate.instant('dashboard.user')] = el.user;
      tObj[this.translate.instant('dashboard.date')] = el.invoice_date;
      tObj[this.translate.instant('dashboard.amount_with_tax')] = (+el.amount_with_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.tax_type')] = this.getTaxTypeName(el.tax_type_id);
      tObj[this.translate.instant('dashboard.amount_no_tax')] = (+el.amount_no_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('dashboard.description')] = el.description;
      tObj[this.translate.instant('dashboard.invoice_type')] = el.type;
      tObj[this.translate.instant('dashboard.unique_identifier')] = el.unique_identifier;
      tObj[this.translate.instant('dashboard.unique_identifier_fact')] = el.unique_identifier + '_fact';
      tObj[this.translate.instant('dashboard.unique_identifier_just')] = el.unique_identifier_just + '_just';
      tObj[this.translate.instant('dashboard.comments')] = el.comments;
      tObj[this.translate.instant('dashboard.paid')] = el.paid == '1'? 'Sí': 'No';
      tObj[this.translate.instant('dashboard.up')] = el.up;
      tObj[this.translate.instant('dashboard.fund')] = el.fund == '1'? 'Sí': 'No';
      tObj[this.translate.instant('dashboard.pay_to')] = el.pay_to;
      tObj[this.translate.instant('dashboard.time')] = el.time;
      tObj[this.translate.instant('dashboard.paid_mode')] = el.paid_mode;
      return tObj;
    });

    let fields = this.invoice[0].length;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    // Get CSV:
    let output = this.getCSV();
    // Create ZIP:
    var zip = new JSZip();
    // Add CSV:
    zip.file('facturas.csv', output);
    // Create image folder:
    var img = zip.folder("invoices_files");
    // Get all images by Id:
    let ids = Array(this.invoiceId);
    this.invoicesService.downloadInvoicesImages(ids.toString())
      .subscribe(res => {
        // Loop res:
        if(res['invoices'])
        res['invoices'].forEach(el => {
          let el_string = el.image_url.toString();
          //Dowload pdf
          if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + '_fact' + ext, el.image_url.replace('data:application/pdf;base64,', ""), { base64: true });
          //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '_fact' + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });

        if(res['justifications'])
        res['justifications'].forEach(el => {
          let el_string = el.just_url.toString();
          //Dowload pdf
          if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + '_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
          //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });

        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "facturas.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 3000 });
      });
  }

  calculateAmountWithoutTax() {
    if((this.amountWithTax || this.amountWithTax === 0)){
      const auxTax = this.tax_types.filter(element => element.id == this.tax_type_id);
      if(auxTax.length != 0) {
        const result = this.roundToTwo(this.amountWithTax/(1 + auxTax[0].type/100));
        if(result < 0) {
          this.amountNoTax = null;
          this.validateNoTax = false;
        } else {
          this.amountNoTax = result;
          this.validateNoTax = true;
        }
      }
    }
  }

  roundToTwo(num) {
    return Math.round( num * 100 + Number.EPSILON ) / 100;
  }

  doFilter() {
    this.projectArrayObs = of(this.projects);
    this.projectArrayObs = this.projectArrayObs
      .pipe(
        map(res => this.filter(res))
      )

      this.onBlurProject();

  }

  filter(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name.toLowerCase().includes(this.project.toLowerCase()))

  }

  filterValidate(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name == this.project
    );
  }

  onBlurProject() {
    const project = this.filterValidate(this.projects);
    if(project.length == 1) {
      this.getTasksByProjectId(project[0].id);
      this.projectId = project[0].id;
      this.validateProject = true;
    } else {

      this.validateProject = false;
    }

  }

  private generateRandom() {
    return Math.floor(Math.random() * 100000);
  }

  paidInvoiceDialog() {
    const dialogRef = this.dialog.open(PaidManagerDialog, {
      width: '500px',
      data: {
        system_date: this.system_date,
        paid_modes: this.paid_modes,
        paid_mode: this.paid_mode,
        number: 1
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paid_mode = result.paid_mode;
        const auxTime = new Date(result.system_date);
        const time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T',' ').replace('Z','');
        this.paidInvoiceById(this.invoiceId, 'invoice', time);

      }
    });
  }

  paidInvoiceById(id, type, time) {
    this.loadingFlag = true;
    this.expendService.paidCostById(id, type, time, this.paid_mode)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('manager.success_paid'), '', {timeOut: 3000});
          if(!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectCloseEvent.next(true);
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_paid'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_paid'), '', {timeOut: 3000})
      });
  }

  unpaidInvoiceDialog() {
    const dialogRef = this.dialog.open(UnpaidManagerDialog, {
      width: '500px',
      data: {number: 1}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unpaidInvoiceById(this.invoiceId, 'invoice');
      }
    });
  }

  unpaidInvoiceById(id, type) {
    this.loadingFlag = true;
    this.expendService.unpaidCostById(id, type)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('manager.success_unpaid'), '', {timeOut: 3000});
          if(!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectOpenEvent.next(true);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_unpaid'), '', {timeOut: 3000})
      });
  }

  paidChange() {

    if(this.paid == '0') {
      this.disbledPaid = true;
      this.auxTime = this.time;
      this.auxPaidMode = this.paid_mode;
      this.time = '';
      this.paid_mode = this.paid_modes[0];
    } else {
      this.time = this.auxTime? this.auxTime: new Date();
      this.paid_mode = this.auxPaidMode? this.auxPaidMode: '';
      this.disbledPaid = false;
    }
    this.validatePaid();
  }

}
