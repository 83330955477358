<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div *ngIf="!loadingFlag" class="section-header">
        <h1>{{ 'agrestatrips.title_edit' | translate }}</h1>
        <button mat-stroked-button (click)="backClicked()">
            <mat-icon aria-hidden="false" aria-label="chevron_left">chevron_left</mat-icon>
            {{ 'agrestatrips.back_button' | translate }}
        </button>
    </div>

    <div class="view-container" [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag">
        <form #f="ngForm" (submit)="onSubmit(f)">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                <mat-card fxFlex="50%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='this.user' placeholder="{{ 'agrestatrips.user' | translate }}"
                                name="user" value="{{user}}" readonly>
                        </mat-form-field>
                        
                        <mat-form-field>
                            <mat-label>{{ 'agrestatrips.vehicle' | translate }}</mat-label>
                            <mat-select name="vehicle_id" [(ngModel)]="vehicle_id">
                                <mat-option *ngFor="let value of vehicles" [value]="value.id" [disabled]="!showEditInputs()">
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">

                        <mat-form-field>
                            <mat-label>{{ 'agrestatrips.dateFrom' | translate }}</mat-label>
                            <input matInput [(ngModel)]="dateFrom" [matDatepicker]="pickerFrom" placeholder="{{ 'agrestatrips.dateFrom' | translate }}" name="dateFrom"  [readonly]="!showEditInputs()" [required]="showEditInputs()">
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom" [disabled]="!showEditInputs()"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="!showEditInputs()"></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'agrestatrips.dateTo' | translate }}</mat-label>
                            <input matInput [(ngModel)]="dateTo" [matDatepicker]="pickerTo" placeholder="{{ 'agrestatrips.dateTo' | translate }}" name="dateTo"  [readonly]="!showEditInputs()" [required]="showEditInputs()">
                            <mat-datepicker-toggle matSuffix [for]="pickerTo" [disabled]="!showEditInputs()"></mat-datepicker-toggle>
                            <mat-datepicker #pickerTo [disabled]="!showEditInputs()"></mat-datepicker>
                        </mat-form-field>

                        <!-- <mat-form-field *ngIf="showEditInputs()">
                            <mat-label>{{ 'agrestatrips.date' | translate }}</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="pickerFrom" placeholder="{{ 'agrestatrips.date' | translate }}" name="date"  required>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field> -->
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="!showEditInputs()">
                        <mat-form-field>
                            <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="project"
                                placeholder="{{ 'agrestatrips.project' | translate }}" name="project" value="{{project}}"
                                readonly>
                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                (optionSelected)="onSelectionChange($event)">
                                <mat-option *ngFor="let project of projects" [value]="project.name">
                                    {{project.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'agrestatrips.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id" disabled>
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" *ngIf="showEditInputs()">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto"
                                   name="project" #projectField="ngModel"
                                   placeholder="{{ 'agrestatrips.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'agrestatrips.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-label>{{ 'agrestatrips.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id" >
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="localization_start" placeholder="{{ 'agrestatrips.localization_start' | translate }}"
                                name="localization_start" value="{{localization_start}}" [readonly]="!showEditInputs()" [required]="showEditInputs()">
                        </mat-form-field>
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="localization_end" placeholder="{{ 'agrestatrips.localization_end' | translate }}"
                                name="localization_end" value="{{localization_end}}" [readonly]="!showEditInputs()" [required]="showEditInputs()">
                        </mat-form-field>
                    </div>
                    
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="showEditInputs()">
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="kmStart" (ngModelChange)="showEditInputs() && updateTotalKm()" placeholder="{{ 'agrestatrips.km_start' | translate }}" name="kmStart" value="{{kmStart}}" [readonly]="!showEditInputs()">
                        </mat-form-field>

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="kmEnd" (ngModelChange)="showEditInputs() && updateTotalKm()" placeholder="{{ 'agrestatrips.km_end' | translate }}" name="kmEnd" value="{{kmEnd}}" [readonly]="!showEditInputs()">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="totalKm" placeholder="{{ 'agrestatrips.km_total' | translate }}"
                                name="totalKm" value="{{totalKm}}" [readonly]="!showEditManager()" [required]="showEditManager()" [disabled]="true">
                        </mat-form-field>
                        <div></div>
                    </div>
                    <br>
                    <div>
                        <br>
                        <!-- Buttons to remove register and download bill -->
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                            <div class="section-footer" [class.force-margin-bottom]="!loadingFlag" *ngIf="showEditManager() || showEditInputs()">
                                <button type="button" mat-raised-button color="warn" (click)="deleteAgrestatripDialog()">{{ 'agrestatrips.delete_button' | translate
                                    }}</button>
                            </div>
                            <div class="section-footer">
                              <button mat-raised-button color="primary" type="button" (click)="downloadFull()" [disabled]="noImage">
                                  {{ "agrestatrips.download_captures_button" | translate }}
                              </button>
                            </div>
                            <div class="section-footer" *ngIf="showEditManager() || showEditInputs()">
                                <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid || !validateProject">
                                    {{ "cartrip.title_edit" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card fxFlex="50%" class="image-card">
                    <img *ngIf="!noImage" class="invoice-image" [src]="sourceImg">
                    <p *ngIf="noImage">{{ 'agrestatrips.no_image' | translate }}</p>
                  <div>
                    <p *ngIf="imageError" class="error-p">{{ 'agrestatrips.error_image' | translate }}</p>
                    <p *ngIf="extensionError" class="error-p">{{ 'agrestatrips.error_extension' | translate }}</p>
                    <div class="image-buttons" *ngIf="showEditManager() || showEditInputs()">
                        <input type="file" id="agrestatripImage" [hidden]=true name="agrestatripImage" ngModel
                            (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                        <label *ngIf="!noImage" class="upload-label" for="agrestatripImage">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'agrestatrips.select_image' | translate }}
                        </label>
                        <label *ngIf="noImage" class="upload-label" for="agrestatripImage">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'agrestatrips.upload_image' | translate }}
                        </label>
                    </div>
                  </div>
              </mat-card>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>