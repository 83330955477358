import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeadquartersService {

  private API_URL = environment.API_URL;
  constructor(
    private http: HttpClient
  ) { }

  getHeadquarters() {
    return this.http.get(`${this.API_URL}/headquarters`);
  }

  changeHeadquarterToUserById(headquarter_id, invoice_id, cartrip_id, overnight_id) {
    return this.http.put(`${this.API_URL}/headquarters/` + 0, {
      headquarter_id,
      invoice_id,
      cartrip_id,
      overnight_id
    });
  }
}
