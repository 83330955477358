import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-expend-add',
  templateUrl: './expend-add.component.html',
  styleUrls: ['./expend-add.component.scss']
})
export class ExpendAddComponent implements OnInit {

  constructor(
    private activatedRouter: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRouter.queryParams
      .subscribe( (params) => {
        switch(params.expend) {
          case 'invoice':
            this.router.navigate(['/invoice-add']);
            break;
          case 'overnight':
            this.router.navigate(['/overnight-add']);
            break;
          case 'cartrip':
            this.router.navigate(['/cartrip-add']);
            break;
          case 'agrestatrip':
            this.router.navigate(['/agrestatrip-add']);
            break;
        }
        }
      );
  }

}
