import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private API_URL = environment.API_URL;

  constructor(
    private http: HttpClient
  ) { }

  recoverPassword(username: string) {
    return this.http.post<any>(`${this.API_URL}/recoverpassword`, {
      username
    });
  }

  hashCheck(hash: string) {
    return this.http.get<any>(`${this.API_URL}/newpassword/` + hash);
  }

  newPassword(event: string, hash: string) {
    return this.http.post<any>(`${this.API_URL}/newpassword`, {
      event,
      hash
    });
  }
}