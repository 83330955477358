
<div class="main-section">
    <h1 [hidden]="loadingFlag">{{ 'overnight.title_list' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag" class="mat-elevation-z8 filter-box">
        <h3 class="align-text">{{ 'overnight.filter_overnight' | translate }}</h3>
        <div class="flex-comtainer">
            <form [formGroup]="searchForm" *ngIf="searchForm">
                <mat-form-field  appearance="outline" [ngClass]="{'highlight': true}">
                    <mat-label>{{ 'overnight.paid' | translate }}</mat-label>
                    <!-- <input type="text" aria-label="{{ 'overnight.amount_to' | translate }}" matInput
                        formControlName="amountTo" (keyup)="applyFilter()"> -->
                    <mat-select aria-label="{{ 'overnight.paid' | translate }}" formControlName="paid" (selectionChange)="applyFilter()">
                        <mat-option *ngFor="let value of paidArray" [value]="value.value">
                            {{value.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>
            <form class="search-form">
                <mat-form-field appearance="outline" [ngClass]="{'highlight': dateFrom}">
                    <mat-label>{{ 'overnight.date_from' | translate }}</mat-label>
                    <input matInput [(ngModel)]="dateFrom" name="dateFrom" [matDatepicker]="pickerFrom" placeholder="{{ 'overnight.date_from' | translate }}" name="dateFrom" (ngModelChange)="dateFromChange()">
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                </mat-form-field>
                <mat-form-field  appearance="outline" [ngClass]="{'highlight': dateTo}">
                    <mat-label>{{ 'overnight.date_to' | translate }}</mat-label>
                    <input type='sub' matInput [(ngModel)]="dateTo" name="dateTo" (ngModelChange)="dateFromChange()" [matDatepicker]="pickerTo" placeholder="{{ 'overnight.date_to' | translate }}" name="dateTo">
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                </mat-form-field>
            </form>
        </div>
        
        
    </div>

    <div [hidden]="loadingFlag">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'overnight.user' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.user}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="nr_nights_int">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'overnight.nr_nights_int' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.nr_nights_int}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="nr_nights_nat">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'overnight.nr_nights_nat' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.nr_nights_nat}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="amount_no_tax">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'overnight.amount_no_tax' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.amount_no_tax | currency:'EUR'}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalAmountWithoutTax | currency:'EUR'}}</td>
            </ng-container>

            <ng-container matColumnDef="paid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'overnight.paid' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.paid}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>{{ 'overnight.action' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="multibutton">
                    <button *ngIf="showEditButton() && element.paid == 'No'" mat-icon-button color="accent" (click)="paidChecker(element.user)" matTooltip="{{ 'overnight.paid_checker' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>add_circle_outline</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>
    <div *ngIf="!loadingFlag" class="download-row" [class.force-margin-bottom]="!loadingFlag">
        <button mat-raised-button color="accent" (click)="downloadCSV()">
            {{ 'manager.download_csv' | translate }}
        </button>
    </div>
    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
