import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../_services/profile.service';
import { ToastrService } from 'ngx-toastr';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  protected id: string;
  username: string;
  email: string;
  role: number;
  loadingFlag: boolean = false;

  constructor(
    private profileService: ProfileService,
    private toastr: ToastrService,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.loadingFlag = true;
    this.getProfile();
  }

  getProfile() {
    this.profileService.getProfile()
      .subscribe(res => {
        this.username = res['username'];
        this.email = res['email'];
        this.role = res['role'];

        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });

    this.managerService.setVoidFilter();
  }
}
