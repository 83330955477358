import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface InvoiceTable {
  id: number;
  project: string;
  task: string;
  user: string;
  date: string;
  amount: string;
  unique_identifier: string;
}

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  private API_URL = environment.API_URL;

  private user: string;
  private project: string;
  private task: string;
  private amountFrom: string;
  private amountTo: string;
  private dateFrom: string;
  private dateTo: string;

  constructor(
    private http: HttpClient
  ) { }

  getInvoices(): Observable<InvoiceTable[]> {
    return this.http.get<InvoiceTable[]>(`${this.API_URL}/invoices`);
  }

  getInvoiceById(id: string) {
    return this.http.get<any>(`${this.API_URL}/invoices/` + id);
  }

  getInvoiceType() {
    return this.http.get<any>(`${this.API_URL}/invoice/invoicetype`);
  }


  updateInvoice(id: string, projectId: string, event: any) {
    return this.http.put<any>(`${this.API_URL}/invoices/` + id,
      {
        projectId,
        event
      });
  }

  deleteInvoice(id: string) {
    return this.http.delete<any>(`${this.API_URL}/invoices/` + id);
  }

  updateImage(invoiceId: string, image: string) {
    return this.http.post<any>(`${this.API_URL}/image`,
      {
        invoiceId,
        image
      });
  }

  downloadInvoicesImages(ids: string) {
    return this.http.get<any>(`${this.API_URL}/download/` + ids);
  }

  saveJustification(image: string, project_name: string, date: string, invoice_id: string, cartrip_id: string) {
    const id = {invoice_id: invoice_id, cartrip_id: cartrip_id};
    return this.http.put<any>(`${this.API_URL}/image/${JSON.stringify(id)}`,
    {
      image,
      project_name,
      date
    });

  }

  setFilters(user: string, project: string, task: string, amountFrom: string, amountTo: string, dateFrom: string, dateTo: string) {
    this.user = user;
    this.project = project
    this.task = task;
    this.amountFrom = amountFrom;
    this.amountTo = amountTo;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
  }

  getFilters() {
    return [
      this.user,
      this.project,
      this.task,
      this.amountFrom,
      this.amountTo,
      this.dateFrom,
      this.dateTo
    ];
  }

  createInvoice(image: string, event: any, projectId: any, date: string) {
    return this.http.post(`${this.API_URL}/invoices`, {
      image,
      event,
      projectId,
      date
    });
  }

  getPDF(url: string) {
    return this.http.get(url, {
      responseType: 'blob'
    });
  }
}
