import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../_services/role.service';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.scss']
})
export class RoleEditComponent implements OnInit {
  protected id: string;
  name: string;
  previousName: string;
  loadingFlag: boolean = false;

  constructor(
    private activedRoute: ActivatedRoute,
    private roleService: RoleService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.loadingFlag = true;
    const routeParams = this.activedRoute.snapshot.params;
    this.id = routeParams.id;
    this.getRoleById(routeParams.id);
  }

  getRoleById(id: string) {
    this.roleService.getRoleById(id)
      .subscribe(res => {
        this.name = res['name'];
        this.previousName = this.name;
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });
    this.managerService.setVoidFilter();
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.roleService.updateRole(this.id, event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('roles.success_role_edit'), '', { timeOut: 3000 });
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('roles.error_role_exist'), '', { timeOut: 3000 });
            this.name = this.previousName;
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('roles.error_role_edit'), '', { timeOut: 3000 });
            this.name = this.previousName;
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

}
