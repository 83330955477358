<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="section-header" *ngIf="!loadingFlag">
        <h1>{{ 'overnight.title_add' | translate }}</h1>
    </div>

    <div class="form-container" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" fxLayout.lt-lg="column">
                <mat-card fxFlex="100%">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" >
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='user' placeholder="{{ 'overnight.user' | translate }}"
                                name="user" readonly required>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="date" matInput [(ngModel)]="overnight_date" placeholder="{{ 'overnight.overnight_date' | translate }}"
                                name="date" required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto" 
                                   name="project" #projectField="ngModel" 
                                   placeholder="{{ 'overnight.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'overnight.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>
                        
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'overnight.task' | translate }}"
                                name="task"
                                [(ngModel)]="task"
                                [disabled]="!tasks || tasks.length === 0"
                            >
                                <mat-option *ngFor="let task of tasks" [value]="task.id"> 
                                    {{ task.name }}
                                </mat-option>
                            </mat-select>
                            <mat-spinner *ngIf="loadingTasksFlag" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="description"
                                placeholder="{{ 'overnight.description' | translate }}" name="description"
                                value="{{description}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_nights_int"
                                placeholder="{{ 'overnight.nr_nights_int' | translate }}" name="nr_nights_int"
                                value="{{nr_nights_int}}">
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_nights_nat"
                                placeholder="{{ 'overnight.nr_nights_nat' | translate }}" name="nr_nights_nat"
                                value="{{nr_nights_nat}}">
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-raised-button class="top-buffer bottom-buffer" type="submit" color="accent"
                            [disabled]="!f.valid || !validateProject">{{ 'overnight.save_button' | translate }}</button>
                    </div>
                </mat-card>
                
            </div>
        </form>
    </div>

    <div class="section-footer" id="flex-end" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag" >
    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
