import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface TaskTable {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class TasksService {
  private API_URL = environment.API_URL

  constructor(
    private http: HttpClient
  ) { }

  getTasksByProjectId(id: string): Observable<TaskTable[]> {
    return this.http.get<TaskTable[]>(`${this.API_URL}/tasks/` + id);
  }

  addTask(projectId: string, event: any) {
    let name = event.nameAdd;
    return this.http.post<any>(`${this.API_URL}/tasks`, {
      projectId,
      name
    });
  }

  editTask(projectId: string, taskId: string, newName: any) {
    let name = newName;
    return this.http.put<any>(`${this.API_URL}/tasks/` + taskId, {
      projectId,
      name
    });
  }

  deleteTask(taskId: string) {
    return this.http.delete<any>(`${this.API_URL}/tasks/` + taskId, {});
  }
}
