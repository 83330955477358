import { Component, OnInit } from "@angular/core";
import { TasksService } from "../_services/tasks.service";
import { ProjectService } from "../_services/project.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../_services/authentication.service";
import { NgForm } from "@angular/forms";
import { Observable, map, of } from "rxjs";
import { OvernightService } from "../_services/overnight.service";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";

@Component({
    selector: 'app-overnight-add',
    templateUrl: './overnight-add.component.html',
    styleUrls: ['./overnight-add.component.scss']
})
export class OvernightAddComponent implements OnInit {
    loadingFlag: boolean = false;
    loadingTasksFlag: boolean = false;

    user: string;
    project: string;
    projects: any[];
    validateProject: boolean = true;
    projectArrayObs: Observable<any[]>;
    projectId: string;
    task: string = '';
    tasks: any[] = [];

    description: string;
    overnight_date: string;
    nr_nights_int: number;
    nr_nights_nat: number;

    constructor(
        private taskService: TasksService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private authService: AuthenticationService,
        private overnightService: OvernightService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadingFlag = true;
        this.getProjects();
        this.user = this.authService.currentUserValue.username;
    }

    onSubmit (form: NgForm) {
        this.loadingFlag = true;

        const date = new Date().toISOString().replace('T',' ').replace('z', '').slice(0, -5);

        this.overnightService.addOvernight(this.projectId, date, form.value)
          .subscribe(res => {
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('overnight.success_overnight_add'), '', { timeOut: 3000 });
            this.router.navigate(['/manager-list']);
          }, error => {
            this.loadingFlag = false;
            this.toastr.error(this.translate.instant('overnight.error_overnight_add'), '', { timeOut: 3000 });
          });
    }

    getProjects() {
        this.projectService.getProjects()
          .subscribe(res => {
            this.projects = res['projects'].map(el => {
              let tObj = {};
              tObj['id'] = el.id;
              tObj['name'] = el.code + ' - ' + el.name;
              return tObj;
            });
            this.loadingFlag = false;
          },
            (err) => {
              this.loadingFlag = false;
            });
    }

    onSelectionChange(event: any) {
        this.task = '';
        let name = event.option.value;
        let tempProjId = this.projects.filter(el => el.name == name);
        this.projectId = tempProjId[0].id;
        this.getTasksByProjectId(this.projectId);
    }

    getTasksByProjectId(id: string) {

      this.loadingTasksFlag = true;

        const object = JSON.stringify({
          id: id,
          offset: false
        });
        this.taskService.getTasksByProjectId(object)
          .subscribe(res => {
            this.tasks = res['tasks'] ? res['tasks']: [];
            if(this.tasks.length > 0){
              this.task = this.tasks[0].id;
            }
            // this.loadingFlag = false;
            this.loadingTasksFlag = false;
          },
            (err) => {
              // this.loadingFlag = false;
            this.loadingTasksFlag = false;
            });
    }

    doFilter() {
        this.projectArrayObs = of(this.projects);
        this.projectArrayObs = this.projectArrayObs
          .pipe(
            map(res => this.filter(res))
          )
    
          this.onBlurProject();
    
      }
    
      filter(projects) {
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name.toLowerCase().includes(this.project.toLowerCase()))
    
      }
    
      onBlurProject() {
        const project = this.filterValidate(this.projects);
        if(project.length == 1) {
          this.getTasksByProjectId(project[0].id);
          this.projectId = project[0].id;
          this.validateProject = true;
        } else{
    
          this.validateProject = false;
        }
    
      }

      filterValidate(projects) {
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name == this.project
        );
      }
}