import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Role } from '../_models/role';
import { AuthenticationService } from '../_services/authentication.service';
import { OvernightService } from '../_services/overnight.service';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import { ManagerService } from '../_services/manager.service';

export interface OvernightTable {
  user: string;
  amount_no_tax: number;
  nr_nights_int: number;
  nr_nights_nat: number;
  paid: string;
}

@Component({
  selector: 'app-overnight-list',
  templateUrl: './overnight-list.component.html',
  styleUrls: ['./overnight-list.component.scss']
})
export class OvernightListComponent implements OnInit {

  loadingFlag: boolean;
  searchForm: FormGroup;
  paid: string = 'No';
  dataSource = new MatTableDataSource<OvernightTable>();
  totalAmountWithoutTax: number;
  paidArray: Object[] = [
    {name: 'Si', value: 'Si'}, 
    {name: 'No', value: 'No'}
  ];
  currentUser: any;
  username: any;
  role: any;
  dateFrom: Date;
  dateTo: Date;
  allOvernights: any;
  filterAllOvernights: any;

  downloadColumns: string[] = [
    this.translate.instant('overnight.project'),
    this.translate.instant('overnight.task'),
    this.translate.instant('overnight.user'),
    this.translate.instant('overnight.date'),
    this.translate.instant('overnight.amount_no_tax'),
    this.translate.instant('overnight.description'),
    this.translate.instant('overnight.nr_nights_int'),
    this.translate.instant('overnight.nr_nights_nat'),
    this.translate.instant('overnight.paid'),
    this.translate.instant('overnight.payroll')
  ];

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  displayedColumns: string[] = ['user', 'nr_nights_int', 'nr_nights_nat', 'amount_no_tax', 'paid', 'actions'];

  constructor(
    private authService: AuthenticationService,
    private overnightService: OvernightService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private router: Router,
    private managerService: ManagerService
  ) { }

  ngOnInit(): void {
    this.dateFrom = null;
    this.dateTo = null;
    this.getOvernights();
    this.getAllOvernights();
    this.currentUser = this.authService.currentUser;
    this.getUserAndRole();
    this.managerService.setVoidFilter();
  }

  getUserAndRole() {
    this.currentUser.subscribe(data => {
      this.username = data.username;
      this.role = data.role;
    });
  }

  showEditButton() {
    if( this.role === Role.Admin || this.role === Role.Manager)
      return true;
    else
      return false;
  }

  getOvernights() {
    this.loadingFlag = true;
    this.overnightService.getOvernights()
      .subscribe(res => {

        res['overnights'] = !res['overnights'] ? []: res['overnights'];

        const OVERNIGHT_DATA: OvernightTable[] = res['overnights']
          .map(element => {
            element['paid'] = element['paid'] == '1'? 'Si': 'No';
            return element;
          });
        this.dataSource.data = OVERNIGHT_DATA;

        this.totalAmountWithoutTax = res['overnights'].map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
        this.searchFormInit();
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  searchFormInit() {
    this.searchForm = new FormGroup({
      paid: new FormControl(this.paid == 'No' ? 'No' : this.paid)
    });
  }

  applyFilter() {
    const paid = this.searchForm.get('paid').value;

    this.paid = paid === null ? 'No' : paid;

    // Create string of our searching values and split if by '$'
    const filterValue = this.paid + '$';
    this.dataSource.filter = filterValue.trim();

    // Recalculate total:
    this.totalAmountWithoutTax = this.dataSource.filteredData.map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
  }

  getFilterPredicate() {
    return (row: OvernightTable, filters: string) => {
      // Split string per '$' to array
      const filterArray = filters.split('$');
      const paid = filterArray[0];
      const matchFilter = [];

      // Fetch data from row
      const columnPaid = row.paid;

      // Verify fetching data by our searching values
      let customFilterPaid;

      // Add maximum and minimum dates if fields are blank
      if(columnPaid == paid) {
        customFilterPaid = true;
      }

      // Push boolean values into array
      matchFilter.push(customFilterPaid);

      // Return true if all values in array is true
      // else return false
      return matchFilter.every(Boolean);
    };
  }

  paidChecker(user: string) {
    let dateTo;
    let dateFrom;
    if(this.dateFrom){
      dateFrom =  this.dateFrom.toISOString().split('T')[0];
    } else {
      dateFrom = '';
    }
    if(this.dateTo){
      const auxDateTo = new Date(this.dateTo.valueOf());
      auxDateTo.setDate(auxDateTo.getDate() + 1);
      dateTo =  auxDateTo.toISOString().split('T')[0];
    } else {
      dateTo = '';
    }
    this.overnightService.paidChecker(user, dateFrom, dateTo)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('overnight.success_overnight_paid'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.ngOnInit();
                }
              );
          } else {
            this.toastr.error(this.translate.instant('overnight.error_overnight_paid'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  dateFromChange() {

    let dateTo;
    let dateFrom;
    let auxDateTo;
    if(this.dateFrom){
      dateFrom =  this.dateFrom.toISOString().split('T')[0];
    } else {
      dateFrom = '';
    }
    if(this.dateTo){
      auxDateTo = new Date(this.dateTo.valueOf());
      auxDateTo.setDate(auxDateTo.getDate() + 1);
      dateTo =  auxDateTo.toISOString().split('T')[0];
    } else {
      dateTo = '';
    }

    if(this.dateTo && this.dateFrom) {
      const diferenceDates = this.dateTo.valueOf() - this.dateFrom.valueOf(); 
      if(diferenceDates < 0) {
        this.toastr.error(this.translate.instant('overnight.diference_date_error'), '', { timeOut: 3000 });
        return;
      }

      this.filterAllOvernights = this.allOvernights
        .filter(element => {
          return (new Date(element.date_end) >= this.dateFrom) && (new Date(element.date_end) < auxDateTo);
        });

    }

    if(this.dateFrom && !this.dateTo) {
      this.filterAllOvernights = this.allOvernights
        .filter(element => {
          return (new Date(element.date_end) >= this.dateFrom);
        });
    }

    if(!this.dateFrom && this.dateTo) {
      this.filterAllOvernights = this.allOvernights
        .filter(element => {
          return (new Date(element.date_end) < dateTo);
        });
    }

    if(!this.dateTo && !this.dateFrom){
      this.getOvernights();
      return;
    }

    
    
    
    
    
    this.overnightService.filterDate(dateFrom, dateTo)
      .subscribe(res => {
        const OVERNIGHT_DATA: OvernightTable[] = res['overnights']
          .map(element => {
            element['paid'] = element['paid'] == '1'? 'Si': 'No';
            return element;
          });
        this.dataSource.data = OVERNIGHT_DATA;

        this.totalAmountWithoutTax = res['overnights'].map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
        this.searchFormInit();
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });

  }

  getAllOvernights() {
    this.overnightService.getAllOvernights()
      .subscribe(res => {
        this.allOvernights = res.overnights;
        this.filterAllOvernights = res.overnights;
      });
  }

  downloadCSV() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('overnight.downloading_csv'), '', { timeOut: 3000 });

    let output = this.getCSV();
    saveAs(output, 'pernoctas.csv');

    this.loadingFlag = false;
    this.toastr.success(this.translate.instant('overnight.downloading_csv_success'), '', { timeOut: 3000 });
  }

  getCSV() {
    const paid = this.searchForm.get('paid').value;

    let data = this.filterAllOvernights.map(el => {
      let tObj = {};
      tObj[this.translate.instant('overnight.project')] = el.project;
      tObj[this.translate.instant('overnight.task')] = el.task;
      tObj[this.translate.instant('overnight.user')] = el.user;
      tObj[this.translate.instant('overnight.date')] = el.date_end;
      tObj[this.translate.instant('overnight.amount_no_tax')] = (+el.amount_no_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('overnight.description')] = el.description;
      tObj[this.translate.instant('overnight.nr_nights_int')] = (+el.nr_nights_int).toLocaleString('es-ES');
      tObj[this.translate.instant('overnight.nr_nights_nat')] = (+el.nr_nights_nat).toLocaleString('es-ES');
      tObj[this.translate.instant('overnight.paid')] =  (el.paid == "1"?'Si': "No");
      tObj[this.translate.instant('overnight.payroll')] = el.payroll;
      return tObj;
    });

    data = data.filter(el => {
      return el[this.translate.instant('overnight.paid')] == paid;
    })

    let fields = this.downloadColumns;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

}
