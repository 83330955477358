<div class="main-section">
    <h1>{{ 'vehicles.title_add' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <input type="text" matInput [(ngModel)]="mat" placeholder="{{ 'vehicles.mat' | translate }}"
                    name="mat" required>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="model" placeholder="{{ 'vehicles.model' | translate }}"
                    name="model" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid">{{
                    'vehicles.send_button'
                    | translate }}</button>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>