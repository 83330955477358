import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { RoleService } from '../_services/role.service';
import { ManagerService } from '../_services/manager.service';

export interface RoleTable {
  id: number;
  name: string;
}

@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.scss']
})
export class RoleListComponent implements OnInit {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['id', 'name', 'actions'];
  dataSource = new MatTableDataSource<RoleTable>();

  constructor(
    private RoleService: RoleService,
    private managerService: ManagerService
  ) { }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit() {
    this.loadingFlag = true;
    this.RoleService.getRoles()
      .subscribe(res => {
        const ROLE_DATA: RoleTable[] = res['roles'];
        this.dataSource.data = ROLE_DATA;
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
    this.managerService.setVoidFilter();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
