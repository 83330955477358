<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div *ngIf="!loadingFlag" class="section-header">
        <h1>{{ 'invoices.title_view' | translate }}</h1>
        <button mat-stroked-button (click)="backClicked()">
            <mat-icon aria-hidden="false" aria-label="chevron_left">chevron_left</mat-icon>
            {{ 'invoices.back_button' | translate }}
        </button>
    </div>

    <div class="view-container" [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag">
        <div>
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                <mat-card fxFlex="50%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.user' | translate }}"
                                name="user" value="{{user}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.date' | translate }}"
                                name="date" value="{{date | date:'shortDate' }}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.project' | translate }}"
                                name="project" value="{{project}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.task' | translate }}"
                                name="task" value="{{task}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.description' | translate }}"
                                name="description" value="{{description}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel
                                placeholder="{{ 'invoices.amount_with_tax' | translate }}" name="amountWithTax"
                                value="{{amountWithTax | currency:'EUR'}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.tax' | translate }}"
                                name="tax" value="{{tax | currency:'EUR'}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.amount_no_tax' | translate }}"
                                name="amountNotax" value="{{amountNotax | currency:'EUR'}}" readonly>
                        </mat-form-field>

                        
                    </div>
                    <div fxLayout="column">
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.invoice_type' | translate }}"
                                name="invoice_type" value="{{type_name}}" readonly>
                        </mat-form-field>
    
                        <mat-form-field>
                            <input type="text" matInput ngModel placeholder="{{ 'invoices.comments' | translate }}"
                                name="comments" value="{{comments}}" readonly>
                        </mat-form-field>
                    </div>

                    <br>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <div>
                            <button mat-raised-button color="primary" (click)="downloadFull()">
                                <mat-icon>cloud_download</mat-icon>
                                {{ "invoices.download_invoice" | translate }}
                            </button>
                        </div>
                        <div>
                            <button mat-raised-button color="accent" (click)='redirectToEditInvoice()' *ngIf="showEditButton()">
                                <mat-icon>edit</mat-icon>
                                {{ "invoices.title_edit" | translate }}
                            </button>
                        </div>
                    </div>
                </mat-card>

                <mat-card fxFlex="50%" class="image-card" *ngIf="!noImage">
                    <img class="invoice-image" [src]="image" *ngIf="!isPDF">
                    <!-- pdf component -->
                    <ngx-extended-pdf-viewer class="overflow-pdf"
                        [src]="pdf"
                        [showToolbar]="true"
                        [showSidebarButton]="false"
                        [showFindButton]="false"
                        [showPagingButtons]="false"
                        [showZoomButtons]="true"
                        [showPresentationModeButton]="false"
                        [showOpenFileButton]="false"
                        [showPrintButton]="true"
                        [showDownloadButton]="true"
                        [showBookmarkButton]="false"
                        [showSecondaryToolbarButton]="false"
                        [showRotateButton]="false"
                        [showHandToolButton]="false"
                        [showScrollingButton]="false"
                        [showSpreadButton]="false"
                        [showPropertiesButton]="false"
                        [useBrowserLocale]="true" style="display: block; height: 600px;" zoom="100%"
                        *ngIf="isPDF">
                    </ngx-extended-pdf-viewer>
                </mat-card>

                <mat-card fxFlex="50%" class="no-image-card" *ngIf="noImage">
                    <span>{{ 'invoices.no_image' | translate }}</span>
                </mat-card>
            </div>
        </div>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>