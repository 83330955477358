import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface LanguageTable {
  id: number;
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private API_URL = environment.API_URL

  constructor(
    private http: HttpClient
  ) { }

  getLanguages(): Observable<LanguageTable[]> {
    return this.http.get<LanguageTable[]>(`${this.API_URL}/languages`);
  }

  getLanguageById(id: string) {
    return this.http.get(`${this.API_URL}/languages/`+ id);
  }

  addLanguage(event: any) {
    let name = event.name;
    let code = event.code;

    return this.http.post<any>(`${this.API_URL}/languages`, {
      name,
      code
    });
  }

  updateLanguage(id: string, event: any) {
    let name = event.name;
    let code = event.code;

    return this.http.put<any>(`${this.API_URL}/languages/` + id, {
      name,
      code
    });
  }

  languageDelete(id: string) {
    return this.http.delete<any>(`${this.API_URL}/languages/`+ id);
  }

}
