<app-nav></app-nav>

<div class="main-section">
    <h1>{{ 'terms.heading_1' | translate }}</h1>
    <p>{{ 'terms.paragraph_1' | translate }} (<a target="_blank"
            href="http://www.agpd.es/portalwebAGPD/temas/reglamento/index-ides-idphp.php?utm_source=Newsletter+Freepress+S.+Coop.+Mad.&amp;utm_campaign=695e52d5d1-EMAIL_CAMPAIGN_2018_05_22&amp;utm_medium=email&amp;utm_term=0_e12783e13a-695e52d5d1-1227634069">RGPD</a>)
    </p>

    <h1>{{ 'terms.heading_2' | translate }}</h1>
    <p>{{ 'terms.paragraph_2' | translate }}</p>

    <h1>{{ 'terms.heading_3' | translate }}</h1>
    <p>{{ 'terms.paragraph_3' | translate }}</p>
    <p>{{ 'terms.paragraph_4' | translate }}</p>
    <p>{{ 'terms.paragraph_5' | translate }}</p>

    <h1>{{ 'terms.heading_4' | translate }}</h1>
    <p>{{ 'terms.paragraph_6' | translate }}</p>
    <p>{{ 'terms.paragraph_7' | translate }}</p>
    <p>{{ 'terms.paragraph_8' | translate }}</p>
    <p>{{ 'terms.paragraph_9' | translate }}</p>
    <p>{{ 'terms.paragraph_10' | translate }}</p>
    <p>{{ 'terms.paragraph_11' | translate }}</p>

    <h1>{{ 'terms.heading_5' | translate }}</h1>
    <p>{{ 'terms.paragraph_12' | translate }}</p>
    <p>{{ 'terms.paragraph_13' | translate }}</p>

    <h1>{{ 'terms.heading_6' | translate }}</h1>
    <p>{{ 'terms.paragraph_14' | translate }}</p>

    <h1>{{ 'terms.heading_7' | translate }}</h1>
    <p>{{ 'terms.paragraph_15' | translate }}</p>

    <h1>{{ 'terms.heading_8' | translate }}</h1>
    <p>{{ 'terms.paragraph_16' | translate }}</p>
    <p>{{ 'terms.paragraph_17' | translate }}</p>

    <h1>{{ 'terms.heading_9' | translate }}</h1>
    <p>{{ 'terms.paragraph_18' | translate }}</p>
    <p>{{ 'terms.paragraph_19' | translate }}</p>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>