<app-nav></app-nav>

<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag">
        <h1>{{ 'password.new_password_title' | translate }}</h1>
        <div class="mat-elevation-z8 password-block" *ngIf="!error">
            <div class="section">
                <form [formGroup]="newPasswordForm" (ngSubmit)="onSubmit()">
                    <div fxLayout="column" fxLayoutGap="5px">
                        <mat-form-field>
                            <input matInput type="password" placeholder="{{ 'password.password' | translate }}"
                                formControlName="password" class="form-control" />
                            <mat-error *ngIf="f.password.errors?.minlength">
                                {{ 'password.password_long' | translate }}
                            </mat-error>
                            <mat-error *ngIf="f.password.errors?.required">
                                {{ 'password.field_required' | translate }}
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field>
                            <input matInput type="password" placeholder="{{ 'password.confirmPassword' | translate }}"
                                formControlName="confirmPassword" class="form-control" />
                            <mat-error *ngIf="f.confirmPassword.errors?.mustMatch">
                                {{ 'password.password_match' | translate }}
                            </mat-error>
                            <mat-error *ngIf="f.confirmPassword.errors?.required">
                                {{ 'password.field_required' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button type="submit"
                            color="accent">{{ 'password.save' | translate }}</button>
                    </div>
                </form>
            </div>
        </div>

        <div class="mat-elevation-z8 " *ngIf="error">
            <div class="section">
                <p class="paragraph">
                    <mat-icon aria-hidden="false" color="warn">error</mat-icon>
                    {{ 'password.new_password_error' | translate }}
                </p>

                <div fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button color="accent" class="button-close"
                        routerLink="/">{{ 'password.home' | translate }}</button>
                </div>
            </div>
        </div>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>