import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { UserService } from '../_services/user.service';
import { ManagerService } from '../_services/manager.service';

export interface UserTable {
  id: number;
  username: string;
  email: string;
  role: number;
  headquarter: any;
}

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})

export class UserListComponent implements OnInit {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['id', 'headquarter', 'username', 'email', 'role', 'actions'];
  dataSource = new MatTableDataSource<UserTable>();

  constructor(
    private userService: UserService,
    private managerService: ManagerService
  ) { }


  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit() {
    this.loadingFlag = true;
    this.userService.getUsers()
      .subscribe(res => {
        const USER_DATA: UserTable[] = res['users'];
        this.dataSource.data = USER_DATA;
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });

    this.managerService.setVoidFilter();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
