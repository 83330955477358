import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { VehicleListComponent } from '../vehicles/vehicle-list/vehicle-list.component';

export interface VehicleTable {
    id: number;
    mat: string;
    model: string;
}

@Injectable({
  providedIn: 'root'
})
export class VehicleService {
  private API_URL = environment.API_URL;
  public vehicleList: VehicleListComponent;

  constructor(
    private http: HttpClient
  ) { }

  getVehicles(): Observable<VehicleTable[]> {
    return this.http.get<VehicleTable[]>(`${this.API_URL}/vehicles`);
  }

  getVehicleById(id: string) {
    return this.http.get(`${this.API_URL}/vehicles/` + id);
  }

  addVehicle(event: any) {
    return this.http.post<any>(`${this.API_URL}/vehicles`, {
      event
    });
  }

  updateVehicle(id: string, event: any) {
    let mat = event.mat;
    let model = event.model;
    const up = event.up;

    return this.http.put<any>(`${this.API_URL}/vehicles/` + id, {
      mat,
      model
    });
  }

  deleteVehicle(id: string) {
    return this.http.delete<any>(`${this.API_URL}/vehicles/` + id);
  }

  setVehicleList(vehicleList: VehicleListComponent) {
    this.vehicleList = vehicleList;
  }

  getVehicleList() {
    return this.vehicleList;
  }

}


