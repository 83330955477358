import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { User } from '../_models/user';
import { SidenavService } from '../_services/sidenav.service';
import { AuthenticationService } from '../_services/authentication.service';
import { media } from '../_helpers/responsive';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;

  showSideNav: boolean;
  currentUser: User;
  role: number;
  mode: string;

  constructor(
    private auth: AuthenticationService,
    private router: Router,
    private sidenavService: SidenavService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadUser();
      }

      if (!this.auth.currentUserValue) {
        this.showSideNav = false;
      } else {
        this.showSideNav = true;
      }
    });
  }

  ngOnInit() {
    this.sidenavService.setSidenav(this.sidenav);
    media('(max-width: 900px)').subscribe(matches => {
      if(matches) {
        this.mode = 'over';
      } else {
        this.mode = 'side';
      }
    });
  }

  loadUser() {
    this.auth.currentUser.subscribe(x => this.currentUser = x);

    if (this.currentUser) {
      this.role = this.currentUser.role;
    }
  }

}
