<div class="main-section">
    <h1 [hidden]="loadingFlag">{{ 'invoices.title_list' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag" class="mat-elevation-z8 filter-box">
        <h3>{{ 'invoices.filter_invoices' | translate }}</h3>
        <form [formGroup]="searchForm" class="search-form" *ngIf="searchForm">
            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.project' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'invoices.project' | translate }}" matInput formControlName="project"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.user' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'invoices.user' | translate }}" matInput formControlName="user"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.date_from' | translate }}</mat-label>
                <input matInput formControlName="dateFrom" [matDatepicker]="pickerFrom" (dateInput)="applyFilter()">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.date_to' | translate }}</mat-label>
                <input matInput formControlName="dateTo" [matDatepicker]="pickerTo" (dateInput)="applyFilter()">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.task' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'invoices.task' | translate }}" matInput formControlName="task"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.amount_from' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'invoices.amount_from' | translate }}" matInput
                    formControlName="amountFrom" (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline">
                <mat-label>{{ 'invoices.amount_to' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'invoices.amount_to' | translate }}" matInput
                    formControlName="amountTo" (keyup)="applyFilter()">
            </mat-form-field>
        </form>
    </div>

    <div [hidden]="loadingFlag">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'invoices.project' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.project}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell">{{ 'invoices.total' | translate }}</td>
            </ng-container>

            <ng-container matColumnDef="task">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'invoices.task' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.task}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'invoices.user' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.user}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="invoice_date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'invoices.invoice_date' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.invoice_date | date:'shortDate'}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="amount_with_tax">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'invoices.amount_with_tax' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.amount_with_tax | currency:'EUR'}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalAmount | currency:'EUR'}}</td>
            </ng-container>

            <ng-container matColumnDef="amount_no_tax">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'invoices.amount_no_tax' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.amount_no_tax | currency:'EUR'}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalAmountWithoutTax | currency:'EUR'}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'invoices.description' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.description}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="multibutton">
                    <button mat-icon-button color="accent" routerLink="/invoice-view/{{element.id}}"
                        matTooltip="{{ 'invoices.view_invoice' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>visibility</mat-icon>
                    </button>

                    <button mat-icon-button color="accent" routerLink="/invoice-edit/{{element.id}}"
                        *ngIf="element.can_edit == 'true' || element.is_admin == 'true'"
                        matTooltip="{{ 'invoices.edit_invoice' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <div *ngIf="!loadingFlag" class="download-row" [class.force-margin-bottom]="!loadingFlag">
        <button mat-raised-button color="accent" (click)="downloadCSV()">
            {{ 'invoices.download_csv' | translate }}
        </button>

        <button mat-raised-button color="accent" (click)="downloadFull()">
            {{ 'invoices.download_full' | translate }}
        </button>
    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>