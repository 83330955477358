import { Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationStart, Router } from '@angular/router';
import { AuthenticationService } from './_services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'FacTool';

  constructor(
    private translate: TranslateService,
    private router: Router,
    private authService: AuthenticationService
  ) {
    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.useLanguage();
        }
      });
  }
  ngOnDestroy(): void {
    localStorage.removeItem('currentUser');
  }

  useLanguage() {
    if (localStorage.getItem('currentUser')) {
      const lg = JSON.parse(localStorage.getItem('currentUser')).lg;
      this.translate.use(lg);
    } else {
      this.translate.use('es');
    }
  }

}
