<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="section-header" *ngIf="!loadingFlag">
        <h1>{{ 'cartrip.add.title' | translate }}</h1>
    </div>

    <div class="form-container" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" fxLayout.lt-lg="column">
                <mat-card fxFlex="100%">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" >
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='user' placeholder="{{ 'cartrip.user' | translate }}"
                                name="user" readonly required>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="date" matInput [(ngModel)]="cartrip_date" placeholder="{{ 'cartrip.add.date' | translate }}"
                                name="date" required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto" 
                                   name="project" #projectField="ngModel" 
                                   placeholder="{{ 'cartrip.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'cartrip.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>
                        
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'cartrip.task' | translate }}"
                                name="task"
                                [(ngModel)]="task"
                                [disabled]="!tasks || tasks.length === 0"
                            >
                                <mat-option *ngFor="let task of tasks" [value]="task.id"> 
                                    {{ task.name }}
                                </mat-option>
                            </mat-select>
                            <mat-spinner *ngIf="loadingTasksFlag" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="description"
                                placeholder="{{ 'cartrip.description' | translate }}" name="description"
                                value="{{description}}">
                        </mat-form-field>
                    </div>

                    <!-- Empty row to give some space between components -->
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" class="spacing">
                    </div>

                    <!-- Place selection -->
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <mat-select placeholder="{{ 'cartrip.add.places' | translate }}" name="place_id" [(ngModel)]="place_id" required>
                                <mat-option *ngFor="let place of places" [value]="place.id">
                                    {{ 'cartrip.places.' + place.name | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- To choose between one calculation method or another -->
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-radio-group [formControl]="radioGroupForm" class="radio-group" [(ngModel)]="kmCalcMethod" (ngModelChange)="onChangeKmCalcMethod()">
                            <mat-radio-button [value]="KmCalcMethod.TOTAL">{{ 'cartrip.add.km_calc_method.total' | translate }}</mat-radio-button>
                            <mat-radio-button [value]="KmCalcMethod.START_END">{{ 'cartrip.add.km_calc_method.start_end' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <!-- In case KmCalcMethod.TOTAL -->
                    <div *ngIf="kmCalcMethod === KmCalcMethod.TOTAL" fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="kmTotal"
                                placeholder="{{ 'cartrip.add.km_total' | translate }}" [name]="place_id === '1' ? 'nr_km_can' : 'nr_km_pe'"
                                value="{{kmTotal}}" required>
                        </mat-form-field>
                    </div>

                    <!-- In case KmCalcMethod.START_END -->
                    <div *ngIf="kmCalcMethod === KmCalcMethod.START_END" fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="kmStart" (ngModelChange)="calculateKmTotal()"
                                placeholder="{{ 'cartrip.add.km_start' | translate }}" name="nr_km_initial"
                                value="{{kmStart}}" required>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="kmEnd" (ngModelChange)="calculateKmTotal()"
                                placeholder="{{ 'cartrip.add.km_end' | translate }}" name="nr_km_final"
                                value="{{kmEnd}}" required>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="number" matInput [ngModel]="kmTotal"
                                placeholder="{{ 'cartrip.add.km_total' | translate }}" [name]="place_id === '1' ? 'nr_km_can' : 'nr_km_pe'"
                                value="{{kmTotal}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-raised-button class="top-buffer bottom-buffer" type="submit" color="accent"
                            [disabled]="!f.valid || !validateProject">{{ 'cartrip.add.save_button' | translate }}</button>
                    </div>
                </mat-card>
                
            </div>
        </form>
    </div>

    <div class="section-footer" id="flex-end" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag" >
    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
