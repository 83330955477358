import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ExpendService {

  private API_URL = environment.API_URL;
  isManagerDashboard: boolean = false;

  constructor(
    private http: HttpClient
  ) { }

  paidCostById(id, type, time, paid_mode) {
    return this.http.post(`${this.API_URL}/expends/paidCostById/` + id, { type, time, paid_mode });
  }

  unpaidCostById(id, type) {
    return this.http.post(`${this.API_URL}/expends/unpaidCostById/` + id, { type });
  }

  getIsManagerDashboard() {
    return this.isManagerDashboard;
  }

  setIsManagerDashboard(isManagerDashboard: boolean) {
    this.isManagerDashboard = isManagerDashboard;
  }

  editVelazquezById(velazquez_input, id, type) {
    return this.http.put(`${this.API_URL}/expends/` + id, { velazquez_input, type });
  }

  editProviderById(provider, id, type) {
    return this.http.post(`${this.API_URL}/expends/setProvider/` + id, { provider, type });
  }
}
