import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './_helpers/auth.guard';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { RoleListComponent } from './role-list/role-list.component';
import { RoleAddComponent } from './role-add/role-add.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import { ProfileComponent } from './profile/profile.component';
import { LanguageListComponent } from './language-list/language-list.component';
import { LanguageAddComponent } from './language-add/language-add.component';
import { LanguageEditComponent } from './language-edit/language-edit.component';
import { Role } from './_models/role';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { RecoverPasswordComponent } from './password/recover-password/recover-password.component';
import { NewPasswordComponent } from './password/new-password/new-password.component';
import { ProjectListComponent } from './projects/project-list/project-list.component';
import { ProjectAddComponent } from './projects/project-add/project-add.component';
import { ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { ProjectViewComponent } from './projects/project-view/project-view.component';
import { TaskListComponent } from './tasks/task-list/task-list.component';
import { InvoiceViewComponent } from './invoices/invoice-view/invoice-view.component';
import { InvoiceEditComponent } from './invoices/invoice-edit/invoice-edit.component';
import { ManagerListComponent } from './manager-list/manager-list.component';
import { OvernightViewComponent } from './overnight-view/overnight-view.component';
import { CartripViewComponent } from './cartrip-view/cartrip-view.component';
import { OvernightListComponent } from './overnight-list/overnight-list.component';
import { ConfigComponent } from './config/config.component';
import { ExpendAddComponent } from './expends/expend-add/expend-add.component';
import { InvoiceAddComponent } from './invoices/invoice-add/invoice-add.component';
import { ManagerDashboardComponent } from './manager-dashboard/manager-dashboard.component';
import { VehicleListComponent } from './vehicles/vehicle-list/vehicle-list.component';
import { VehicleEditComponent } from './vehicles/vehicle-edit/vehicle-edit.component';
import { VehicleAddComponent } from './vehicles/vehicle-add/vehicle-add.component';
import { AgrestatripListComponent } from './agrestatrips/agrestatrip-list/agrestatrip-list.component';
import { AgrestatripEditComponent } from './agrestatrips/agrestatrip-edit/agrestatrip-edit.component';
import { OvernightAddComponent } from './overnight-add/overnight-add.component';
import { CartripAddComponent } from './cartrip-add/cartrip-add.component';
import { AgrestatripAddComponent } from './agrestatrips/agrestatrip-add/agrestatrip-add.component';

const routes: Routes = [
  // Parent routing does not have sidenav and headbar
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'terms-conditions', component: TermsConditionsComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: 'new-password/:hash', component: NewPasswordComponent },
  {
    path: '', component: SidenavComponent, children: [
      { path: 'expend-add', component: ExpendAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal, Role.Manager] } },
      { path: 'invoice-add', component: InvoiceAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal, Role.Manager] } },
      { path: 'user-list', component: UserListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'user-edit/:id', component: UserEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'manager-list', component: ManagerListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal, Role.Manager] } },
      { path: 'manager-dashboard', component: ManagerDashboardComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Manager] } },
      { path: 'invoice-view/:id', component: InvoiceViewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'invoice-edit/:id', component: InvoiceEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'overnight-list', component: OvernightListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'overnight-add', component: OvernightAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal, Role.Manager] } },
      { path: 'overnight-view/:id', component: OvernightViewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'cartrip-view/:id', component: CartripViewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'cartrip-add', component: CartripAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'config', component: ConfigComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Manager]} },
      { path: 'project-list', component: ProjectListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'project-view', component: ProjectViewComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'project-add', component: ProjectAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'project-edit/:id', component: ProjectEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'task-list/:id', component: TaskListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'role-list', component: RoleListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'role-add', component: RoleAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'role-edit/:id', component: RoleEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'language-list', component: LanguageListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'language-add', component: LanguageAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'language-edit/:id', component: LanguageEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
      { path: 'vehicle-list', component: VehicleListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin,Role.Manager] } },
      { path: 'vehicle-edit/:id', component: VehicleEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin,Role.Manager] } },
      { path: 'vehicle-add', component: VehicleAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'agrestatrip-list', component: AgrestatripListComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'agrestatrip-add', component: AgrestatripAddComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
      { path: 'agrestatrip-edit/:id', component: AgrestatripEditComponent, canActivate: [AuthGuard], data: { roles: [Role.Admin, Role.Normal,Role.Manager] } },
    ]
  },
  // Otherwise redirect to home
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }