import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ManagerService } from 'src/app/_services/manager.service';
import { ProjectService } from 'src/app/_services/project.service';
import { UpService } from 'src/app/_services/up.service';
import { CloseProjectDialog, FundProjectDialog, OpenProjectDialog, ProjectListComponent, UnfundProjectDialog } from '../project-list/project-list.component';

@Component({
  selector: 'delete-project-dialog',
  templateUrl: 'delete-project-dialog.html',
  styleUrls: ['./project-edit.component.scss']
})
export class DeleteProjectDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteProjectDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss']
})
export class ProjectEditComponent implements OnInit {
  protected id: string;
  code: string;
  name: string;
  previousCode: string;
  previousName: string;
  loadingFlag: boolean = false;
  allowDelete: boolean = false;
  closed: any;
  projectList: ProjectListComponent;
  up: any;
  ups: any[] = [];
  private upsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  fund: any;

  constructor(
    private activedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private toastr: ToastrService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private router: Router,
    private managerService: ManagerService,
    private upService: UpService,
    private ProjectService: ProjectService
  ) { }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.allowDelete = false;
    this.projectList = this.projectService.getProjectList();
    const routeParams = this.activedRoute.snapshot.params;
    this.id = routeParams.id;
    this.upService.getUps()
      .subscribe(res => {
        this.ups = res['ups'].map(element => {
          if(element.name == 'no_defined'){
            element.name = this.translate.instant('projects.no_defined_up');
          }
          return element;

        });

        this.upsEvent.next(true);
      });
    this.upsEvent.asObservable()
      .subscribe( res => {
        if(res){
          this.getProjectById(routeParams.id);
        }
      });

    this.managerService.setVoidFilter();
  }

  getProjectById(id: string) {
    this.projectService.getProjectById(id)
      .subscribe(res => {
        this.code = res['code'];
        this.name = res['name'];
        this.up = res['up'];
        this.closed = res['closed'];
        this.fund = res['fund'];
        this.previousCode = this.code;
        this.previousName = this.name;
        if ((+res['invoices'] + +res['cartrip'] + +res['overnights']) == 0) {
          this.allowDelete = true;
        } else {
          this.allowDelete = false;
        }
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.projectService.updateProject(this.id, event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('projects.success_project_edit'), '', { timeOut: 3000 });
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('projects.error_project_exist'), '', { timeOut: 3000 });
            this.code = this.previousCode;
            this.name = this.previousName;
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('projects.error_project_edit'), '', { timeOut: 3000 });
            this.code = this.previousCode;
            this.name = this.previousName;
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  deleteProjectDialog() {
    const dialogRef = this.dialog.open(DeleteProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteProject(this.id);
      }
    });
  }

  deleteProject(projectId: string) {
    this.loadingFlag = true;

    this.projectService.deleteProject(projectId)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('projects.success_project_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.router.navigate(['/project-list']);
                }
              );
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('projects.error_project_invoices'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('projects.error_project_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  closeProjectDialog() {
    const dialogRef = this.dialog.open(CloseProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.closeProjectById(this.id);
      }
    });
  }

  closeProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.closeProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_closed'), '', {timeOut: 3000});
          this.router.navigate(['/project-list'])
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_closed'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_closed'), '', {timeOut: 3000})
      });
  }

  openProjectDialog() {
    const dialogRef = this.dialog.open(OpenProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openProjectById(this.id);
      }
    });
  }

  openProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.openProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_open'), '', {timeOut: 3000});
          this.router.navigate(['/project-list']);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_open'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_open'), '', {timeOut: 3000})
      });
  }

  unfundProjectDialog() {
    const dialogRef = this.dialog.open(UnfundProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unfundProjectById(this.id);
      }
    });
  }

  unfundProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.unfundProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_unfund'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_unfund'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_unfund'), '', {timeOut: 3000})
      });
  }

  fundProjectDialog() {
    const dialogRef = this.dialog.open(FundProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fundProjectById(this.id);
      }
    });
  }

  fundProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.fundProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_fund'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_fund'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_fund'), '', {timeOut: 3000})
      });
  }

}
