<div class="main-section">
    <h1>{{ 'roles.title_edit' | translate }}: {{name}}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <p>{{ 'roles.note_edit' | translate }}</p>
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <input type="text" matInput [(ngModel)]="name" placeholder="{{ 'roles.name' | translate }}"
                    value="{{name}}" name="name" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="accent"
                    [disabled]="!f.valid">{{ 'roles.send_button' | translate }}</button>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>