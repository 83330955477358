import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { VehicleService } from "src/app/_services/vehicle.service";

@Component({
    selector: 'app-vehicle-add',
    templateUrl: './vehicle-add.component.html',
    styleUrls: ['./vehicle-add.component.scss']
})
export class VehicleAddComponent {
    mat: string;
    model: string;
    loadingFlag: boolean = false;

    constructor(
        private vehicleService: VehicleService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router
    ) {}

    onSubmit(event: any) {
        this.loadingFlag = true;
    
        this.vehicleService.addVehicle(event)
          .subscribe(
            (res: any[]) => {
              if (res && res['status'] == 1) {
                this.toastr.success(this.translate.instant('vehicles.success_vehicle_add'), '', { timeOut: 3000 })
                  .onHidden
                  .subscribe(
                    () => this.router.navigate(['/vehicle-list'])
                  );
              } else if (res && res['status'] == 2) {
                this.toastr.error(this.translate.instant('vehicles.error_vehicle_exists'), '', { timeOut: 3000 });
                this.loadingFlag = false;
              } else {
                this.toastr.error(this.translate.instant('vehicles.error_vehicle_add'), '', { timeOut: 3000 });
                this.loadingFlag = false;
              }
            },
            error => {
              this.toastr.error(String(error), '', { timeOut: 3000 });
              this.loadingFlag = false;
            }
          );
        
      }
}