<div class="main-section">
    <h1 [hidden]="loadingFlag">{{ 'projects.title_edit' | translate }}: {{code}} - {{name}}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">

            <mat-form-field>
                <mat-label>{{'projects.up' | translate}} *</mat-label>
                <mat-select [(ngModel)]="up" name="up">
                  <mat-option *ngFor="let up of ups" [value]="up.id">
                    {{up.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="code" placeholder="{{ 'projects.code' | translate }}"
                    name="code" required>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="name" placeholder="{{ 'projects.name' | translate }}"
                    value="{{name}}" name="name" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
              <button *ngIf="fund == 1" mat-raised-button type="button" class="button-margin" (click)="unfundProjectDialog()">
                  {{ 'projects.unfund_project' | translate }}
                  <mat-icon matListIcon>close</mat-icon>
              </button>

              <button mat-raised-button *ngIf="fund == 0" type="button" class="button-margin" (click)="fundProjectDialog()">
                  {{ 'projects.fund_project' | translate }}
                  <mat-icon matListIcon>lock_open</mat-icon>
              </button>

                <button *ngIf="closed == 0" mat-raised-button type="button" class="button-margin" (click)="closeProjectDialog()">
                    {{ 'projects.close_project' | translate }}
                    <mat-icon matListIcon>close</mat-icon>
                </button>

                <button mat-raised-button *ngIf="closed == 1" type="button" class="button-margin" (click)="openProjectDialog()">
                    {{ 'projects.open_project' | translate }}
                    <mat-icon matListIcon>lock_open</mat-icon>
                </button>
                <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid">{{ 'projects.send_button' |
                    translate }}</button>
                </div>
        </form>
    </div>

    <div class="delete-button-div" *ngIf="allowDelete">
        <button mat-raised-button color="warn" (click)="deleteProjectDialog()">{{ 'projects.delete_button' | translate
            }}</button>
    </div>

    <div class="delete-button-div" *ngIf="!allowDelete && !loadingFlag">
        <p class="not-allowed-delete">{{ 'projects.not_allowed_delete' | translate }}</p>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
