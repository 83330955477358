<app-nav></app-nav>

<div id="login-view" fxLayout fxLayoutAlign="center center">
  <div id="loading-spinner" [hidden]="!loadingFlag">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div [hidden]="loadingFlag">
    <mat-card class="login-card">
      <div fxLayout="row" fxLayoutAlign="center center">

        <div class='login-form'>
          <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <mat-form-field>
              <input type="text" autofocus matInput ngModel placeholder="{{ 'login.username' | translate }}" name="username"
                required>
            </mat-form-field>

            <mat-form-field>
              <input type="password" matInput ngModel placeholder="{{ 'login.password' | translate }}" name="password"
                required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="center center">
              <button mat-raised-button type="submit" color="primary" class="primary-button login-button"
                [disabled]="!f.valid">{{ 'login.login_button' | translate }}</button>
            </div>
          </form>

          <div class="top-buffer" *ngIf="error">
            <p class="error-p">{{ error_message }}</p>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
  <span class="version">{{'login.version' | translate}}</span>
  <img class="login-logo-agresta" src="assets/img/agresta-blanco.png" alt="Agresta">
</div>