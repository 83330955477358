import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { OvernightTable } from '../overnight-list/overnight-list.component';

@Injectable({
  providedIn: 'root'
})
export class OvernightService {

  API_URL: string = environment.API_URL;
  constructor(
    private http: HttpClient
  ) { }

  getOvernights(): Observable<OvernightTable[]> {
    return this.http.get<OvernightTable[]>(`${this.API_URL}/overnight`);
  }

  getAllOvernights() {
    return this.http.post<any>(`${this.API_URL}/overnight/alldata`, {});
  }

  getOvernightById(id) {
    return this.http.get<any>(`${this.API_URL}/overnight/${id}`);
  }

  updateOvernight(id, project_id, form) {
    return this.http.put<any>(`${this.API_URL}/overnight/${id}`,
      {
        project_id,
        form
      });
  }

  addOvernight(project_id: any, date: string, form) {
    const body = { project_id, date, overnight: form };

    console.log("requested", body);

    // How app sends overnight:
    // const httpOptions = {
    //     headers: new HttpHeaders({
    //       'Content-Type': 'application/json',
    //       Authorization: 'Authorization: Bearer ' + token
    //     })
    //   }
  
    //   overnight = JSON.stringify(overnight);
  
    //   return this.http.post<any>(`${this.API_URL}/overnightapp`, {
    //     overnight,
    //     project_id,
    //     date,
    //     date_end
    //   },
    //     httpOptions
    //   );

    return this.http.post<any>(`${this.API_URL}/overnight`, body);
  }

  deleteOvernight(id: string) {
    return this.http.delete<any>(`${this.API_URL}/overnight/` + id);
  }

  paidChecker(username: string, dateFrom, dateTo) {
    return this.http.post<any>(`${this.API_URL}/overnight/paid`, {username: username, dateFrom: dateFrom, dateTo: dateTo});
  }

  filterDate(dateFrom, dateTo) {
    return this.http.post<any>(`${this.API_URL}/overnight/filter`, {dateFrom, dateTo});
  }

}
