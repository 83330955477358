import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

export interface RoleTable {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private API_URL = environment.API_URL

  constructor(
    private http: HttpClient
  ) { }

  getRoles(): Observable<RoleTable[]> {
    return this.http.get<RoleTable[]>(`${this.API_URL}/roles`);
  }

  getRoleById(id: string) {
    return this.http.get(`${this.API_URL}/roles/`+ id);
  }

  addRole(event: any) {
    let name = event.name;

    return this.http.post<any>(`${this.API_URL}/roles`, {
      name
    });
  }

  updateRole(id: string, event: any) {
    let name = event.name;

    return this.http.put<any>(`${this.API_URL}/roles/` + id, {
      name
    });
  }

}
