import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource, MatTableModule } from "@angular/material/table";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import { VehicleService } from 'src/app/_services/vehicle.service';
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";
import { MatSort, MatSortModule } from "@angular/material/sort";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";

export interface VehicleTable {
    id: number;
    mat: string;
    model: string;
}

@Component({
    selector: 'app-vehicle-list',
    templateUrl: './vehicle-list.component.html',
    styleUrls: ['./vehicle-list.component.scss']
})
export class VehicleListComponent implements OnInit, OnDestroy {
    loadingFlag: boolean = false;
    displayedColumns: string[] = ['mat', 'model', 'actions'];
    dataSource = new MatTableDataSource<VehicleTable>();

    @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
        this.dataSource.paginator = paginator;
    }

    @ViewChild(MatSort) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    constructor(
        private vehicleService: VehicleService,
        private translate: TranslateService
    ) { }

    ngOnInit(): void {
        this.loadingFlag = true;
        this.vehicleService.getVehicles()
            .subscribe(res => {
                this.dataSource.data = res['vehicles'];
                this.loadingFlag = false;
            },
            () => {
                this.loadingFlag = false;
            });
    }

    ngOnDestroy(): void {
        this.vehicleService.setVehicleList(this);
    }

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
    }
}