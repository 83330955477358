<div class="main-section">
    <h1>{{ 'projects.title_add' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="form-container mat-elevation-z8" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <mat-label>{{'projects.up' | translate}} *</mat-label>
                <mat-select [(ngModel)]="up" name="up">
                  <mat-option *ngFor="let up of ups" [value]="up.id">
                    {{up.name}}
                  </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="code" placeholder="{{ 'projects.code' | translate }}"
                    name="code" required>
            </mat-form-field>

            <mat-form-field>
                <input type="text" matInput [(ngModel)]="name" placeholder="{{ 'projects.name' | translate }}"
                    name="name" required>
            </mat-form-field>

            <div fxLayout="row" fxLayoutAlign="end center">
                <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid">{{
                    'projects.send_button'
                    | translate }}</button>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>