import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from '../_services/language.service';
import { ManagerService } from '../_services/manager.service';

export interface LanguageTable {
  id: number;
  name: string;
  code: string;
}

@Component({
  selector: 'app-language-edit',
  templateUrl: './language-edit.component.html',
  styleUrls: ['./language-edit.component.scss']
})
export class LanguageEditComponent implements OnInit {
  protected id: string;
  name: string;
  code: string;
  previousName: string;
  previousCode: string;
  loadingFlag: boolean = false;
  delete: string;

  constructor(
    private activedRoute: ActivatedRoute,
    private languageService: LanguageService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.loadingFlag = true;
    const routeParams = this.activedRoute.snapshot.params;
    this.id = routeParams.id;
    this.getLanguageById(routeParams.id);
    this.managerService.setVoidFilter();
  }

  getLanguageById(id: string) {
    this.languageService.getLanguageById(id)
      .subscribe(res => {
        this.name = res['name'];
        this.previousName = this.name;
        this.code = res['code'];
        this.previousCode = this.code;

        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.languageService.updateLanguage(this.id, event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('languages.success_language_edit'), '', { timeOut: 3000 });
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('languages.error_language_exist'), '', { timeOut: 3000 });
            this.name = this.previousName;
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('languages.error_language_edit'), '', { timeOut: 3000 });
            this.name = this.previousName;
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  onSubmitDelete(event: any) {
    this.loadingFlag = true;

    this.languageService.languageDelete(this.id)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.loadingFlag = false;
            this.toastr.info(this.translate.instant('languages.success_language_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => this.router.navigate(['/language-list'])
              );
          } else {
            this.toastr.error(this.translate.instant('languages.error_language_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

}
