<app-nav></app-nav>

<div id="home-view" fxLayout fxLayoutAlign="center center">
  <mat-card class="home-card">
    <h1>{{ 'home.title' | translate }}</h1>
    <div fxLayout="row" fxLayoutAlign="center center">
      <a color="primary" class="primary-button home-button" mat-raised-button routerLink="/login">{{ 'home.login_button' | translate }}</a></div>
  </mat-card>
  <span class="home-version">v0.1</span>
  <img class="home-logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>