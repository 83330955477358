import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { Location } from '@angular/common';
import { AuthenticationService } from '../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { CartripService } from '../_services/cartrip.service';
import { Role } from '../_models/role';
import { ProjectService } from '../_services/project.service';
import { TasksService } from '../_services/tasks.service';
import { ConfigurationService } from '../_services/configuration.service';
import { NgForm } from '@angular/forms';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import { ExpendService } from '../_services/expend.service';
import { PaidManagerDialog, UnpaidManagerDialog } from '../manager-dashboard/manager-dashboard.component';
import { InvoicesService } from '../_services/invoices.service';
import * as JSZip from 'jszip';
import blobPdfFromBase64String from '../_helpers/blobPdfFromBase64String';
import * as moment from 'moment';

@Component({
  selector: 'delete-cartrip-dialog',
  templateUrl: 'delete-cartrip-dialog.html',
  styleUrls: ['./cartrip-view.component.scss']
})
export class DeleteCartripDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteCartripDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteCartripDialog,
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-cartrip-view',
  templateUrl: './cartrip-view.component.html',
  styleUrls: ['./cartrip-view.component.scss']
})
export class CartripViewComponent implements OnInit {
  protected cartrip_id: string;
  loadingFlag: boolean = false;

  projects: any[];
  username: string;
  role: any;
  defaultTiquet = 'Justificante';
  tiquets = ['Justificante'];
  currentUser: Observable<any>;
  isPDF: boolean = false;
  user: any;
  date: Date;
  project: any;
  task: string = '';
  tasks: any[] = [];
  description: any;
  nr_km_can: any;
  nr_km_pe: any;
  amount_no_tax: any;
  place: any;
  can_edit: any;
  is_admin: any;
  projectId: any;
  places: any;
  placeId: any;
  config: any;
  validateProject: boolean = true;
  projectArrayObs: Observable<any[]>;
  paid: any;
  system_date: any = new Date();
  disbledPaid: boolean;
  auxTime: any;
  time: any;
  auxPaidMode: any;
  timeValidate: boolean;
  paidModeValidate: boolean;
  paid_modes: any[] = ['Sin valor', 'Transferencia', 'Domiciliación', 'Efectivo'];
  paid_mode: any = 'Transferencia';
  paids: any[] = [
    { name: 'Sí', value: '1' },
    { name: 'No', value: '0' }
  ];
  changeSelectionproject: boolean = false;
  datestamp: any;
  fund: boolean;
  sourceImg: string;
  noImage: boolean;
  imageError: boolean;
  extensionError: boolean;
  auxSourceImg: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private location: Location,
    private router: Router,
    private authService: AuthenticationService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private cartripService: CartripService,
    private projectService: ProjectService,
    private taskService: TasksService,
    private configService: ConfigurationService,
    private expendService: ExpendService,
    private invoicesService: InvoicesService
  ) { }

  ngOnInit(): void {
    const routeParams = this.activatedRoute.snapshot.params;
    this.cartrip_id = routeParams.id;
    this.loadingFlag = true;
    this.configService.getConfig()
      .subscribe(res => {
        this.config = res;
      });

    this.cartripService.getPlaces().subscribe(res => {
      this.places = res.places.map(element => {
        element.id = +element.id;
        return element;
      })

    });
    this.currentUser = this.authService.currentUser;
    this.getProjects();
    this.getUserAndRole();
  }

  getUserAndRole() {
    this.currentUser.subscribe(data => {
      this.username = data.username;
      this.role = data.role;
    });
  }

  getProjects() {
    this.projectService.getProjects()
      .subscribe(res => {
        this.projects = res['projects'].map(el => {
          let tObj = {};
          tObj['id'] = el.id;
          tObj['name'] = el.code + ' - ' + el.name;
          return tObj;
        });

        this.getCartripById(this.cartrip_id);
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  onSelectionChange(event: any) {
    this.task = '';
    this.changeSelectionproject = true;
    let name = event.option.value;
    let tempProjId = this.projects.filter(el => el.name == name);
    this.projectId = tempProjId[0].id;
    this.getTasksByProjectId(this.projectId);
  }

  getTasksByProjectId(id: string) {
    this.loadingFlag = true;
    const object = JSON.stringify({
      id: id,
      offset: false
    });
    this.taskService.getTasksByProjectId(object)
      .subscribe(res => {
        this.tasks = res['tasks'];
        if (this.changeSelectionproject) {
          this.task = this.tasks[0].id;
        }
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  showEditManager() {
    return (this.role === Role.Admin || this.role === Role.Manager);
  }

  showEditInputs() {
    return (this.can_edit || this.is_admin || this.role === Role.Manager);
  }


  backClicked() {
    this.location.back();
  }

  getCartripById(id: string) {
    this.cartripService.getCartripById(id)
      .subscribe((res: any) => {
        this.user = res.user;
        this.date = new Date(res.date_end);
        this.datestamp = res.date_end;
        this.project = res.project;
        this.projectId = res.project_id;
        this.task = res.task_id;
        this.description = res.description;
        this.nr_km_can = res.nr_km_can;
        this.nr_km_pe = res.nr_km_pe;
        this.amount_no_tax = res.amount_no_tax;
        this.placeId = res.place == 'Canary_Islands' ? 1 : 2;
        this.place = res.place;
        this.paid = res.paid;
        this.loadingFlag = false;
        this.auxSourceImg = res['just_url'] + '?' + this.generateRandom();
        this.can_edit = res.can_edit == 'true' ? true : false;
        this.is_admin = res.is_admin == 'true' ? true : false;
        this.auxTime = res['time'] ? new Date(res['time']) : '';
        this.auxPaidMode = res['paid_mode'] ? res['paid_mode'] : 'Transferencia';
        this.fund = res['fund'] == '1' ? true : false;
        this.paidChange();
        this.getTasksByProjectId(this.projectId);
        if (this.auxSourceImg && this.auxSourceImg.indexOf('.pdf') !== -1) {
          this.isPDF = true;
        }

        if (this.auxSourceImg) {
          this.noImage = false;
        } else {
          this.noImage = true;
        }

        setTimeout(() => {
          this.sourceImg = this.auxSourceImg;
        }, 1500);
      },
        (err) => {
          this.toastr.error(err, '', { timeOut: 3000 });
          this.loadingFlag = false;
        });
  }

  deleteCartripDialog() {
    const dialogRef = this.dialog.open(DeleteCartripDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteCartrip(this.cartrip_id);
      }
    });
  }

  deleteCartrip(overnight_id: string) {
    this.loadingFlag = true;

    this.cartripService.deleteCartrip(this.cartrip_id)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('cartrip.success_invoice_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  if (!this.expendService.getIsManagerDashboard())
                    this.router.navigate(['manager-list']);
                  else
                    this.router.navigate(['manager-dashboard']);
                }
              );
          } else {
            this.toastr.error(this.translate.instant('cartrip.error_invoice_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  nightsChange() {

    this.amount_no_tax = this.nr_km_can * this.config.price_can_km + this.nr_km_pe * this.config.price_pe_km;
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    if (!form.value.amount_no_tax)
      form.value.amount_no_tax = this.amount_no_tax;

    form.value.date = moment(this.date).format('Y-MM-DD');
    if (this.time) {
      const auxTime = new Date(this.time);
      form.value.time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T', ' ').replace('Z', '');
    }

    if (this.paid_mode == 'Sin valor') {
      form.value.paid_mode = null;
    }

    this.cartripService.updateCartripValues(this.cartrip_id, this.projectId, form.value)
      .subscribe((res: any) => {
        if (res && res['status'] == 1) {
          this.toastr.success(this.translate.instant('cartrip.success_invoice_edit'), '', { timeOut: 3000 });
          if (!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
        } else {
          this.toastr.error(this.translate.instant('cartrip.error_invoice_edit'), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  doFilter() {
    this.projectArrayObs = of(this.projects);
    this.projectArrayObs = this.projectArrayObs
      .pipe(
        map(res => this.filter(res))
      )

    this.onBlurProject();

  }

  filter(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name.toLowerCase().includes(this.project.toLowerCase()))

  }

  filterValidate(projects) {
    return projects.filter(project =>
      // used 'includes' here for demo, you'd want to probably use 'indexOf'
      project.name == this.project
    );
  }

  onBlurProject() {
    const project = this.filterValidate(this.projects);
    if (project.length == 1) {
      this.getTasksByProjectId(project[0].id);
      this.projectId = project[0].id;
      this.validateProject = true;
    } else {

      this.validateProject = false;
    }

  }

  paidInvoiceDialog() {
    const dialogRef = this.dialog.open(PaidManagerDialog, {
      width: '500px',
      data: {
        system_date: this.system_date,
        paid_modes: this.paid_modes,
        paid_mode: this.paid_mode,
        number: 1
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paid_mode = result.paid_mode;
        const auxTime = new Date(result.system_date);
        const time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T', ' ').replace('Z', '');
        this.paidInvoiceById(this.cartrip_id, 'cartrip', time);

      }
    });
  }

  paidInvoiceById(id, type, time) {
    this.loadingFlag = true;
    this.expendService.paidCostById(id, type, time, this.paid_mode)
      .subscribe(res => {
        if (res['status']) {
          this.toastr.success(this.translate.instant('manager.success_paid'), '', { timeOut: 3000 });
          if (!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectCloseEvent.next(true);
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_paid'), '', { timeOut: 3000 });
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_paid'), '', { timeOut: 3000 })
      });
  }

  unpaidInvoiceDialog() {
    const dialogRef = this.dialog.open(UnpaidManagerDialog, {
      width: '500px',
      data: { number: 1 }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unpaidInvoiceById(this.cartrip_id, 'cartrip');
      }
    });
  }

  unpaidInvoiceById(id, type) {
    this.loadingFlag = true;
    this.expendService.unpaidCostById(id, type)
      .subscribe(res => {
        if (res['status']) {
          this.toastr.success(this.translate.instant('manager.success_unpaid'), '', { timeOut: 3000 });
          if (!this.expendService.getIsManagerDashboard())
            this.router.navigate(['manager-list']);
          else
            this.router.navigate(['manager-dashboard']);
          // this.expendService.projectOpenEvent.next(true);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('manager.error_unpaid'), '', { timeOut: 3000 });
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('manager.error_unpaid'), '', { timeOut: 3000 })
      });
  }

  paidChange() {

    if (this.paid == '0') {
      this.disbledPaid = true;
      this.auxTime = this.time;
      this.auxPaidMode = this.paid_mode;
      this.time = '';
      this.paid_mode = this.paid_modes[0];
    } else {
      this.time = this.auxTime ? this.auxTime : new Date();
      this.paid_mode = this.auxPaidMode ? this.auxPaidMode : '';
      this.disbledPaid = false;
    }
    this.validatePaid();
  }

  validatePaid() {
    if (this.paid == '1') {
      this.timeValidate = this.time;
      this.paidModeValidate = !(this.paid_mode === 'Sin valor');

    } else {
      this.timeValidate = true;
      this.paidModeValidate = true;
    }
  }

  private generateRandom() {
    return Math.floor(Math.random() * 100000);
  }

  imageChanged(event: any) {
    let fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    let fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {
      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.saveJustification(dataUrl, this.project, new Date().toISOString(), '', this.cartrip_id).
          subscribe(res => {
            if (res['status'] == 1) {
              this.imageError = false;
              this.isPDF = false;
              this.sourceImg = dataUrl;
              this.toastr.success(this.translate.instant('invoices.success_justification_upload'), '', { timeOut: 3000 });
            } else {
              this.imageError = true;
            }
          }, () => {
            this.imageError = true;
          });
      }
      fr.readAsDataURL(fileContent);
    } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
      this.extensionError = true;
    }
  }

  loadPDF(event) {
    const fileContent = (event.target as HTMLInputElement).files[0];
    if (!fileContent) {
      this.imageError = true;
      return;
    }

    const fileName = event.target.files[0].name;
    let fileExtension = fileName.split('.').pop();

    fileExtension = fileExtension.toLowerCase();

    if (fileExtension == 'pdf') {
      this.imageError = false;
      this.extensionError = false;

      const fr = new FileReader();
      fr.readAsDataURL(fileContent);
      fr.onload = () => {
        const dataUrl = fr.result.toString();
        this.invoicesService.saveJustification(dataUrl, this.project, new Date().toISOString(), '', this.cartrip_id).
          subscribe(res => {
            if (res['status'] == 1) {
              this.imageError = false;
              this.isPDF = true;
              this.sourceImg = URL.createObjectURL(blobPdfFromBase64String(dataUrl));
              this.toastr.success(this.translate.instant('invoices.success_image_upload'), '', { timeOut: 3000 });
            } else {
              this.imageError = true;
            }
          }, () => {
            this.imageError = true;
          });
      }

    } else {
      this.extensionError = true;
    }
  }
  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    // Create ZIP:
    var zip = new JSZip();
    // Create image folder:
    var img = zip.folder("invoices_files");
    // Get all images by Id:
    let ids = Array(this.cartrip_id);
    this.cartripService.downloadCartripImages(ids.toString())
      .subscribe(res => {
        res['cartrip_just'].forEach(el => {
          let el_string = el.just_url.toString();
          //Dowload pdf
          if (el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + '_km_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
            //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '_km_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });

        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "facturas.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 3000 });
      });
  }
}

