import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartripService {

  API_URL: string = environment.API_URL;
  constructor(
    private http: HttpClient
  ) { }

  getCartripById(id) {
    return this.http.get<any>(`${this.API_URL}/cartrip/${id}`);
  }

  addCartrip (projectId: any, date: string, form) {
    const body = {
      project_id: projectId,
      date: date,
      cartrip: form
    };

    console.log('submited', body);

    return this.http.post<any>(`${this.API_URL}/cartrip`, body);
  }

  updateCartripValues(id, project_id, form) {
    return this.http.put<any>(`${this.API_URL}/cartrip/${id}`,
      {
        project_id,
        form
      });
  }

  updateCartripValuesManager(id, amount_no_tax, role) {
    return this.http.put<any>(`${this.API_URL}/cartrip/${id}`,
      {
        amount_no_tax,
        role
      });
  }

  updateCartripValuesAdmin(id, amount_no_tax,date, projectId, task, description, nr_km_can, nr_km_pe, place, role) {

    return this.http.put<any>(`${this.API_URL}/cartrip/${id}`,
    {
      amount_no_tax,
      date,
      projectId,
      task,
      description,
      nr_km_can,
      nr_km_pe,
      place,
      role
    });
  }

  updateCartripValuesNormal(id, amount_no_tax, date, projectId, task, description, nr_km_can, nr_km_pe, place, role) {
    return this.http.put<any>(`${this.API_URL}/cartrip/${id}`,
    {
      amount_no_tax,
      date,
      projectId,
      task,
      description,
      nr_km_can,
      nr_km_pe,
      place,
      role
    });
  }

  deleteCartrip(id: string) {
    return this.http.delete<any>(`${this.API_URL}/cartrip/` + id);
  }

  getPlaces() {
    return this.http.post<any>(`${this.API_URL}/cartrip/places`,{});
  }

  saveJustification(image: string, project_name: string, date: string, cartrip_id: string) {
    const id = {cartrip_id: cartrip_id};
    return this.http.put<any>(`${this.API_URL}/image/${JSON.stringify(id)}`,
    {
      image,
      project_name,
      date
    });

  }

  downloadCartripImages(ids: string) {
    return this.http.put<any>(`${this.API_URL}/download/` + ids, {});
  }
}
