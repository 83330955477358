<h1 mat-dialog-title class="dialog-center">{{ 'dashboard.trip_expends_title' | translate }}</h1>
<p>{{ 'dashboard.trip_expends_paragraph' | translate }}</p>
<form #f="ngForm">
  <div mat-dialog-content class="dialog-center">

    <mat-form-field appearance="outline">
          <mat-label>{{ 'dashboard.users' | translate }}</mat-label>
          <mat-select aria-label="{{ 'dashboard.user' | translate }}" [(ngModel)]="data.user" name="user" multiple required>
              <mat-option *ngFor="let user of data.users" [value]="user">
                  {{user}}
              </mat-option>
          </mat-select>
      </mat-form-field>

      <div fxLayout="row" fxLayoutGap="20px">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'dashboard.start_date' | translate }}</mat-label>
            <input matInput [(ngModel)]="data.dateFrom" [matDatepicker]="pickerFrom" name="dateFrom" required #dateFrom>
            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
            <mat-datepicker #pickerFrom></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
            <mat-label>{{ 'dashboard.end_date' | translate }}</mat-label>
            <input matInput [(ngModel)]="data.dateTo" [matDatepicker]="pickerTo" name="dateTo" required #dateTo>
            <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
            <mat-datepicker #pickerTo></mat-datepicker>
        </mat-form-field>
      </div>
      <mat-form-field appearance="outline">
          <mat-label>{{ 'dashboard.from_to' | translate }}</mat-label>
          <input matInput [(ngModel)]="data.from_to" type="text" name="from_to" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
          <mat-label>{{ 'dashboard.project' | translate }}</mat-label>
          <input matInput [(ngModel)]="data.project" type="text" name="project" required>
      </mat-form-field>
      <mat-form-field appearance="outline">
          <mat-label>{{ 'dashboard.task' | translate }}</mat-label>
          <input matInput [(ngModel)]="data.task" type="text" name="task">
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'dashboard.paid_mode' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.paid_mode" type="text" name="paid_mode" required>
    </mat-form-field>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-raised-button (click)="onCancelClick()" [innerHTML]="'dashboard.cancel' | translate"></button>
      <button mat-raised-button color="accent" [mat-dialog-close]="data" cdkFocusInitial
          [innerHTML]="'dashboard.generate' | translate" [disabled]="!f.valid"></button>
    </div>
</form>

