<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>
    
    <div *ngIf="!loadingFlag">
        <h1>{{ 'config.title' | translate }}</h1>
    </div>

    <div class="view-container" *ngIf="!loadingFlag">
        <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                <mat-card fxFlex="100%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="price_int_night" placeholder="{{ 'config.price_int_night' | translate }}"
                                value="{{price_int_night}}" name="price_int_night" required>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="price_nat_night" placeholder="{{ 'config.price_nat_night' | translate }}"
                                value="{{price_nat_night}}" name="price_nat_night" required>
                        </mat-form-field>                        
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="price_can_km" placeholder="{{ 'config.price_can_km' | translate }}"
                                value="{{price_can_km}}" name="price_can_km" required>
                        </mat-form-field>
                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="price_pe_km" placeholder="{{ 'config.price_pe_km' | translate }}"
                                value="{{price_pe_km}}" name="price_pe_km" required>
                        </mat-form-field> 
                                            
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid">{{ 'config.save'
                            | translate }}</button>
                    </div>   
                </mat-card>
            </div>
        </form>
    </div>
    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>