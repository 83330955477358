<div class="main-section">
    <h1>{{ 'languages.title_add' | translate }}</h1>

    <div class="form-container mat-elevation-z8">
        <div id="loading-spinner" [hidden]="!loadingFlag">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
                <mat-spinner></mat-spinner>
            </div>
        </div>

        <div [hidden]="loadingFlag">
            <p>{{ 'languages.note_add' | translate }}</p>
            <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
                <mat-form-field>
                    <input type="text" matInput [(ngModel)]="name" placeholder="{{ 'languages.name' | translate }}"
                        name="name" required>
                </mat-form-field>

                <mat-form-field>
                    <input type="text" matInput [(ngModel)]="code" placeholder="{{ 'languages.code' | translate }}"
                        name="code" required>
                </mat-form-field>

                <div fxLayout="row" fxLayoutAlign="end center">
                    <button mat-raised-button type="submit" color="accent"
                        [disabled]="!f.valid">{{ 'languages.send_button' | translate }}</button>
                </div>
            </form>
        </div>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>