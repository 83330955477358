import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { RoleService } from '../_services/role.service';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-role-add',
  templateUrl: './role-add.component.html',
  styleUrls: ['./role-add.component.scss']
})
export class RoleAddComponent implements OnInit {
  name: string;
  loadingFlag: boolean = false;

  constructor(
    private router: Router,
    private roleService: RoleService,
    private toastr: ToastrService,
    private translate: TranslateService,
    private managerService: ManagerService
  ) { }

  ngOnInit() {
    this.managerService.setVoidFilter();
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.roleService.addRole(event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('roles.success_role_add'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => this.router.navigate(['/role-list'])
              );
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('roles.error_role_exist'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('roles.error_role_add'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );

    this.managerService.setVoidFilter();
  }

}
