import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DateAdapter, MatOptionModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatExpansionModule, MatExpansionPanel } from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserEditComponent } from './user-edit/user-edit.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { RoleAddComponent } from './role-add/role-add.component';
import { RoleEditComponent } from './role-edit/role-edit.component';
import { RoleListComponent } from './role-list/role-list.component';
import { ProfileComponent } from './profile/profile.component';
import { LanguageListComponent } from './language-list/language-list.component';
import { LanguageAddComponent } from './language-add/language-add.component';
import { LanguageEditComponent } from './language-edit/language-edit.component';
import { MatPaginatorI18nService } from './_services/mat-paginator-i18n.service';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { RecoverPasswordComponent } from './password/recover-password/recover-password.component';
import { NewPasswordComponent } from './password/new-password/new-password.component';
import { ProjectAddComponent } from './projects/project-add/project-add.component';
import { ChangeUpDialog, CloseProjectDialog, FundProjectDialog, OpenProjectDialog, ProjectListComponent, UnfundProjectDialog } from './projects/project-list/project-list.component';
import { ProjectViewComponent } from './projects/project-view/project-view.component';
import { DeleteProjectDialog, ProjectEditComponent } from './projects/project-edit/project-edit.component';
import { TaskListComponent, EditTaskDialog, DeleteTaskDialog } from './tasks/task-list/task-list.component';
import { InvoiceListComponent } from './invoices/invoice-list/invoice-list.component';
import { InvoiceViewComponent } from './invoices/invoice-view/invoice-view.component';
import { InvoiceEditComponent, DeleteInvoiceDialog } from './invoices/invoice-edit/invoice-edit.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {  ManagerListComponent, PaidCostDialog, UnpaidCostDialog } from './manager-list/manager-list.component';
import { DeleteOvernightDialog, OvernightViewComponent } from './overnight-view/overnight-view.component';
import { CartripViewComponent, DeleteCartripDialog } from './cartrip-view/cartrip-view.component';
import { OvernightListComponent } from './overnight-list/overnight-list.component';
import { ConfigComponent } from './config/config.component';
import { InvoiceAddComponent } from './invoices/invoice-add/invoice-add.component';
import { ExpendAddComponent } from './expends/expend-add/expend-add.component';
import {MatTabsModule} from '@angular/material/tabs';
import {
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/es';
import { ChangeCTDialog, ChangeVelazquezDialog, ManagerDashboardComponent, PaidManagerDialog, TripExpendsDialog, UnpaidManagerDialog } from './manager-dashboard/manager-dashboard.component';
import { StopChangePropagationDirective } from './_helpers/stop-change-propagation.directive';
import { VehicleListComponent } from './vehicles/vehicle-list/vehicle-list.component';
import { DeleteVehicleDialog, VehicleEditComponent } from './vehicles/vehicle-edit/vehicle-edit.component';
import { VehicleAddComponent } from './vehicles/vehicle-add/vehicle-add.component';
import { AgrestatripListComponent } from './agrestatrips/agrestatrip-list/agrestatrip-list.component';
import { AgrestatripEditComponent, DeleteAgrestatripDialog } from './agrestatrips/agrestatrip-edit/agrestatrip-edit.component';
import { OvernightAddComponent } from './overnight-add/overnight-add.component';
import { CartripAddComponent } from './cartrip-add/cartrip-add.component';
import { MatRadioModule } from '@angular/material/radio';
import { AgrestatripAddComponent } from './agrestatrips/agrestatrip-add/agrestatrip-add.component';

registerLocaleData(es, 'es-ES');

@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    LoginComponent,
    UserListComponent,
    UserEditComponent,
    SidenavComponent,
    RoleAddComponent,
    RoleEditComponent,
    RoleListComponent,
    ProfileComponent,
    LanguageListComponent,
    LanguageAddComponent,
    LanguageEditComponent,
    TermsConditionsComponent,
    RecoverPasswordComponent,
    NewPasswordComponent,
    ProjectAddComponent,
    ProjectListComponent,
    ProjectViewComponent,
    ProjectEditComponent,
    VehicleListComponent,
    VehicleEditComponent,
    VehicleAddComponent,
    AgrestatripListComponent,
    AgrestatripEditComponent,
    AgrestatripAddComponent,
    TaskListComponent,
    EditTaskDialog,
    DeleteTaskDialog,
    DeleteInvoiceDialog,
    DeleteOvernightDialog,
    DeleteProjectDialog,
    DeleteCartripDialog,
    DeleteVehicleDialog,
    DeleteAgrestatripDialog,
    CloseProjectDialog,
    OpenProjectDialog,
    UnfundProjectDialog,
    FundProjectDialog,
    ChangeVelazquezDialog,
    ChangeUpDialog,
    ChangeCTDialog,
    UnpaidCostDialog,
    PaidCostDialog,
    UnpaidManagerDialog,
    PaidManagerDialog,
    TripExpendsDialog,
    InvoiceListComponent,
    InvoiceViewComponent,
    InvoiceEditComponent,
    ManagerListComponent,
    OvernightViewComponent,
    CartripViewComponent,
    CartripAddComponent,
    ConfigComponent,
    OvernightAddComponent,
    OvernightListComponent,
    InvoiceAddComponent,
    ExpendAddComponent,
    ManagerDashboardComponent,
    StopChangePropagationDirective
  ],
  imports: [
    MatRadioModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    MatButtonModule,
    MatCardModule,
    MatGridListModule,
    MatTabsModule,
    MatSidenavModule,
    MatChipsModule,
    MatIconModule,
    MatFormFieldModule,
    MatOptionModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatToolbarModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatTooltipModule,
    MatAutocompleteModule,
    NgxExtendedPdfViewerModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: MatPaginatorIntl, useClass: MatPaginatorI18nService },
    { provide: LOCALE_ID, useValue: "es-ES" },
    {provide: MAT_DATE_LOCALE, useValue: 'es-ES'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
  bootstrap: [AppComponent],
  entryComponents: [EditTaskDialog, DeleteTaskDialog, DeleteInvoiceDialog, DeleteProjectDialog, DeleteOvernightDialog, DeleteCartripDialog, CloseProjectDialog, OpenProjectDialog, UnfundProjectDialog, FundProjectDialog, ChangeUpDialog, ChangeCTDialog, ChangeVelazquezDialog, UnpaidCostDialog, PaidCostDialog, UnpaidManagerDialog, PaidManagerDialog, TripExpendsDialog]
})
export class AppModule { }

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  const bustCache = `?v=${new Date().getTime()}`; // Using a timestamp to bust the cache
  return new TranslateHttpLoader(http, `./assets/i18n/`, ".json" + bustCache);
}
