import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { InvoicesService } from 'src/app/_services/invoices.service';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';

export interface InvoiceTable {
  id: number;
  project: string;
  task: string;
  user: string;
  invoice_date: string;
  amount_with_tax: string;
  tax: string;
  amount_no_tax: string;
  description: string;
  unique_identifier: string;
}

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit, OnDestroy {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['project', 'task', 'user', 'invoice_date', 'amount_with_tax', 'amount_no_tax', 'description', 'actions'];
  downloadColumns: string[] = [
    this.translate.instant('invoices.project'),
    this.translate.instant('invoices.task'),
    this.translate.instant('invoices.user'),
    this.translate.instant('invoices.invoice_date'),
    this.translate.instant('invoices.amount_with_tax'),
    this.translate.instant('invoices.tax'),
    this.translate.instant('invoices.amount_no_tax'),
    this.translate.instant('invoices.description'),
    this.translate.instant('invoices.unique_identifier')
  ];
  dataSource = new MatTableDataSource<InvoiceTable>();
  totalAmount: number;
  totalAmountWithoutTax: number;

  searchForm: FormGroup;
  user: string = '';
  project: string = '';
  task: string = '';
  amountFrom: string = '';
  amountTo: string = '';
  dateFrom: string = '';
  dateTo: string = '';

  constructor(
    private invoicesService: InvoicesService,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    let filters: string[] = this.invoicesService.getFilters();

    this.user = filters[0];
    this.project = filters[1];
    this.task = filters[2];
    this.amountFrom = filters[3];
    this.amountTo = filters[4];
    this.dateFrom = filters[5];
    this.dateTo = filters[6];
  }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.getInvoices();
  }

  ngOnDestroy(): void {
    this.invoicesService.setFilters(
      this.user,
      this.project,
      this.task,
      this.amountFrom,
      this.amountTo,
      this.dateFrom,
      this.dateTo
    );
  }

  getInvoices() {
    this.invoicesService.getInvoices()
      .subscribe(res => {
        const INVOICE_DATA: InvoiceTable[] = res['invoices'];
        this.dataSource.data = INVOICE_DATA;

        this.totalAmount = res['invoices'].map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
        this.totalAmountWithoutTax = res['invoices'].map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
        this.searchFormInit();
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();

        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  searchFormInit() {
    this.searchForm = new FormGroup({
      user: new FormControl(this.user == '' ? '' : this.user),
      project: new FormControl(this.project == '' ? '' : this.project),
      task: new FormControl(this.task == '' ? '' : this.task),
      amountFrom: new FormControl(this.amountFrom == '' ? '' : this.amountFrom),
      amountTo: new FormControl(this.amountTo == '' ? '' : this.amountTo),
      dateFrom: new FormControl(this.dateFrom == '' ? '' : this.dateFrom),
      dateTo: new FormControl(this.dateTo == '' ? '' : this.dateTo)
    });
  }

  getFilterPredicate() {
    return (row: InvoiceTable, filters: string) => {
      // Split string per '$' to array
      const filterArray = filters.split('$');

      const user = filterArray[0];
      const project = filterArray[1];
      const task = filterArray[2];
      let amountFrom = filterArray[3].replace(",", ".");
      let amountTo = filterArray[4].replace(",", ".");
      let dateFrom = filterArray[5];
      let dateTo = filterArray[6];

      const matchFilter = [];

      // Fetch data from row
      const columnUser = row.user;
      const columnProject = row.project;
      const columnTask = row.task;
      const columnAmount = row.amount_with_tax;
      const columnDate = row.invoice_date;

      // Verify fetching data by our searching values
      const customFilterUser = columnUser.toLowerCase().includes(user);
      const customFilterPoject = columnProject.toLowerCase().includes(project);
      const customFilterTask = columnTask.toLowerCase().includes(task);
      let customFilterAmountFrom;
      let customFilterAmountTo;
      let customFilterDateFrom;
      let customFilterDateTo;

      // Add maximum and minimum dates if fields are blank
      if (!Date.parse(dateFrom)) {
        dateFrom = '2000-01-01';
      }
      if (!Date.parse(dateTo)) {
        dateTo = Date.now().toString();
      }

      // Check amount_with_tax:
      if (+amountFrom <= +columnAmount) {
        customFilterAmountFrom = true;
      }
      if (+amountTo >= +columnAmount) {
        customFilterAmountTo = true;
      }

      // Check date limits
      if (Date.parse(dateFrom) <= Date.parse(columnDate)) {
        customFilterDateFrom = true;
      }
      // Add miliseconds to just be that dateTo 23:59:59
      if (Date.parse(columnDate) <= Date.parse(dateTo) + 86399999) {
        customFilterDateTo = true;
      }

      // Push boolean values into array
      matchFilter.push(customFilterUser);
      matchFilter.push(customFilterPoject);
      matchFilter.push(customFilterTask);
      if (amountFrom) {
        matchFilter.push(customFilterAmountFrom);
      }
      if (amountTo) {
        matchFilter.push(customFilterAmountTo);
      }
      if (Date.parse(dateFrom)) {
        matchFilter.push(customFilterDateFrom);
      }
      if (Date.parse(dateTo)) {
        matchFilter.push(customFilterDateTo);
      }

      // Return true if all values in array is true
      // else return false
      return matchFilter.every(Boolean);
    };
  }

  applyFilter() {
    const user = this.searchForm.get('user').value;
    const project = this.searchForm.get('project').value;
    const task = this.searchForm.get('task').value;
    const amountFrom = this.searchForm.get('amountFrom').value;
    const amountTo = this.searchForm.get('amountTo').value;
    const dateFrom = this.searchForm.get('dateFrom').value;
    const dateTo = this.searchForm.get('dateTo').value;

    this.user = user ?? '';
    this.project = project ?? '';
    this.task = task ?? '';
    this.amountFrom = amountFrom ?? '';
    this.amountTo = amountTo ?? '';

    if (dateFrom != null && typeof dateFrom != 'string') {
      dateFrom.toLocaleDateString('es-Es');
    }

    if (dateTo != null && typeof dateTo != 'string') {
      dateTo.toLocaleDateString('es-Es');
    }

    this.dateFrom = (dateFrom === null || dateFrom === '') ? '' : dateFrom;
    this.dateTo = (dateTo === null || dateTo === '') ? '' : dateTo;

    // Create string of our searching values and split if by '$'
    const filterValue = this.user + '$' + this.project + '$' + this.task + '$' + this.amountFrom + '$' + this.amountTo + '$' + this.dateFrom + '$' + this.dateTo;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    // Recalculate total:
    this.totalAmount = this.dataSource.filteredData.map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
    this.totalAmountWithoutTax = this.dataSource.filteredData.map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
  }

  getCSV() {
    let data = this.dataSource.filteredData.map(el => {
      let tObj = {};
      tObj[this.translate.instant('invoices.project')] = el.project;
      tObj[this.translate.instant('invoices.task')] = el.task;
      tObj[this.translate.instant('invoices.user')] = el.user;
      tObj[this.translate.instant('invoices.invoice_date')] = el.invoice_date;
      tObj[this.translate.instant('invoices.amount_with_tax')] = (+el.amount_with_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.tax')] = (+el.tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.amount_no_tax')] = (+el.amount_no_tax).toLocaleString('es-ES');
      tObj[this.translate.instant('invoices.description')] = el.description;
      tObj[this.translate.instant('invoices.unique_identifier')] = el.unique_identifier;
      return tObj;
    });

    let fields = this.downloadColumns;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

  downloadCSV() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    let output = this.getCSV();
    saveAs(output, 'facturas.csv');

    this.loadingFlag = false;
    this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
  }

  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 3000 });

    // Get CSV:
    let output = this.getCSV();
    // Create ZIP:
    var zip = new JSZip();
    // Add CSV:
    zip.file('facturas.csv', output);
    // Create image folder:
    var img = zip.folder("invoices_img");
    // Get all images by Id:
    let ids = this.dataSource.filteredData.map(el => {
      let tObj = [];
      tObj.push(el.id);
      return tObj;
    });

    this.invoicesService.downloadInvoicesImages(ids.toString())
      .subscribe(res => {
        // Loop res:
        res['results'].forEach(el => {
          let el_string = el.image_url.toString();
          let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
          img.file(el.unique_identifier + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
        });

        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "facturas.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 3000 });
      });
  }
}
