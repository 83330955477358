import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, of } from "rxjs";
import { environment } from "src/environments/environment";
import { AgrestatripTable } from "../agrestatrips/agrestatrip-list/agrestatrip-list.component";

@Injectable({
    providedIn: 'root'
})
export class AgrestatripService {
    API_URL: string = environment.API_URL;

    private user: string;
    private project: string;
    private task: string;
    private dateFrom: string;
    private dateTo: string;
    private kmFrom: string;
    private kmTo: string;

    up: string;
    closed: string;
    vehicle: string;

    isManagerDashboard: boolean = false;

    constructor(
        private http: HttpClient
    ) {}

    getAgrestatrips(): Observable<AgrestatripTable[]> {
        return this.http.get<AgrestatripTable[]>(`${this.API_URL}/agrestatrips`);
    }

    getAgrestatripById (id: string): Observable<AgrestatripTable> {
        return this.http.get<AgrestatripTable>(`${this.API_URL}/agrestatrips/${id}`);
    }

    addAgrestatrip(projectId: string, form: any, image: string, date: string) {
        const body = {
            date,
            project_id: projectId,
            agrestatrip: {
              ...form,
              image: image
            }
        };

        return this.http.post<any>(`${this.API_URL}/agrestatrips`, body);
    }

    updateAgrestatripValues(id, project_id, form) {
        return this.http.put<any>(`${this.API_URL}/agrestatrips/${id}`,
        {
            project_id,
            form
        });
    }

    deleteAgrestatrip (agrestatrip_id: string) {
        return this.http.delete(`${this.API_URL}/agrestatrips/${agrestatrip_id}`);
    }

    downloadAgrestatripImages(ids: string) {
        return this.http.get<any>(`${this.API_URL}/agrestatrips/download/` + ids, {});
    }

    uploadImage(image: string, agrestatrip_id: string, project_name: string, date: string) {
        return this.http.put<any>(`${this.API_URL}/agrestatrips/download/${agrestatrip_id}`,
        {
          image,
          project_name,
          date
        });
    }

    setFilters(
        user: string,
        project: string,
        task: string,
        kmFrom: string,
        kmTo: string,
        dateFrom: string,
        dateTo: string,
        up: string,
        closed: string,
        vehicle: string
    ) {
        console.log('setFilters', up);
        this.user = user;
        this.project = project;
        this.task = task;
        this.kmFrom = kmFrom;
        this.kmTo = kmTo;
        this.dateFrom = dateFrom;
        this.dateTo = dateTo;
        this.up = up;
        this.closed = closed;
        this.vehicle = vehicle;
    }

    getFilters() {
        console.log('getFilters', this.up);
        return [
          this.user,
          this.project,
          this.task,
          this.kmFrom,
          this.kmTo,
          this.dateFrom,
          this.dateTo,
          this.up,
          this.closed,
          this.vehicle
        ];
    }

    setVoidFilter () {
        this.user = undefined;
        this.project = undefined;
        this.task = undefined;
        this.kmFrom = undefined;
        this.kmTo = undefined;
        this.dateFrom = undefined;
        this.dateTo = undefined;
        this.up = undefined;
        this.closed = undefined;
        this.vehicle = undefined;
    }

    getIsManagerDashboard() {
        return this.isManagerDashboard;
    }

    setIsManagerDashboard(isManagerDashboard: boolean) {
        this.isManagerDashboard = isManagerDashboard;
    }
}