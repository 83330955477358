import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TasksService } from 'src/app/_services/tasks.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { log } from 'console';
import { ManagerService } from 'src/app/_services/manager.service';

export interface TaskTable {
  id: number;
  name: string;
}

@Component({
  selector: 'edit-task-dialog',
  templateUrl: 'edit-task-dialog.html',
  styleUrls: ['./task-list.component.scss']
})
export class EditTaskDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<EditTaskDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: EditTaskDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'delete-task-dialog',
  templateUrl: 'delete-task-dialog.html',
  styleUrls: ['./task-list.component.scss']
})
export class DeleteTaskDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteTaskDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteTaskDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['name', 'invoices', 'actions'];
  dataSource = new MatTableDataSource<TaskTable>();
  projectName: string;
  projectId: string;
  name: string;
  nameAdd: string;
  noData: boolean = false;

  constructor(
    private taskService: TasksService,
    private activedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private translate: TranslateService,
    public dialog: MatDialog,
    private managerService: ManagerService
  ) { }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit(): void {
    this.loadingFlag = true;
    const routeParams = this.activedRoute.snapshot.params;
    this.projectId = routeParams.id;
    this.getTasksByProjectId(this.projectId);
    this.managerService.setVoidFilter();
  }

  getTasksByProjectId(id: string) {
    const object = JSON.stringify({
      id: id,
      offset: true
    });
    this.taskService.getTasksByProjectId(object)
      .subscribe(res => {
        const TASK_DATA: TaskTable[] = res['tasks'];  
        this.projectName = res['project_name']

        if (res['status'] == 1) {
          this.dataSource.data = TASK_DATA;
          this.noData = false;
        } else {
          this.noData = true;
        }

        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onSubmit(event: any) {
    this.loadingFlag = true;

    this.taskService.addTask(this.projectId, event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('tasks.success_task_add'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.nameAdd = '';
                  this.getTasksByProjectId(this.projectId);
                }
              );
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('tasks.error_task_exist'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          } else {
            this.toastr.error(this.translate.instant('tasks.error_task_add'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  editTaskDialog(taskId: string, newName: string) {
    const dialogRef = this.dialog.open(EditTaskDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.editTask(taskId, newName);
      }
    });
  }

  editTask(taskId: string, newName: string) {
    this.loadingFlag = true;

    this.taskService.editTask(this.projectId, taskId, newName)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('tasks.success_task_edit'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.getTasksByProjectId(this.projectId);
                }
              );
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('tasks.error_task_exist'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.getTasksByProjectId(this.projectId);
                }
              );
          } else {
            this.toastr.error(this.translate.instant('tasks.error_task_edit'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.getTasksByProjectId(this.projectId);
                }
              );
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

  deleteTaskDialog(taskId: string) {
    const dialogRef = this.dialog.open(DeleteTaskDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.deleteTask(taskId);
      }
    });
  }

  deleteTask(taskId: string) {
    this.loadingFlag = true;

    this.taskService.deleteTask(taskId)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('tasks.success_task_delete'), '', { timeOut: 3000 })
              .onHidden
              .subscribe(
                () => {
                  this.getTasksByProjectId(this.projectId);
                }
              );
          } else {
            this.toastr.error(this.translate.instant('tasks.error_task_delete'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }

}
