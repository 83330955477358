import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';

import { AuthenticationService } from '../_services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { NgForm } from '@angular/forms';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  error: boolean = false;
  error_message: string = '';
  loadingFlag: boolean = false;
  returnUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private managerService: ManagerService
  ) {
    // Redirect to /manager-list if already logged in
    // if (this.authService.currentUserValue) {
    //   this.router.navigate(['/manager-list']);
    // }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/manager-list'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/manager-list';
    this.managerService.setVoidFilter();
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let username = form.value.username;
    let password = form.value.password;
    this.loadingFlag = true;
    this.authService.login(username, password)
      .pipe(
        take(1)
      )
      .subscribe(
        (data: any[]) => {
          if (data && data['status'] == 1) {
            // Redirect to /manager-list when logged in:
            this.router.navigate([this.returnUrl]);
          } else if (data && data['status'] == 2) {
            // User exists, but it's not active:
            this.error_message = this.translate.instant('login.user_inactive');
            this.error = true;
            this.loadingFlag = false;
          }
          else {
            this.error_message = this.translate.instant('login.error');
            this.error = true;
            this.loadingFlag = false;
          }
        },
        error => {
          // console.log(error);
        }
      );
    form.reset();
  }
}
