<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag" class="mat-elevation-z8 filter-box">
        <h3>{{ 'agrestatrips.title_list' | translate }} - {{ 'manager.filter_manager' | translate }}</h3>
        <form [formGroup]="searchForm" class="search-form" *ngIf="searchForm">
            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true}">
                <mat-label>{{ 'manager.up' | translate }}</mat-label>
                <mat-select aria-label="{{ 'manager.up' | translate }}" formControlName="up" (selectionChange)="applyFilter()">
                    <mat-option *ngFor="let value of upsFilter" [value]="value.name">
                        {{value.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': projectClass}"> <!--[ngClass]="{'highlight': projectClass}"-->
                <mat-label>{{ 'manager.project' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'manager.project' | translate }}" matInput formControlName="project"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': userClass}">
                <mat-label>{{ 'manager.user' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'manager.user' | translate }}" matInput formControlName="user"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': taskClass}">
                <mat-label>{{ 'manager.task' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'manager.task' | translate }}" matInput formControlName="task"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true}">
                <mat-label>{{ 'manager.closed' | translate }}</mat-label>
                <mat-select aria-label="{{ 'manager.closed' | translate }}" formControlName="closed" (selectionChange)="applyFilter()">
                    <mat-option *ngFor="let value of closedArray" [value]="value.value">
                        {{value.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': projectClass}"> <!--[ngClass]="{'highlight': projectClass}"-->
                <mat-label>{{ 'agrestatrips.vehicle' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'agrestatrips.vehicle' | translate }}" matInput formControlName="vehicle"
                    (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': amountFromClass}">
                <mat-label>{{ 'agrestatrips.km_from' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'agrestatrips.km_from' | translate }}" matInput
                    formControlName="kmFrom" (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': amountToClass}">
                <mat-label>{{ 'agrestatrips.km_to' | translate }}</mat-label>
                <input type="text" aria-label="{{ 'agrestatrips.km_to' | translate }}" matInput
                    formControlName="kmTo" (keyup)="applyFilter()">
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': dateFromClass}">
                <mat-label>{{ 'manager.date_from' | translate }}</mat-label>
                <input matInput formControlName="dateFrom" [matDatepicker]="pickerFrom" (dateInput)="applyFilter()" #dateFrom>
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <mat-datepicker #pickerFrom></mat-datepicker>
            </mat-form-field>

            <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': dateToClass}">
                <mat-label>{{ 'manager.date_to' | translate }}</mat-label>
                <input matInput formControlName="dateTo" [matDatepicker]="pickerTo" value="dateTo" (dateInput)="applyFilter()" #dateTo>
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
            </mat-form-field>
            <button class="search-form-field reset-button" mat-raised-button color="primary" type="button" (click)="resetFilters()">
                {{ "manager.reset_filters" | translate }}
            </button>
        </form>
    </div>

    <div [hidden]="loadingFlag">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="up">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manager.up' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.up}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell">{{ 'manager.total' | translate }}</td>
            </ng-container>

            <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'manager.project' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.project}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="closed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'manager.closed' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.closed}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="task">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'manager.task' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.task}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'manager.user' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.user}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="vehicle">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'agrestatrips.vehicle' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.vehicle}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.start_date' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.date_start | date:'shortDate'}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="endDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.end_date' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.date_end | date:'shortDate'}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="totalKm">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.total_km' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.total_km }} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalKm }}</td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="multibutton">
                    <button  mat-icon-button color="accent" routerLink="/agrestatrip-edit/{{element.agrestatrip_id}}"
                        matTooltip="{{ 'agrestatrips.edit_trip' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <div *ngIf="!loadingFlag" class="download-row" [class.force-margin-bottom]="!loadingFlag">
        <button mat-raised-button color="accent" (click)="downloadCSV()" [disabled]="dataSource.data.length <= 0">
            {{ 'manager.download_csv' | translate }}
        </button>

        <button mat-raised-button color="accent" (click)="downloadFull()" [disabled]="dataSource.data.length <= 0">
            {{ 'agrestatrips.download_full' | translate }}
        </button>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>