<div class="main-section">
    <h1 [hidden]="loadingFlag">{{ 'tasks.title_list' | translate }}: {{ projectName }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag">
        <form class="add-form" (ngSubmit)="onSubmit(f.value)" #f="ngForm">
            <mat-form-field>
                <!-- DB uses Character varying (50) for that field -->
                <input type="text" [maxLength]='50' matInput [(ngModel)]="nameAdd"
                    placeholder="{{ 'tasks.new_task' | translate }}" name="nameAdd" required>
            </mat-form-field>

            <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid">{{ 'tasks.add_button' |
                translate }}</button>
        </form>
    </div>

    <div [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag" *ngIf="!noData">
        <h3>{{ 'tasks.tasks_list' | translate }}</h3>
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'tasks.filter' | translate }}">
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'tasks.name' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel="never" appearance="outline">
                        <!-- DB uses Character varying (50) for that field -->
                        <input matInput [maxLength]='50' placeholder=" {{element.task_name}}" [value]="element.name"
                            [(ngModel)]="element.name" (change)="editTaskDialog(element.id, element.name)">
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="invoices">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'tasks.invoices_amount' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.invoices}} </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="multibutton">
                    <button *ngIf="element.invoices == 0" mat-icon-button color="accent"
                        (click)="deleteTaskDialog(element.id)" matTooltip="{{ 'tasks.delete_task' | translate }}"
                        matTooltipPosition="above">
                        <mat-icon matListIcon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <div [hidden]="loadingFlag" class="no-task-note" [class.force-margin-bottom]="!loadingFlag" *ngIf="noData">
        <p>{{ 'tasks.no_tasks' | translate }}</p>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>