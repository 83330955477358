import { Component, OnInit } from '@angular/core';
import { ManagerService } from 'src/app/_services/manager.service';

@Component({
  selector: 'app-project-view',
  templateUrl: './project-view.component.html',
  styleUrls: ['./project-view.component.scss']
})
export class ProjectViewComponent implements OnInit {

  constructor(
    private managerService: ManagerService
  ) { 
    
  }

  ngOnInit(): void {
    this.managerService.setVoidFilter();
  }

}
