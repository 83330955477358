import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from '../_services/configuration.service';
import { ManagerService } from '../_services/manager.service';

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})
export class ConfigComponent implements OnInit {
  loadingFlag: boolean = false;
  price_int_night: number;
  price_nat_night: number;
  price_can_km: number;
  price_pe_km: number;
  

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private configService: ConfigurationService,
    private managerService: ManagerService
  ) { }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.managerService.setVoidFilter();

    this.configService.getConfig()
      .subscribe(res => {
        this.price_int_night = +res['price_int_night'];
        this.price_nat_night = +res['price_nat_night'];
        this.price_can_km = +res['price_can_km'];
        this.price_pe_km = +res['price_pe_km'];
        this.loadingFlag = false;
      },
        (err) => {
          this.loadingFlag = false;
          this.toastr.error(err, '', { timeOut: 3000 });
        });
  }

  onSubmit(event: any) {
    this.loadingFlag = true;
    this.configService.updateConfig(event)
      .subscribe(
        (res: any[]) => {
          if (res && res['status'] == 1) {
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('config.success_config_update'), '', { timeOut: 3000 });
          } else {
            this.toastr.error(this.translate.instant('config.error_config_update'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(error, '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
  }
}
