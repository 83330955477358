<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div *ngIf="!loadingFlag" class="section-header">
        <h1>{{ 'overnight.title_view' | translate }}</h1>
        <button mat-stroked-button (click)="backClicked()">
            <mat-icon aria-hidden="false" aria-label="chevron_left">chevron_left</mat-icon>
            {{ 'overnight.back_button' | translate }}
        </button>
    </div>

    <div class="view-container" [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag">
        <form  #f="ngForm" (submit)="onSubmit(f)">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                <mat-card fxFlex="100%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="user" placeholder="{{ 'overnight.user' | translate }}"
                                name="user" value="{{user}}" readonly>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showEditInputs()">
                            <mat-label>{{ 'overnight.date' | translate }}</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="pickerFrom" placeholder="{{ 'overnight.date' | translate }}" name="date"  readonly>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom" disabled></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom disabled></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field *ngIf="showEditInputs()">
                            <mat-label>{{ 'overnight.date' | translate }}</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="pickerFrom" placeholder="{{ 'overnight.date' | translate }}" name="date"  required>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="!showEditInputs()">
                        <mat-form-field>
                            <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="project" (ngModelChange)="projectValidation()"
                                placeholder="{{ 'overnight.project' | translate }}" name="project" value="{{project}}"
                                readonly>
                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                (optionSelected)="onSelectionChange($event)">
                                <mat-option *ngFor="let project of projects" [value]="project.name" disabled>
                                    {{project.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'overnight.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id" disabled>
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" *ngIf="showEditInputs()">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto" 
                                   name="project" #projectField="ngModel" 
                                   placeholder="{{ 'overnight.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'invoices.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>
                        
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-label>{{ 'overnight.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id">
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field *ngIf="!showEditInputs()">
                            <input type="text" matInput [(ngModel)]="description" placeholder="{{ 'overnight.description' | translate }}"
                                name="description" value="{{description}}" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="showEditInputs()">
                            <input type="text" matInput [(ngModel)]="description" placeholder="{{ 'overnight.description' | translate }}"
                                name="description" value="{{description}}">
                        </mat-form-field>
                        
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="!showEditInputs()">

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_nights_int"
                                placeholder="{{ 'overnight.nr_nights_int' | translate }}" name="nr_nights_int"
                                value="{{nr_nights_int}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_nights_nat" placeholder="{{ 'overnight.nr_nights_nat' | translate }}"
                                name="nr_nights_nat" value="{{nr_nights_nat}}" readonly>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="showEditInputs()">

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="nr_nights_int" (ngModelChange)="nightsChange()" placeholder="{{'overnight.nr_nights_int' | translate }}" name="nr_nights_int" value="{{nr_nights_int}}">
                        </mat-form-field>

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="nr_nights_nat" (ngModelChange)="nightsChange()" placeholder="{{ 'overnight.nr_nights_nat' | translate }}" name="nr_nights_nat" value="{{nr_nights_nat}}">
                        </mat-form-field>
                    </div>
                    <div *ngIf="!showEditManager()">
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                            <mat-form-field>
                                <input type="text" matInput placeholder="{{ 'overnight.amount_no_tax' | translate }}"
                                name="amount_no_tax" value="{{amount_no_tax | currency:'EUR'}}" readonly>
                            </mat-form-field>
                            <mat-form-field>
                                <input type="text" matInput [(ngModel)]="payroll" placeholder="{{ 'overnight.payroll' | translate}}"
                                    name="payroll" readonly>
                            </mat-form-field>
                            
                        </div>
                        <br>
                    </div>
                    <div *ngIf="showEditManager()">
                        <h6>{{ 'overnight.manager_editing' | translate }}</h6>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                            <mat-form-field>
                                <input type="number" matInput [(ngModel)]="amount_no_tax" placeholder="{{ 'overnight.amount_no_tax' | translate }}"
                                    name="amount_no_tax">
                            </mat-form-field>
                            <mat-form-field>
                                <input type="text" matInput [(ngModel)]="payroll" placeholder="{{ 'overnight.payroll' | translate}}"
                                    name="payroll">
                            </mat-form-field>
                            
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!showEditManager()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value" [disabled]="true">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="true"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="true"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="true">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>                
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="showEditManager()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="disbledPaid"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="disbledPaid"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="disbledPaid">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>                
                    </div>
                    <br>
                    <div *ngIf="showEditManager() || showEditInputs()">
                        <br>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                            <div class="section-footer" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag">
                                <button type='button' mat-raised-button color="warn" (click)="deleteOvernightDialog()">
                                    {{ 'overnight.delete_button' | translate }}
                                </button>
                            </div>
                            <div>
                                <button type="submit" mat-raised-button color="accent" [disabled]="!f.valid || !validateProject || !timeValidate || !paidModeValidate">
                                    {{ "overnight.title_edit" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>