import { Location } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import * as JSZip from "jszip";
import { ToastrService } from "ngx-toastr";
import { Observable, map, of } from "rxjs";
import { Role } from "src/app/_models/role";
import { AgrestatripService } from "src/app/_services/agrestatrip.service";
import { AuthenticationService } from "src/app/_services/authentication.service";
import { ProjectService } from "src/app/_services/project.service";
import { TasksService } from "src/app/_services/tasks.service";
import { saveAs } from 'file-saver';
import { VehicleService } from "src/app/_services/vehicle.service";
import * as moment from "moment";

@Component({
  selector: 'delete-agrestatrip-dialog',
  templateUrl: 'delete-agrestatrip-dialog.html',
  styleUrls: ['./agrestatrip-edit.component.scss']
})
export class DeleteAgrestatripDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<DeleteAgrestatripDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: DeleteAgrestatripDialog,
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
    selector: 'app-agrestatrip-edit',
    templateUrl: './agrestatrip-edit.component.html',
    styleUrls: ['./agrestatrip-edit.component.scss']
})
export class AgrestatripEditComponent implements OnInit {
    protected agrestatrip_id: string;
    loadingFlag: boolean = false;

    projects: any[];
    role: any;

    currentUser: Observable<any>;

    user: any;
    dateFrom: Date | moment.Moment;
    dateTo: Date | moment.Moment;
    project: any;
    task: string = '';
    tasks: any[] = [];
    kmStart: number;
    kmEnd: number;
    totalKm: number;

    validateProject: boolean = true;

    imageError: boolean;
    imageErrorReason: string;
    extensionError: boolean;
    
    sourceImg: any;

    vehicle_id: any;
    vehicles: any[] = [];
    localization_start: string;
    localization_end: string;

    changeSelectionproject: boolean;

    projectId: any;

    can_edit: any;
    is_admin: any;
    
    noImage: boolean;

    projectArrayObs: Observable<any[]>;

    constructor(
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private router: Router,
        private location: Location,
        private translate: TranslateService,
        private authService: AuthenticationService,
        public dialog: MatDialog,
        private taskService: TasksService,
        private projectService: ProjectService,
        private agrestatripService: AgrestatripService,
        private vehicleService: VehicleService
    ) { }

    ngOnInit(): void {
        const routeParams = this.activatedRoute.snapshot.params;
        this.agrestatrip_id = routeParams.id;
        this.loadingFlag = true;
        this.currentUser = this.authService.currentUser;
        this.getProjects();
        this.getRole();
        this.getVehicles();
    }

    getRole() {
        this.currentUser.subscribe(data => {
            this.role = data.role;
        });
    }

    getProjects() {
        this.projectService.getProjects()
            .subscribe(res => {
                this.projects = res['projects'].map(el => {
                    let tObj = {};
                    tObj['id'] = el.id;
                    tObj['name'] = el.code + ' - ' + el.name;
                    return tObj;
                });

                this.getAgrestatripById(this.agrestatrip_id);
            },
                (err) => {
                    this.loadingFlag = false;
                }
            );
    }

    getVehicles () {
      this.vehicleService.getVehicles()
        .subscribe(res => {
          console.log(res);
          this.vehicles = res['vehicles'].map(el => {
            let tObj = {};
            tObj['id'] = el.id;
            tObj['name'] = el.mat + ' - ' + el.model;
            return tObj;
          });
        });
    }

    onSelectionChange(event: any) {
        this.task = '';
        this.changeSelectionproject = true;
        let name = event.option.value;
        let tempProjId = this.projects.filter(el => el.name == name);
        this.projectId = tempProjId[0].id;
        this.getTasksByProjectId(this.projectId);
    }

    getAgrestatripById(id: string) {
        this.agrestatripService.getAgrestatripById(id)
          .subscribe((res: any) => {
            console.log("getAgrestatripById", res);
            this.user = res.user;
            this.dateFrom = new Date(res.date_start);
            this.dateTo = new Date(res.date_end);
            this.project = res.project;
            this.projectId = res.project_id;
            this.task = res.task_id;
            this.kmStart = res.km_start;
            this.kmEnd = res.km_end;
            this.totalKm = res.total_km;
            this.localization_start = res.localization_start;
            this.localization_end = res.localization_end;
            this.vehicle_id = res.vehicle_id;
            this.loadingFlag = false;
            
            let auxSourceImg = res['image_url']+ '?' + this.generateRandom();
            this.can_edit = res.can_edit === 'true'? true: false;
            this.is_admin = res.is_admin === 'true'? true: false;
            this.getTasksByProjectId(this.projectId);
    
            if (res['image_url'] && auxSourceImg) {
              this.noImage = false;
            } else {
              this.noImage = true;
            }
    
            setTimeout(() => {
              this.sourceImg = auxSourceImg;
            }, 1500);
          },
            (err) => {
              this.toastr.error(err, '', { timeOut: 3000 });
              this.loadingFlag = false;
            });
    }

    deleteAgrestatripDialog() {
      const dialogRef = this.dialog.open(DeleteAgrestatripDialog, {
        width: '300px'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.deleteCartrip();
        }
      });
    }
  
    deleteCartrip() {
      this.loadingFlag = true;
  
      this.agrestatripService.deleteAgrestatrip(this.agrestatrip_id)
        .subscribe(
          (res: any[]) => {
            if (res && res['status'] == 1) {
              this.toastr.success(this.translate.instant('agrestatrips.success_delete'), '', { timeOut: 3000 })
                .onHidden
                .subscribe(() => {
                    this.router.navigate(['agrestatrip-list']);
                  }
                );
            } else {
              this.toastr.error(this.translate.instant('agrestatrips.error_delete'), '', { timeOut: 3000 });
              this.loadingFlag = false;
            }
          },
          error => {
            this.toastr.error(String(error), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        );
    }

    getTasksByProjectId(id: string) {
        this.loadingFlag = true;
        const object = JSON.stringify({
          id: id,
          offset: false
        });
        this.taskService.getTasksByProjectId(object)
          .subscribe(res => {
            this.tasks = res['tasks'];
            if(this.changeSelectionproject) {
              this.task = this.tasks[0].id;
            }
            this.loadingFlag = false;
        },
        (err) => {
            this.loadingFlag = false;
        });
    }

    showEditManager() {
        return this.role === Role.Admin || this.role === Role.Manager;
    }

    showEditInputs() {
        return this.can_edit || this.is_admin || this.role === Role.Manager;
    }

    backClicked() {
        this.location.back();
    }

    onSubmit(form: NgForm) {
        if(!form.valid) {
          return;
        }

        // In order to prevent values to accidentally change, we make sure they are
        // moment objects, and then formatting them as dates without time
        form.value.dateFrom = moment(this.dateFrom).format('Y-MM-DD');
        form.value.dateTo = moment(this.dateTo).format('Y-MM-DD');
    
        this.agrestatripService.updateAgrestatripValues(this.agrestatrip_id, this.projectId, form.value)
        .subscribe((res: any) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('agrestatrips.success_edit'), '', { timeOut: 3000 });
            this.router.navigate(['agrestatrip-list']);
          } else {
            this.toastr.error(this.translate.instant('agrestatrips.error_edit'), '', { timeOut: 3000 });
            this.loadingFlag = false;
          }
        },
        error => {
          this.toastr.error(String(error), '', { timeOut: 3000 });
          this.loadingFlag = false;
        }
      );
    }

    doFilter() {
        this.projectArrayObs = of(this.projects);
        this.projectArrayObs = this.projectArrayObs
          .pipe(
            map(res => this.filter(res))
          )
    
          this.onBlurProject();
    
    }

    filter(projects) {
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name.toLowerCase().includes(this.project.toLowerCase()))
    }

    filterValidate(projects) {
      console.log("projects", projects);
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name == this.project
        );
    }

    onBlurProject() {
        const project = this.filterValidate(this.projects);
        if(project.length == 1) {
          this.getTasksByProjectId(project[0].id);
          this.projectId = project[0].id;
          this.validateProject = true;
        } else {
          this.validateProject = false;
        }
    }

    updateTotalKm () {
      this.totalKm = this.kmStart < this.kmEnd ? this.kmEnd - this.kmStart : 0;
    }

    imageChanged(event: any) {
        let fileContent = (event.target as HTMLInputElement).files[0];
        if (!fileContent) {
          this.imageError = true;
          return;
        }

        this.loadingFlag = true;
    
        let fileName = event.target.files[0].name;
        let fileExtension = fileName.split('.').pop();
    
        fileExtension = fileExtension.toLowerCase();
    
        if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {
          this.imageError = false;
          this.extensionError = false;
    
          const fr = new FileReader();
          fr.onload = () => {
            const dataUrl = fr.result.toString();
            this.agrestatripService.uploadImage(dataUrl, this.agrestatrip_id, this.project, new Date().toISOString()).
              subscribe(res => {
                if (res['status'] == 1) {
                  this.imageError = false;
                  this.noImage = false;
                  this.sourceImg = dataUrl;
                  this.toastr.success(this.translate.instant('agrestatrips.success_upload'), '', { timeOut: 3000 });
                } else {
                  this.imageError = true;
                }
              }, () => {
                this.imageError = true;
              }, () => {
                this.loadingFlag = false;
              });
          }
          fr.readAsDataURL(fileContent);
        } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
          this.extensionError = true;
        }
    }

    downloadFull() {
        this.loadingFlag = true;
        this.toastr.warning(this.translate.instant('agrestatrips.downloading_csv'), '', { timeOut: 3000 });
    
        // Create ZIP:
        let zip = new JSZip();
        // Create image folder:
        let img = zip.folder("agrestatrip_images");
        // Get all images by Id:
        let ids = Array(this.agrestatrip_id);

        // Counter to know how many images are loaded into the folder
        let cont = 0;
        this.agrestatripService.downloadAgrestatripImages(ids.toString())
          .subscribe(res => {
            res['images'].forEach(el => {
              if (!el.image_ok) {
                this.imageErrorReason = el.image_reason;
                return;
              }

              let el_string = el.image_url.toString();
              let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
              img.file(el.unique_identifier + '_agrestatrip_image' + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
              cont++;
            });

            if (cont === 0) {
              this.toastr.error(this.translate.instant('agrestatrips.downloading_images_error') + this.imageErrorReason, '', { timeOut: 3000 });
              this.loadingFlag = false;
              return;
            }
    
            // Generate final ZIP file:
            zip.generateAsync({ type: "blob" }).then(function (content) {
              // see FileSaver.js
              saveAs(content, "agrestatrips.zip");
            });
    
            this.loadingFlag = false;
            this.toastr.success(this.translate.instant('agrestatrips.downloading_images_success'), '', { timeOut: 3000 });
          }, err => {
            this.loadingFlag = false;
            this.toastr.error(this.translate.instant('agrestatrips.downloading_images_error') + this.imageErrorReason, '', { timeOut: 3000 });
          });
    }

    private generateRandom() {
        return Math.floor(Math.random() * 100000);
    }
}