import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ExpendService } from '../_services/expend.service';
import { InvoicesService } from '../_services/invoices.service';
import { ManagerService } from '../_services/manager.service';
import { UpService } from '../_services/up.service';
import { saveAs } from 'file-saver';
import * as json2csv from 'json2csv';
import * as JSZip from 'jszip';
import { HeadquartersService } from '../_services/headquarters.service';
import { ProjectService } from '../_services/project.service';
import { ChangeUpDialog } from '../projects/project-list/project-list.component';
import { TaxService } from '../_services/tax.service';
import { ColumnService } from '../_services/column.service';
import { PdfService } from '../_services/pdf.service';
import * as moment from 'moment';
import { CartripService } from '../_services/cartrip.service';

@Component({
  selector: 'trip-expends-dialog',
  templateUrl: 'trip-expends-dialog.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class TripExpendsDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<TripExpendsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'paid-cost-dialog',
  templateUrl: 'paid-cost-dialog.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class PaidManagerDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<PaidManagerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'change-up-dialog',
  templateUrl: 'change-ct-dialog.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ChangeCTDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<ChangeCTDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: ChangeCTDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'change-velazquez-dialog',
  templateUrl: 'change-velazquez-dialog.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class ChangeVelazquezDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<ChangeVelazquezDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: ChangeVelazquezDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'unpaid-cost-dialog',
  templateUrl: 'unpaid-cost-dialog.html',
  styleUrls: ['./manager-dashboard.component.scss']
})
export class UnpaidManagerDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<UnpaidManagerDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

export interface ManagerTable {
  id: number;
  invoice_id: number;
  overnight_id: number;
  cartrip_id: number;
  project: string;
  task: string;
  user: string;
  date: string;
  amount_with_tax: string;
  tax: string;
  amount_no_tax: string;
  description: string;
  invoice_type: string;
  unique_identifier: string;
  comments: string;
  can_edit: boolean;
  is_admin: boolean;
  is_manager: boolean;
  nr_nights_int: number;
  nr_nights_nat: number;
  paid: string;
  payroll: string;
  place: string;
  nr_km_pe: number;
  nr_km_can: number;
  module: string;
  closed: string;
  headquarter: string;
  up: string;
  project_id: any;
  user_id: any;
  tax_type: any;
  pay_to: any;
  review: any;
  velazquez_input: any;
  select: boolean;
  time: string;
  paid_mode: any;
  provider: any;
  fund: any;
  just: any;
  url_just: any;
}

@Component({
  selector: 'app-manager-dashboard',
  templateUrl: './manager-dashboard.component.html',
  styleUrls: ['./manager-dashboard.component.scss'],
})
export class ManagerDashboardComponent implements OnInit, OnDestroy {

  loadingFlag: boolean;
  searchForm: FormGroup;
  user: any = '';
  project: string = '';
  task: string = '';
  amountFrom: string = '';
  amountTo: string = '';
  dateFrom: string = '';
  dateTo: string = '';
  datePaidFrom: string = '';
  datePaidTo: string = '';
  dataSource = new MatTableDataSource<ManagerTable>();
  totalAmount: number;
  totalAmountWithoutTax: number;
  module: string = '';
  ups: any[] = [];
  headquarters: any[] = [];
  private upsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private headquartersEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  moduleArray: Object[] = [
    {name: 'Factura', value: 'Factura'},
    {name: 'Pernocta', value: 'Pernocta'},
    {name: 'Km', value: 'Km'},
    {name: 'Todos', value: ''}
  ];
  users: any[];
  reviews: Object[] = [
    {name: 'Sí', value: 'Sí'},
    {name: 'No', value: 'No'},
    {name: 'Todas', value: 'Todas'}
  ];

  funds: Object[] = [
    {name: 'Sí', value: 'Sí'},
    {name: 'No', value: 'No'},
    {name: 'Todas', value: 'Todas'}
  ];

  justs: Object[] = [
    {name: 'Sí', value: 'Sí'},
    {name: 'No', value: 'No'},
    {name: 'Todas', value: 'Todas'}
  ];

  closedArray: Object[] = [
    {
      name: this.translate.instant('dashboard.yes'),
      value: this.translate.instant('dashboard.yes')
    },
    {
      name: this.translate.instant('dashboard.no'),
      value: this.translate.instant('dashboard.no')
    },
    {
      name: this.translate.instant('dashboard.all'),
      value: ''
    }
  ];

  closed: string = this.translate.instant('dashboard.no');

  paidArray: Object[] = [
    {
      name: this.translate.instant('dashboard.yes'),
      value: this.translate.instant('dashboard.yes')
    },
    {
      name: this.translate.instant('dashboard.no'),
      value: this.translate.instant('dashboard.no')
    },
    {
      name: this.translate.instant('dashboard.all'),
      value: ''
    }
  ];

  paid: string = '';
  downloadColumns: string[] = [
    this.translate.instant('dashboard.headquarter'),
    this.translate.instant('dashboard.up'),
    this.translate.instant('dashboard.project'),
    this.translate.instant('dashboard.closed'),
    this.translate.instant('dashboard.task'),
    this.translate.instant('dashboard.user'),
    this.translate.instant('dashboard.date'),
    this.translate.instant('dashboard.velazquez_input'),
    this.translate.instant('dashboard.pay_to'),
    this.translate.instant('dashboard.review'),
    this.translate.instant('dashboard.fund'),
    this.translate.instant('dashboard.just'),
    this.translate.instant('dashboard.amount_with_tax'),
    this.translate.instant('dashboard.tax_type'),
    this.translate.instant('dashboard.amount_no_tax'),
    this.translate.instant('dashboard.description'),
    this.translate.instant('dashboard.invoice_type'),
    this.translate.instant('dashboard.module'),
    this.translate.instant('dashboard.comments'),
    this.translate.instant('dashboard.nr_nights_int'),
    this.translate.instant('dashboard.nr_nights_nat'),
    this.translate.instant('dashboard.paid'),
    this.translate.instant('dashboard.time'),
    this.translate.instant('dashboard.paid_mode'),
    this.translate.instant('dashboard.payroll'),
    this.translate.instant('dashboard.place'),
    this.translate.instant('dashboard.nr_km_can'),
    this.translate.instant('dashboard.nr_km_pe'),
    this.translate.instant('dashboard.unique_identifier')
  ];
  objDateFrom: Date;
  headquarter: any = 'Todos';
  up: any = 'Todas';
  upsFilter: any[];
  projectClass: boolean;
  userClass: boolean;
  taskClass: boolean;
  amountToClass: boolean;
  dateFromClass: boolean;
  dateToClass: boolean;
  datePaidFromClass: boolean;
  datePaidToClass: boolean;
  amountFromClass: boolean;
  system_date: any = new Date();
  paid_modes: any[] = ['Transferencia', 'Domiciliación', 'Efectivo'];
  paid_mode: any = 'Transferencia';
  multiplePaid: boolean = false;
  originalData: ManagerTable[] = [];
  idsPaid: number[] = [];
  idsUnpaid: number[] = [];
  tax_types: any[] = [];
  private taxEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  tax_type: any = 'Todos';
  review: string;
  fund: string;
  just: string;
  previousUrl: string;
  pay_to: string;
  payToClass: boolean;
  totalColumns: Array<any> = [];
  idsToTripExpends = [];
  usersFilter: string[];

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild('dateFrom') dateFromElement: ElementRef;

  @ViewChild('dateTo') dateToElement: ElementRef;

  @ViewChild('datePaidFrom') datePaidFromElement: ElementRef;

  @ViewChild('datePaidTo') datePaidToElement: ElementRef;

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  idsInvoicesToPaid: any[] = [];
  idsInvoicesToUnpaid: any[] = [];

  idsCartripsToPaid: any[] = [];
  idsCartripsToUnpaid: any[] = [];

  idsOvernightsToPaid: any[] = [];
  idsOvernightsToUnpaid: any[] = [];

  displayColumns: any[] = [
                          'headquarter',
                          'up',
                          'project',
                          'velazquez',
                          'provider',
                          'closed',
                          'task',
                          'description',
                          'user',
                          'module',
                          'date',
                          'time',
                          'pay_to',
                          'review',
                          'unique_identifier',
                          'amount_with_tax',
                          'tax_type',
                          'paid',
                          'fund',
                          'just',
                          'select',
                          'actions',
                        ];

  displayedColumns: string[] = [];

  constructor(
    private managerService: ManagerService,
    private translate: TranslateService,
    private toastr: ToastrService,
    private invoicesService: InvoicesService,
    private upService: UpService,
    private dialog: MatDialog,
    private expendService: ExpendService,
    private headquarterService: HeadquartersService,
    private projectService: ProjectService,
    private taxService: TaxService,
    private ColumnService: ColumnService,
    private pdf: PdfService,
    private cartripService: CartripService
  ) {
    if(!this.expendService.getIsManagerDashboard()){
      this.managerService.setVoidFilter();
    }

    const localFilters = window.localStorage.getItem('filters');
    let filters: string[] = localFilters ? JSON.parse(localFilters).filters : this.managerService.getFilters();
    window.localStorage.removeItem('filters');
    let usersArray = filters[0].split(',');
    usersArray.pop();
    this.user = usersArray;
    this.project = filters[1];
    this.task = filters[2];
    this.amountFrom = filters[3];
    this.amountTo = filters[4];
    this.dateFrom = filters[5];
    this.dateTo = filters[6];
    this.module = filters[7] ? filters[7]: '';
    this.up = filters[8]? filters[8]: 'Todas';
    this.closed = filters[9]? filters[9]: 'No';
    this.paid = filters[10]? filters[10]: '';
    this.headquarter = filters[11]? filters[11]: 'Todos';
    this.tax_type = filters[12]? filters[12]: 'Todos';
    this.review = filters[13]? filters[13]: 'Todas';
    this.pay_to = filters[14];
    this.datePaidFrom = filters[15];
    this.datePaidTo = filters[16];
    this.fund = filters[17]? filters[17]: 'Todas';
    this.just = filters[18]? filters[18]: 'Todas';
   }

  ngOnInit(): void {
    this.expendService.setIsManagerDashboard(true);
    this.loadingFlag = true;
    this.ColumnService.getColumns()
      .subscribe(res => {
        console.log(res);
        if(res['columns'].length != 0) {
          this.displayColumns.forEach(element => {
            const filter: any = res['columns'].filter(column => column.name == element);
            this.totalColumns.push(filter[0]);
          });
        }

        console.log(this.totalColumns);

        this.totalColumns = this.totalColumns.map(column => {
          column.value = column.value == 1 ? true: false;
          return column;
        })



        if(res['user_columns'].length != 0) {
          res['user_columns'].forEach(element => {
            if(element.display == 1) {
              this.totalColumns = this.totalColumns.map(column => {
                if(column.id == element.display_column_id) {
                  column.value = true;
                }

                return column;
              })
            }
          });
        }

        this.displayColumns = [];

        this.totalColumns.forEach(element => {
          if(element.value) {
            this.displayedColumns.push(element.name);
          }
        });
      });


    this.taxService.getTaxTypes()
      .subscribe(res => {
        this.tax_types = res['tax_types'];
        this.tax_types.push({id: this.tax_types.length + 1 + '', type: 'Sin valor'});
        this.tax_types.push({id: this.tax_types.length + 1 + '', type: 'Todos'});
      });


    this.upService.getUps()
      .subscribe(res => {
        this.ups = res['ups'].map(element => {
          if(element.name == 'no_defined'){
            element.name = this.translate.instant('dashboard.no_defined_up');
          }
          return element;

        });

        this.upsFilter = this.ups;
        this.upsFilter.push({id: (this.ups.length + 1).toString(), name: 'Todas'});
      });
    this.headquarterService.getHeadquarters()
      .subscribe(res => {
        this.headquarters = res['headquarters'];
        this.headquarters.push({id: (this.ups.length + 1).toString(), name: 'Todos'});
      });

  this.getExpends();


  }

  changeUpDialog(event,id) {
    const dialogRef = this.dialog.open(ChangeUpDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeUpToProjectById(event,id);
      }
    });
  }

  changeUpToProjectById(event, id) {
    this.loadingFlag = true;

    const up = this.ups.filter(element => {
      return element.name == event.value;
    });

    this.projectService.changeUPToProjectById(up[0].id,id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_change'), '', {timeOut: 1500});
          setTimeout(() => {
            this.reload();
          }, 1500);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_change'), '', {timeOut: 1500});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_change'), '', {timeOut: 1500})
      });
  }

  changeCTDialog(event: Event, invoice_id, cartrip_id, overnight_id) {
    const dialogRef = this.dialog.open(ChangeCTDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeHeadquarterToUserById(event, invoice_id, cartrip_id, overnight_id);
      }
    });
  }

  changeHeadquarterToUserById(event, invoice_id, cartrip_id, overnight_id) {
    this.loadingFlag = true;

    const headquarter = this.headquarters.filter(element => {
      return element.name == event.value;
    });

    this.headquarterService.changeHeadquarterToUserById(headquarter[0].id, invoice_id, cartrip_id, overnight_id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('dashboard.success_change'), '', {timeOut: 1500});
          setTimeout(() => {
            this.reload();
          }, 1500);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_change'), '', {timeOut: 1500});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('dashboard.error_change'), '', {timeOut: 1500})
      });
  }

  getExpends() {
    this.loadingFlag = true;
    this.users = [];
    this.managerService.getExpends()
      .subscribe(res => {
        const EXPEND_DATA: ManagerTable[] = res['expends'] ? res['expends']: [];
        this.dataSource.data = EXPEND_DATA.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }).map( element => {
          if(element.up == 'no_defined') {
            element.up = this.translate.instant('dashboard.no_defined_up');
          }
          element.closed = element.closed == '0'? 'No': 'Sí';
          element.paid = element.paid == '0'? 'No': 'Sí';
          element.tax_type = element.tax_type ? element.tax_type + ' %': 'Sin valor';
          element.review = element.review == '1'? 'Sí': 'No';
          element.just = element.url_just && element.url_just.indexOf('http') != -1? 'Sí': 'No';
          if(element.fund == 1){
            element.fund = 'Sí'
          } else {
            element.fund = 'No'
          }
          const auxHeadquarter = this.headquarters.filter(headquarter => {
            return headquarter.id == element.headquarter;
          })

          if(auxHeadquarter.length == 0){
            element.headquarter = 'Sin CT'
          } else {
            element.headquarter = auxHeadquarter[0].name;
          }

          let filter = this.users.filter(user => user == element.user);

          if(filter.length == 0) {
            this.users.push(element.user);
          }

          element.select = false;
          return element;
        });

        this.users.sort();

        this.totalAmount = res['expends'].map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
        this.totalAmountWithoutTax = res['expends'].map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
        this.searchFormInit();
        this.dataSource.filterPredicate = this.getFilterPredicate();
        this.applyFilter();
        this.loadingFlag = false;
        setTimeout(() => {
          if(this.dateFrom)
            this.dateFromElement.nativeElement.value = new Date(this.dateFrom).toLocaleDateString('es-ES');
          if(this.dateTo)
            this.dateToElement.nativeElement.value = new Date(this.dateTo).toLocaleDateString('es-ES');
          if(this.datePaidFrom)
            this.datePaidFromElement.nativeElement.value = new Date(this.datePaidFrom).toLocaleDateString('es-ES');
          if(this.datePaidTo)
            this.datePaidToElement.nativeElement.value = new Date(this.datePaidTo).toLocaleDateString('es-ES');
        }, 300)
      },
        (err) => {
          this.loadingFlag = false;
        });
  }

  searchFormInit() {
    this.objDateFrom = new Date(this.dateFrom);
    this.searchForm = new FormGroup({
      user: new FormControl(this.user == '' ? '' : this.user),
      project: new FormControl(this.project == '' ? '' : this.project),
      task: new FormControl(this.task == '' ? '' : this.task),
      amountFrom: new FormControl(this.amountFrom == '' ? '' : this.amountFrom),
      amountTo: new FormControl(this.amountTo == '' ? '' : this.amountTo),
      dateFrom: new FormControl(this.dateFrom == '' ? '' : new Date(this.dateFrom).toLocaleDateString('es-ES')),
      dateTo: new FormControl(this.dateTo == '' ? '' : new Date(this.dateTo).toLocaleDateString('es-ES')),
      module: new FormControl(this.module == '' ? '': this.module),
      closed: new FormControl(this.closed == '' ? '': this.closed),
      paid: new FormControl(this.paid == '' ? '': this.paid),
      up: new FormControl(this.up == '' ? '': this.up),
      headquarter: new FormControl(this.headquarter == '' ? '': this.headquarter),
      tax_type: new FormControl(this.tax_type == '' ? '': this.tax_type),
      review: new FormControl(this.review == '' ? '': this.review),
      fund: new FormControl(this.fund == '' ? '': this.fund),
      just: new FormControl(this.just == '' ? '': this.just),
      pay_to: new FormControl(this.pay_to == '' ? '' : this.pay_to),
      datePaidFrom: new FormControl(this.datePaidFrom == '' ? '' : new Date(this.datePaidFrom).toLocaleDateString('es-ES')),
      datePaidTo: new FormControl(this.datePaidTo == '' ? '' : new Date(this.datePaidTo).toLocaleDateString('es-ES'))
    });
  }

  applyFilter() {
    const user = this.searchForm.get('user').value;
    const project = this.searchForm.get('project').value;
    const task = this.searchForm.get('task').value;
    const amountFrom = this.searchForm.get('amountFrom').value;
    const amountTo = this.searchForm.get('amountTo').value;
    const dateFrom = this.searchForm.get('dateFrom').value;
    const dateTo = this.searchForm.get('dateTo').value;
    const datePaidFrom = this.searchForm.get('datePaidFrom').value;
    const datePaidTo = this.searchForm.get('datePaidTo').value;
    const module = this.searchForm.get('module').value;
    const closed = this.searchForm.get('closed').value;
    const paid = this.searchForm.get('paid').value;
    const up = this.searchForm.get('up').value;
    const headquarter = this.searchForm.get('headquarter').value;
    const tax_type = this.searchForm.get('tax_type').value;
    const review = this.searchForm.get('review').value;
    const fund = this.searchForm.get('fund').value;
    const just = this.searchForm.get('just').value;
    const pay_to = this.searchForm.get('pay_to').value;

    // Double exclamation mark converts evaluating expression
    // as boolean. Is equivalent to
    // if (project) { this.projectClass = true; } else { this.projectClass = false; }
    this.projectClass = !!project;
    this.userClass = !!user;
    this.taskClass = !!task;
    this.amountFromClass = !!amountFrom;
    this.amountToClass = !!amountTo;
    this.dateFromClass = !!(dateFrom !== 'Invalid Date' && dateFrom);
    this.dateToClass = !!(dateTo !== 'Invalid Date' && dateTo);
    this.datePaidFromClass = !!(datePaidFrom != 'Invalid Date' && datePaidFrom);
    this.datePaidToClass = !!(datePaidTo != 'Invalid Date' && datePaidTo);
    this.payToClass = !!pay_to;

    this.user = user === null || user.length == 0? '' : user;
    this.project = project === null ? '' : project;
    this.task = task === null ? '' : task;
    this.amountFrom = amountFrom === null ? '' : amountFrom;
    this.amountTo = amountTo === null ? '' : amountTo;
    this.module = module === null ? '' : module;
    this.closed = closed === null ? '' : closed;
    this.paid = paid === null ? '' : paid;
    this.up = up === null ? '' : up;
    this.headquarter = headquarter === null ? '': headquarter;
    this.tax_type = tax_type === null ? '': tax_type;
    this.review = review === null ? '': review;
    this.fund = fund === null ? '': fund;
    this.just = just === null ? '': just;
    this.pay_to = pay_to === null ? '' : pay_to;

    if(typeof dateFrom != 'string'){
      this.dateFrom = (dateFrom === null || dateFrom === '') ? '' : dateFrom.format('YYYY-MM-DD');
    }

    if(typeof dateTo != 'string')
      this.dateTo = (dateTo === null || dateTo === '') ? '' : dateTo.format('YYYY-MM-DD');

    if(typeof datePaidFrom != 'string'){
      this.datePaidFrom = (datePaidFrom === null || datePaidFrom === '') ? '' : datePaidFrom.format('YYYY-MM-DD');
    }

    if(typeof datePaidTo != 'string')
      this.datePaidTo = (datePaidTo === null || datePaidTo === '') ? '' : datePaidTo.format('YYYY-MM-DD');

      let users = '';

      if(this.user && this.user.length != 0) {
        this.user.forEach(user => {
          users += user + ',';
        });
      }



    // Create string of our searching values and split if by '$'
    const filterValue = users + '$' + this.project + '$' + this.task + '$' + this.amountFrom + '$' + this.amountTo + '$' + this.dateFrom + '$' + this.dateTo;
    this.dataSource.filter = filterValue.trim().toLowerCase() +  '$' + this.module + '$' + this.closed + '$'+ this.up + '$' + this.paid + '$' + this.headquarter + '$' + this.tax_type + '$' + this.review + '$' + this.pay_to + '$' + this.datePaidFrom + '$' + this.datePaidTo + '$' + this.fund + '$' + this.just;

    // Recalculate total:
    this.totalAmount = this.dataSource.filteredData.map(t => t.amount_with_tax).reduce((acc, value) => +acc + +value, 0);
    this.totalAmountWithoutTax = this.dataSource.filteredData.map(t => t.amount_no_tax).reduce((acc, value) => +acc + +value, 0);
  }

  getFilterPredicate() {
    return (row: ManagerTable, filters: string) => {
      // Split string per '$' to array
      const filterArray = filters.split('$');

      const users = filterArray[0];
      const project = filterArray[1];
      const task = filterArray[2];
      let amountFrom = filterArray[3].replace(",", ".");
      let amountTo = filterArray[4].replace(",", ".");
      let dateFrom = filterArray[5];
      let dateTo = filterArray[6];
      let module = filterArray[7];
      let closed = filterArray[8];
      let up = filterArray[9];
      let paid = filterArray[10];
      let headquarter = filterArray[11];
      let tax_type = filterArray[12];
      let review = filterArray[13];
      const pay_to = filterArray[14];
      let datePaidFrom = filterArray[15];
      let datePaidTo = filterArray[16];
      let fund = filterArray[17];
      let just = filterArray[18];



      const matchFilter = [];

      // Fetch data from row
      const columnUser = row.user;
      const columnProject = row.project;
      const columnTask = row.task;
      const columnAmount = row.amount_with_tax;
      const columnDate = row.date;
      const columnDatePaid = row.time;
      const columnModule = row.module;
      const columnClosed = row.closed;
      const columnUp = row.up;
      const columnPaid = row.paid;
      const columnHeadquarter = row.headquarter;
      const columnTaxType = row.tax_type;
      const columnReview = row.review;
      const columnPayTo = row.pay_to;
      const columnFund = row.fund;
      const columnJust = row.just;


      // Verify fetching data by our searching values
      let customFilterUser = [];
      let usersArray = users.split(',');
      usersArray.pop();
      usersArray.forEach(user => {
        customFilterUser.push(columnUser.toLowerCase().includes(user));
      });
      const customFilterPoject = columnProject.toLowerCase().includes(project);
      const customFilterTask = columnTask.toLowerCase().includes(task);
      let customFilterPayTo;
      let customFilterAmountFrom;
      let customFilterAmountTo;
      let customFilterDateFrom;
      let customFilterDateTo;
      let customFilterDatePaidFrom;
      let customFilterDatePaidTo;
      let customFilterModule;
      let customFilterClosed;
      let customFilterUp;
      let customFilterPaid;
      let customFilterHeadquarter;
      let customFilterTaxType;
      let customFilterReview;
      let customFilterFund;
      let customFilterJust;

      // Setting predetermined values to dates if there is none selected
      dateFrom = Date.parse(dateFrom) ? dateFrom : '2000-01-01';
      dateTo = Date.parse(dateTo) ? dateTo : new Date().toISOString();

      // Check amount_with_tax
      customFilterAmountFrom = +amountFrom <= +columnAmount;
      customFilterAmountTo = +amountTo >= +columnAmount;

      // Check date limits
      customFilterDateFrom = Date.parse(dateFrom) <= Date.parse(columnDate);
      customFilterDateTo = Date.parse(columnDate) <= Date.parse(dateTo) + 86399999;

      // Checking paid dates to set predetermined values
      customFilterDatePaidFrom = !columnDatePaid && !datePaidFrom || (columnDatePaid && Date.parse(datePaidFrom) <= Date.parse(columnDatePaid));
      customFilterDatePaidTo = !columnDatePaid && !datePaidTo || (columnDatePaid && Date.parse(columnDatePaid) <= Date.parse(datePaidTo) + 86399999);

      // Checking equality between two values or the total,
      // used to simplify this part of the filter applying
      const checkEquality = (columnValue, value, allValue?) => columnValue === value || value === allValue;

      customFilterModule = checkEquality(columnModule, module);
      customFilterClosed = checkEquality(columnClosed, closed);
      customFilterPaid = checkEquality(columnPaid, paid);
      customFilterUp = checkEquality(columnUp, up, 'Todas');
      customFilterHeadquarter = checkEquality(columnHeadquarter, headquarter, 'Todos');
      customFilterTaxType = columnTaxType.includes(tax_type) || tax_type === 'Todos';
      customFilterReview = checkEquality(columnReview, review, 'Todas');
      customFilterFund = checkEquality(columnFund, fund, 'Todas');
      customFilterJust = checkEquality(columnJust, just, 'Todas');
      customFilterPayTo = pay_to ? (columnPayTo ? columnPayTo.toLowerCase().includes(pay_to.toLowerCase()): false) : true;

      // Push boolean values into array
      let customFilterUserResult = customFilterUser.length != 0? false: true;
      customFilterUser.forEach(filter => {
         customFilterUserResult = customFilterUserResult || filter;
      });
      matchFilter.push(customFilterUserResult);
      matchFilter.push(customFilterPoject);
      matchFilter.push(customFilterTask);
      matchFilter.push(customFilterPayTo);
      if(module) {
        matchFilter.push(customFilterModule);
      }
      if(closed) {
        matchFilter.push(customFilterClosed);
      }
      if(paid) {
        matchFilter.push(customFilterPaid);
      }
      if(up) {
        matchFilter.push(customFilterUp);
      }
      if(headquarter) {
        matchFilter.push(customFilterHeadquarter);
      }
      if(tax_type) {
        matchFilter.push(customFilterTaxType);
      }
      if(review) {
        matchFilter.push(customFilterReview);
      }
      if(fund) {
        matchFilter.push(customFilterFund);
      }
      if(just) {
        matchFilter.push(customFilterJust);
      }
      if (amountFrom) {
        matchFilter.push(customFilterAmountFrom);
      }
      if (amountTo) {
        matchFilter.push(customFilterAmountTo);
      }
      if (Date.parse(dateFrom)) {
        matchFilter.push(customFilterDateFrom);
      }
      if (Date.parse(dateTo)) {
        matchFilter.push(customFilterDateTo);
      }
      if (Date.parse(datePaidFrom)) {
        matchFilter.push(customFilterDatePaidFrom);
      }
      if (Date.parse(datePaidTo)) {
        matchFilter.push(customFilterDatePaidTo);
      }
      // Return true if all values in array is true
      // else return false
      return matchFilter.every(Boolean);
    };
  }

  getCSV() {
    let data = this.dataSource.filteredData.map(el => {
      // const obj = {};
      // Object.keys(el).forEach(key => {
      //   obj[this.translate.instant(`dashboard.${key}`)] = el[key];
      // });
      // return obj;
      return {
        [this.translate.instant('dashboard.project')]: el.project,
        [this.translate.instant('dashboard.task')]: el.task,
        [this.translate.instant('dashboard.user')]: el.user,
        [this.translate.instant('dashboard.date')]: el.date,
        [this.translate.instant('dashboard.amount_with_tax')]: (+el.amount_with_tax).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.tax_type')]: el.tax_type,
        [this.translate.instant('dashboard.amount_no_tax')]: (+el.amount_no_tax).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.description')]: el.description,
        [this.translate.instant('dashboard.invoice_type')]: el.invoice_type ? this.translate.instant('invoices.' + el.invoice_type) : '',
        [this.translate.instant('dashboard.unique_identifier')]: el.unique_identifier,
        [this.translate.instant('dashboard.comments')]: el.comments,
        [this.translate.instant('dashboard.nr_nights_int')]: (+el.nr_nights_int).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.nr_nights_nat')]: (+el.nr_nights_nat).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.paid')]: el.paid,
        [this.translate.instant('dashboard.payroll')]: el.payroll,
        [this.translate.instant('dashboard.place')]: el.place,
        [this.translate.instant('dashboard.nr_km_can')]: (+el.nr_km_can).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.nr_km_pe')]: (+el.nr_km_pe).toLocaleString('es-ES'),
        [this.translate.instant('dashboard.module')]: el.module,
        [this.translate.instant('dashboard.closed')]: el.closed,
        [this.translate.instant('dashboard.up')]: el.up,
        [this.translate.instant('dashboard.headquarter')]: this.translate.instant('dashboard.' + el.headquarter),
        [this.translate.instant('dashboard.velazquez_input')]: el.velazquez_input,
        [this.translate.instant('dashboard.review')]: el.review,
        [this.translate.instant('dashboard.fund')]: el.fund,
        [this.translate.instant('dashboard.just')]: el.just,
        [this.translate.instant('dashboard.pay_to')]: el.pay_to,
        [this.translate.instant('dashboard.time')]: el.time,
        [this.translate.instant('dashboard.paid_mode')]: el.paid_mode
      };
    });

    let fields = this.downloadColumns;
    let opts = { fields, delimiter: ';', withBOM: true };
    let CSV = json2csv.parse(data, opts);
    let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
    return output;
  }

  downloadCSV() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 1500 });

    let output = this.getCSV();
    saveAs(output, 'gastos.csv');

    this.loadingFlag = false;
    this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 1500 });
  }

  downloadFull() {
    this.loadingFlag = true;
    this.toastr.warning(this.translate.instant('invoices.downloading_csv'), '', { timeOut: 1500 });

    // Get CSV:
    let output = this.getCSV();
    // Create ZIP:
    var zip = new JSZip();
    // Add CSV:
    zip.file('gastos.csv', output);
    // Create image folder:
    var img = zip.folder("invoices_img");
    // Get all images by Id:
    let ids = this.dataSource.filteredData.map(el => {
      let tObj = [];
      if(el.invoice_id){
        tObj.push(el.invoice_id);
      }

      return tObj;
    });

    let idKMs = this.dataSource.filteredData.map(el => {
      let tObj = [];
      if(el.cartrip_id){
        tObj.push(el.cartrip_id);
      }

      return tObj;
    });

    ids = ids.filter(n => n.length != 0);
    idKMs = idKMs.filter(n => n.length != 0);

    this.cartripService.downloadCartripImages(idKMs.toString())
        .subscribe(res => {
          res['cartrip_just'].forEach(el => {
            let el_string = el.just_url.toString();
            //Dowload pdf
            if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
              const ext = '.pdf';
              img.file(el.unique_identifier + '_km_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
            //Download img
            } else {
              let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
              img.file(el.unique_identifier + '_km_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
            }
          });
        });

    this.invoicesService.downloadInvoicesImages(ids.toString())
      .subscribe(res => {
        // Loop res:
        if(res['invoices'])
        res['invoices'].forEach(el => {
          let el_string = el.image_url.toString();
          //Dowload pdf
          if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + '_fact' + ext, el.image_url.replace('data:application/pdf;base64,', ""), { base64: true });
          //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '_fact' + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });

        if(res['justifications'])
        res['justifications'].forEach(el => {
          let el_string = el.just_url.toString();
          //Dowload pdf
          if(el_string.indexOf('data:application/pdf;base64,') !== -1) {
            const ext = '.pdf';
            img.file(el.unique_identifier + '_just' + ext, el.just_url.replace('data:application/pdf;base64,', ""), { base64: true });
          //Download img
          } else {
            let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
            img.file(el.unique_identifier + '_just' + '.' + ext, el.just_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
          }
        });


        // Generate final ZIP file:
        zip.generateAsync({ type: "blob" }).then(function (content) {
          // see FileSaver.js
          saveAs(content, "gastos.zip");
        });

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 1500 });
      }, err => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 6000 });
      });
  }

  refreshSelected() {
    this.dataSource.data.forEach(element => {
      element.select = false;
    });
    this.idsInvoicesToPaid = [];
    this.idsInvoicesToUnpaid = [];
    this.idsCartripsToPaid = [];
    this.idsCartripsToUnpaid = [];
    this.idsOvernightsToPaid = [];
    this.idsOvernightsToUnpaid = [];
    this.multiplePaid = false;
  }

  changePaid(element) {
    if(!element.select){
      this.idsToTripExpends.push(element);
      if(element.paid == 'No'){
        if(element.invoice_id){
          const index = this.idsInvoicesToUnpaid.indexOf(element.invoice_id);
          if(index !== -1){
            this.idsInvoicesToUnpaid.splice(index, 1);
          }
          this.idsInvoicesToPaid.push(element.invoice_id);
        } else if(element.overnight_id) {
          const index = this.idsOvernightsToUnpaid.indexOf(element.overnight_id);
          if(index !== -1){
            this.idsOvernightsToUnpaid.splice(index, 1);
          }
          this.idsOvernightsToPaid.push(element.overnight_id);
        } else if(element.cartrip_id) {
          const index = this.idsCartripsToUnpaid.indexOf(element.cartrip_id);
          if(index !== -1){
            this.idsCartripsToUnpaid.splice(index, 1);
          }
          this.idsCartripsToPaid.push(element.cartrip_id);
        }
      } else {
        if(element.invoice_id){
          const index = this.idsInvoicesToPaid.indexOf(element.invoice_id);
          if(index !== -1){
            this.idsInvoicesToPaid.splice(index, 1);
          }
          this.idsInvoicesToUnpaid.push(element.invoice_id);
        } else if(element.overnight_id) {
          const index = this.idsOvernightsToPaid.indexOf(element.overnight_id);
          if(index !== -1){
            this.idsOvernightsToPaid.splice(index, 1);
          }
          this.idsOvernightsToUnpaid.push(element.overnight_id);
        } else if(element.cartrip_id) {
          const index = this.idsCartripsToPaid.indexOf(element.cartrip_id);
          if(index !== -1){
            this.idsCartripsToPaid.splice(index, 1);
          }
          this.idsCartripsToUnpaid.push(element.cartrip_id);
        }

      }
    } else {
      this.idsToTripExpends = this.idsToTripExpends.filter(trip => trip.id != element.id);
      if(element.invoice_id){
        const index = this.idsInvoicesToPaid.indexOf(element.invoice_id);
        if(index !== -1){
          this.idsInvoicesToPaid.splice(index, 1);
        }

      } else if(element.overnight_id) {
        const index = this.idsOvernightsToPaid.indexOf(element.overnight_id);
        if(index !== -1){
          this.idsOvernightsToPaid.splice(index, 1);
        }

      } else if(element.cartrip_id) {
        const index = this.idsCartripsToPaid.indexOf(element.cartrip_id);
        if(index !== -1){
          this.idsCartripsToPaid.splice(index, 1);
        }

      }

      if(element.invoice_id){
        const index = this.idsInvoicesToUnpaid.indexOf(element.invoice_id);
        if(index !== -1){
          this.idsInvoicesToUnpaid.splice(index, 1);
        }

      } else if(element.overnight_id) {
        const index = this.idsOvernightsToUnpaid.indexOf(element.overnight_id);
        if(index !== -1){
          this.idsOvernightsToUnpaid.splice(index, 1);
        }

      } else if(element.cartrip_id) {
        const index = this.idsCartripsToUnpaid.indexOf(element.cartrip_id);
        if(index !== -1){
          this.idsCartripsToUnpaid.splice(index, 1);
        }

      }

    }
    element.select = !element.select;

  }

  changeUnpaid(element) {
    element.select = !element.select;

  }

  reload() {
    let usersValues = '';
    if(this.user && this.user.length != 0)
    this.user.forEach(user => {
      usersValues += user + ',';
    });
    const filters = {
      filters: [
        usersValues,
        this.project,
        this.task,
        this.amountFrom,
        this.amountTo,
        this.dateFrom,
        this.dateTo,
        this.module,
        this.up,
        this.closed,
        this.paid,
        this.headquarter,
        this.tax_type,
        this.review,
        this.pay_to,
        this.datePaidFrom,
        this.datePaidTo,
        this.fund,
        this.just
      ]
    }

    window.localStorage.setItem('filters', JSON.stringify(filters));
    window.location.reload();
  }

  multipleCheckPaid() {
    this.multiplePaid = !this.multiplePaid;
    this.dataSource.filteredData.forEach(element => {
        this.changePaid(element);
    });
  }

  // multipleCheckUnpaid() {
  //   this.multiplePaid = false;
  //   this.dataSource.filteredData.forEach(element => {
  //     if(element.paid !== "No")
  //       this.changeUnpaid(element);
  //     else
  //       this.changePaid(element);
  //   });
  // }

  showDisabledPaid() {
    return this.idsInvoicesToPaid.length + this.idsOvernightsToPaid.length + this.idsCartripsToPaid.length;
  }

  showDisabledUnpaid() {
    return this.idsCartripsToUnpaid.length + this.idsInvoicesToUnpaid.length + this.idsOvernightsToUnpaid.length;
  }

  openExpendTripDialog() {
    const dialogRef = this.dialog.open(TripExpendsDialog, {
      width: '680px',
      data: {
        user: this.user,
        users: this.users,
        dateFrom: this.dateFrom,
        dateTo: this.dateTo,
        from_to: '',
        project: this.project,
        task: this.task,
        paid_modes: this.paid_modes,
        paid_mode: 'Transferencia bancaria',
        number: this.showDisabledPaid()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let persons = '';
        if(result.user)
          result.user.forEach(element => {
            persons += element + ', '
          });
        if(typeof result.dateFrom == 'string'){
          let auxDate = result.dateFrom.split('-');
          result.dateFrom = auxDate.reverse().join('/');
        } else {
          result.dateFrom = result.dateFrom.format('DD/MM/YYYY');
        }

        if(typeof result.dateTo == 'string'){
          let auxDate = result.dateTo.split('-');
          result.dateTo = auxDate.reverse().join('/');
        } else {
          result.dateTo = result.dateTo.format('DD/MM/YYYY');
        }

        const from_to = result.from_to;
        const projects = result.project;
        const task = result.task;
        const trip_selected = JSON.stringify(this.idsToTripExpends);
        const paid_mode = result.paid_mode;
        this.pdf.generatePDFs(persons, result.dateFrom, result.dateTo, from_to, projects, task, trip_selected, paid_mode)
          .subscribe(res => {
              const zip = new JSZip();
              zip.file('HojaViaje.pdf', res['pdf'], { base64: true });
              res['pdfs'].forEach(element => {
                Object.keys(element).forEach(key => {
                  zip.file(key + '.pdf', element[key], { base64: true });
                })
              })
              zip.generateAsync({ type: "blob" }).then(function (content) {
                // see FileSaver.js
                saveAs(content, "DocumentosViajes.zip");
              });

          });
      }
    });
  }

  paidCostDialog() {
    const dialogRef = this.dialog.open(PaidManagerDialog, {
      width: '500px',
      data: {
        system_date: this.system_date,
        paid_modes: this.paid_modes,
        paid_mode: this.paid_mode,
        number: this.showDisabledPaid()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.paid_mode = result.paid_mode;
        const auxTime = new Date(result.system_date);
        const time = new Date(auxTime.setHours(auxTime.getHours() + 1)).toISOString().replace('T',' ').replace('Z','');
        this.paidCostById(time);

      }
    });
  }

  paidCostById(time) {
    this.loadingFlag = true;
    let numberInvoices = this.idsInvoicesToPaid.length;
    let numberOvernights = this.idsOvernightsToPaid.length;
    let numberCartrips = this.idsCartripsToPaid.length;
    while(this.idsInvoicesToPaid.length != 0){
      let id = this.idsInvoicesToPaid.pop();
      this.expendService.paidCostById(id, 'invoice', time, this.paid_mode)
      .subscribe( res => {
        numberInvoices--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberInvoices--;
        if(numberInvoices == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }

    while(this.idsOvernightsToPaid.length != 0){
      let id = this.idsOvernightsToPaid.pop();
      this.expendService.paidCostById(id, 'overnight', time, this.paid_mode)
      .subscribe( res => {
        numberOvernights--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberOvernights--;
        if(numberOvernights == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }

    while(this.idsCartripsToPaid.length != 0){
      let id = this.idsCartripsToPaid.pop();
      this.expendService.paidCostById(id, 'cartrip', time, this.paid_mode)
      .subscribe( res => {
        numberCartrips--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberCartrips--;
        if(numberCartrips == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }

  }

  unpaidCostDialog(id, type) {
    const dialogRef = this.dialog.open(UnpaidManagerDialog, {
      width: '500px',
      data: {
        number: this.showDisabledUnpaid()
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unpaidCostById(id, type);
      }
    });
  }

  unpaidCostById(id, type) {
    this.loadingFlag = true;
    let numberInvoices = this.idsInvoicesToUnpaid.length;
    let numberOvernights = this.idsOvernightsToUnpaid.length;
    let numberCartrips = this.idsCartripsToUnpaid.length;
    while(this.idsInvoicesToUnpaid.length != 0){
      let id = this.idsInvoicesToUnpaid.pop();
      this.expendService.unpaidCostById(id, 'invoice')
      .subscribe( res => {
        numberInvoices--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberInvoices--;
        if(numberInvoices == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }

    while(this.idsOvernightsToUnpaid.length != 0){
      let id = this.idsOvernightsToUnpaid.pop();
      this.expendService.unpaidCostById(id, 'overnight')
      .subscribe( res => {
        numberOvernights--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberOvernights--;
        if(numberOvernights == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }

    while(this.idsCartripsToUnpaid.length != 0){
      let id = this.idsCartripsToUnpaid.pop();
      this.expendService.unpaidCostById(id, 'cartrip')
      .subscribe( res => {
        numberCartrips--;
        if(res['status'] == 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {
          this.reload();
          // this.expendService.projectCloseEvent.next(true);
        } else if(res['status'] != 1 && numberInvoices == 0 && numberOvernights == 0 && numberCartrips == 0) {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      }, error => {
        numberCartrips--;
        if(numberCartrips == 0){
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_paid'), '', {timeOut: 1500});
        }
      });
    }
  }

  resetFilters() {
    this.searchForm.get('user').setValue('');
    this.searchForm.get('project').setValue('');
    this.searchForm.get('task').setValue('');
    this.searchForm.get('amountFrom').setValue('');
    this.searchForm.get('amountTo').setValue('');
    this.searchForm.get('dateFrom').setValue(null);
    this.searchForm.get('dateTo').setValue(null);
    this.searchForm.get('datePaidFrom').setValue(null);
    this.searchForm.get('datePaidTo').setValue(null);
    this.searchForm.get('module').setValue('');
    this.searchForm.get('closed').setValue('No');
    this.searchForm.get('paid').setValue('');
    this.searchForm.get('up').setValue('Todas');
    this.searchForm.get('headquarter').setValue('Todos');
    this.searchForm.get('tax_type').setValue('Todos');
    this.searchForm.get('review').setValue('Todas');
    this.searchForm.get('fund').setValue('Todas');
    this.searchForm.get('just').setValue('Todas');
    this.applyFilter();
  }

  columnChange(event, column) {
    const checked = event.checked ? 1: 0;
    this.ColumnService.setColumnDisplay(checked, column.id)
      .subscribe(res => {
        //console.log(res);
      });
    this.totalColumns = this.totalColumns.map(element => {
      if(element.id == column.id) {
        element.value = event.checked;
      }

      return element;
    });
    this.displayedColumns = [];
    this.totalColumns.forEach(element => {
      if(element.value) {
        this.displayedColumns.push(element.name);
      }
    });
    this.resetFilters();
  }

  editVelazquezInput(event, element) {
    let id;
    let type;

    if(element.invoice_id){
      type = 'invoice';
      id = element.invoice_id;
    } else if(element.overnight_id){
      type = 'overnight';
      id = element.overnight_id
    } else if(element.cartrip_id){
      type = 'cartrip';
      id = element.cartrip_id
    }
    this.expendService.editVelazquezById(event.target.value, id, type)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('dashboard.success_change_velazquez'), '', {timeOut: 1500});
          // setTimeout(() => {
          //   this.reload();
          // }, 1500);
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_change_velazquez'), '', {timeOut: 1500});
        }
      }, error => {
        this.toastr.error(this.translate.instant('dashboard.error_change_velazquez'), '', {timeOut: 1500})
      });
  }

  editProviderInput(event, element) {
    let id;
    let type;

    if(element.invoice_id){
      type = 'invoice';
      id = element.invoice_id;
    } else if(element.overnight_id){
      type = 'overnight';
      id = element.overnight_id
    } else if(element.cartrip_id){
      type = 'cartrip';
      id = element.cartrip_id
    }
    this.expendService.editProviderById(event.target.value, id, type)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('dashboard.success_change_provider'), '', {timeOut: 1500});
          // setTimeout(() => {
          //   this.reload();
          // }, 1500);
        } else {
          this.toastr.error(this.translate.instant('dashboard.error_change_provider'), '', {timeOut: 1500});
        }
      }, error => {
        this.toastr.error(this.translate.instant('dashboard.error_change_provider'), '', {timeOut: 1500})
      });
  }

  getFilterValue(name: string) {
    const element = this.totalColumns.filter(element => element.name == name);
    return element[0].value;
  }

  ngOnDestroy(): void {
    let usersValues = '';
    if(this.user && this.user.length != 0)
      this.user.forEach(user => {
        usersValues += user + ',';
      });
    this.managerService.setFilters(
      usersValues,
      this.project,
      this.task,
      this.amountFrom,
      this.amountTo,
      this.dateFrom,
      this.dateTo,
      this.module,
      this.up,
      this.closed,
      this.paid,
      this.headquarter,
      this.tax_type,
      this.review,
      this.pay_to,
      this.datePaidFrom,
      this.datePaidTo,
      this.fund,
      this.just
    );
  }

}

