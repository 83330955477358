import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PdfService {

  private API_URL = environment.API_URL;

  constructor(
    private http: HttpClient,
  ) { }

  generatePDFs(persons, dateFrom, dateTo, from_to, projects, task, trip_selected, paid_mode) {
    return this.http.post(`${this.API_URL}/generatepdfs`, {
      persons,
      dateFrom,
      dateTo,
      from_to,
      projects,
      task,
      trip_selected,
      paid_mode
    });
  }
}
