<div class="main-section">
    <h1>{{ 'projects.title_list' | translate }}</h1>

    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag">
        <mat-form-field>
            <input matInput (keyup)="applyFilter($event.target.value)"
                placeholder="{{ 'projects.filter' | translate }}">
        </mat-form-field>

        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="up">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.up' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="outline" class="wrapper">
                        <mat-select [(ngModel)]="element.up" (selectionChange)="changeUpDialog($event, element.id)">
                          <mat-option *ngFor="let up of ups" [value]="up.name">
                            {{up.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
            </ng-container>

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.code' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.code}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.name' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>

            <ng-container matColumnDef="task_amount">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.tasks_amount' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.task_amount}} </td>
            </ng-container>

            <ng-container matColumnDef="fund">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.funded' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.fund == '1'" type="button" mat-icon-button color="accent" (click)="unfundProjectDialog(element.id)" matTooltip="{{ 'projects.unfund_project' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box</mat-icon>
                    </button>

                    <button mat-icon-button *ngIf="element.fund == '0'" type="button" color="accent" (click)="fundProjectDialog(element.id)" matTooltip="{{ 'projects.fund_project' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box_outline_blank</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="closed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projects.closed' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <button *ngIf="element.closed == '0'" type="button" mat-icon-button color="accent" (click)="closeProjectDialog(element.id)" matTooltip="{{ 'projects.close_project' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box_outline_blank</mat-icon>
                    </button>

                    <button mat-icon-button *ngIf="element.closed == '1'" type="button" color="accent" (click)="openProjectDialog(element.id)" matTooltip="{{ 'projects.open_project' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="multibutton">
                    <button mat-icon-button color="accent" routerLink="/project-edit/{{element.id}}"
                        matTooltip="{{ 'projects.edit_project' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>

                    <button mat-icon-button color="accent" routerLink="/task-list/{{element.id}}"
                        matTooltip="{{ 'projects.list_tasks' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>list</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
