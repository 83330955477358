import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { PasswordService } from 'src/app/_services/password.service';

@Component({
  selector: 'app-recover-password',
  templateUrl: './recover-password.component.html',
  styleUrls: ['./recover-password.component.scss']
})
export class RecoverPasswordComponent implements OnInit {
  loadingFlag: boolean = false;
  returnUrl: string;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private translate: TranslateService,
    private passwordService: PasswordService,
    private toastr: ToastrService
  ) {
    // Redirect to profile if already logged in
    if (this.authService.currentUserValue) {
      this.router.navigate(['/profile']);
    }
  }

  ngOnInit() {
    // get return url from route parameters or default to '/profile'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/profile';
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }

    let username = form.value.username;
    this.loadingFlag = true;

    this.passwordService.recoverPassword(username)
      .subscribe(
        (res) => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('recover.email_sent'));
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('recover.username_not_exists'));
          }
          else {
            this.toastr.error(this.translate.instant('recover.error'));
          }
          this.loadingFlag = false;
        },
        error => {
          this.toastr.error(error);
          this.loadingFlag = false;
        }
      );
    form.reset();
  }
}
