import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ManagerTable } from '../manager-list/manager-list.component';

export interface ManagerServiceTable {
  id: number;
  project: string;
  task: string;
  user: string;
  date: string;
  amount: string;
  unique_identifier: string;
}

@Injectable({
  providedIn: 'root'
})
export class ManagerService {

  private user: string;
  private project: string;
  private task: string;
  private amountFrom: string;
  private amountTo: string;
  private dateFrom: string;
  private dateTo: string;
  private module: string;

  API_URL: string = environment.API_URL;
  up: string;
  closed: string;
  paid: string;
  headquarter: any;
  tax_type: any;
  review: any;
  pay_to: any;
  datePaidFrom: any;
  datePaidTo: any;
  fund: any;
  just: any;
  constructor(
    private http: HttpClient
  ) { }

  getExpends(): Observable<ManagerTable[]> {
    return this.http.get<ManagerTable[]>(`${this.API_URL}/expends`);
  }

  setFilters(user: string, project: string, task: string, amountFrom: string, amountTo: string, dateFrom: string, dateTo: string, module: string, up: string, closed: string, paid: string, headquarter: any, tax_type, review, pay_to, datePaidFrom, datePaidTo, fund, just) {
    this.user = user;
    this.project = project;
    this.task = task;
    this.amountFrom = amountFrom;
    this.amountTo = amountTo;
    this.dateFrom = dateFrom;
    this.dateTo = dateTo;
    this.module = module;
    this.up = up;
    this.closed = closed;
    this.paid = paid;
    this.headquarter = headquarter;
    this.tax_type = tax_type;
    this.review = review;
    this.pay_to = pay_to;
    this.datePaidFrom = datePaidFrom;
    this.datePaidTo = datePaidTo;
    this.fund = fund;
    this.just = just;
  }

  getFilters() {
    return [
      this.user,
      this.project,
      this.task,
      this.amountFrom,
      this.amountTo,
      this.dateFrom,
      this.dateTo,
      this.module,
      this.up,
      this.closed,
      this.paid,
      this.headquarter,
      this.tax_type,
      this.review,
      this.pay_to,
      this.datePaidFrom,
      this.datePaidTo,
      this.fund,
      this.just
    ];
  }

  setVoidFilter() {
    this.user = '';
    this.project = '';
    this.task = '';
    this.amountFrom = '';
    this.amountTo = '';
    this.dateFrom = '';
    this.dateTo = '';
    this.module = '';
    this.up = '';
    this.closed = '';
    this.paid = '';
    this.headquarter = '';
    this.tax_type = '';
    this.review = '';
    this.pay_to = '';
    this.datePaidFrom = '';
    this.datePaidTo = '';
    this.fund = '';
    this.just = '';
  }
}
