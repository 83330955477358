<div class="main-section higger-width">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div class="section-header" *ngIf="!loadingFlag">
        <h1>{{ 'agrestatrips.add.title' | translate }}</h1>
    </div>

    <div class="form-container" [hidden]="loadingFlag">
        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center" fxLayout.lt-lg="column">
                <mat-card fxFlex="50%">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" >
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='user' placeholder="{{ 'agrestatrips.user' | translate }}"
                                name="user" readonly required>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto" 
                                   name="project" #projectField="ngModel" 
                                   placeholder="{{ 'agrestatrips.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'agrestatrips.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>
                        
                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-select
                                placeholder="{{ 'agrestatrips.task' | translate }}"
                                name="task_id"
                                [(ngModel)]="task"
                                [disabled]="!tasks || tasks.length === 0"
                            >
                                <mat-option *ngFor="let task of tasks" [value]="task.id"> 
                                    {{ task.name }}
                                </mat-option>
                            </mat-select>
                            <mat-spinner *ngIf="loadingTasksFlag" matSuffix [diameter]="18" style="float: right; margin-left: 8px"></mat-spinner>
                        </mat-form-field>
                    </div>

                    <!-- Vehicle selection -->
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <mat-form-field>
                            <mat-select placeholder="{{ 'agrestatrips.add.vehicles' | translate }}" name="vehicle_id" [(ngModel)]="vehicle_id" required>
                                <mat-option *ngFor="let vehicle of vehicles" [value]="vehicle.id">
                                    {{ vehicle.mat }} - {{ vehicle.model }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Origin and destination information -->
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <div fxFlex="50%">
                            <h3>{{ 'agrestatrips.add.origin_title' | translate }}</h3>
                            <mat-form-field>
                                <input type="number" matInput [(ngModel)]="kmStart" (ngModelChange)="calculateKmTotal()"
                                    placeholder="{{ 'agrestatrips.add.km_start' | translate }}" name="km_start"
                                    value="{{kmStart}}" required>
                            </mat-form-field>

                            <mat-form-field>
                                <input type="date" matInput [(ngModel)]="dateStart" placeholder="{{ 'agrestatrips.add.date_start' | translate }}"
                                    name="date_start" required>
                            </mat-form-field>

                            <mat-form-field>
                                <input type="text" matInput [ngModel]="locationStart"
                                    placeholder="{{ 'agrestatrips.add.location_start' | translate }}" name="location_start"
                                    value="{{locationStart}}" required>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%">
                            <h3>{{ 'agrestatrips.add.destination_title' | translate }}</h3>
                            <mat-form-field>
                                <input type="number" matInput [(ngModel)]="kmEnd" (ngModelChange)="calculateKmTotal()"
                                    placeholder="{{ 'agrestatrips.add.km_end' | translate }}" name="km_end"
                                    value="{{kmEnd}}" required>
                            </mat-form-field>

                            <mat-form-field>
                                <input type="date" matInput [(ngModel)]="dateEnd" placeholder="{{ 'agrestatrips.add.date_end' | translate }}"
                                    name="date_end" required>
                            </mat-form-field>

                            <mat-form-field>
                                <input type="text" matInput [ngModel]="locationEnd"
                                    placeholder="{{ 'agrestatrips.add.location_end' | translate }}" name="location_end"
                                    value="{{locationEnd}}" required>
                            </mat-form-field>
                        </div>
                    </div>

                    <!-- Total km -->
                    <div fxLayoutGap="20px" fxLayout.lt-lg="column">
                        <h3>{{ 'agrestatrips.add.km_total' | translate }}</h3>
                        <mat-form-field>
                            <input type="number" matInput [ngModel]="kmTotal" name="km_total"
                                value="{{kmTotal}}" readonly>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutAlign="start center">
                        <button mat-raised-button class="top-buffer bottom-buffer" type="submit" color="accent"
                            [disabled]="!f.valid || !validateProject">{{ 'agrestatrips.add.save_button' | translate }}</button>
                    </div>
                </mat-card>
                
                <mat-card fxFlex="50%" class="image-card" *ngIf="!noImage">
                    <img class="image" [src]="image">
                    <div class="section-footer" id="flex-start">
                        <p *ngIf="imageError" class="error-p">{{ 'agrestatrips.add.errors.error_image' | translate }}</p>
                        <p *ngIf="extensionError" class="error-p">{{ 'agrestatrips.add.errors.error_extension' | translate }}</p>
                        <div>
                            <input type="file" id="image" [hidden]=true name="image" ngModel
                                (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                            <label class="upload-label" for="image">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'agrestatrips.add.select_image' | translate
                                }}
                            </label>
                        </div>
                    </div>
                </mat-card>

                <mat-card fxFlex="50%" class="no-image-card" *ngIf="noImage">
                    <span>{{ 'agrestatrips.add.no_image' | translate }}</span>
                    <div class="section-footer" id="flex-start">
                        <p *ngIf="imageError" class="error-p">{{ 'agrestatrips.add.errors.error_image' | translate }}</p>
                        <p *ngIf="extensionError" class="error-p">{{ 'agrestatrips.add.errors.error_extension' | translate }}</p>
                        <div>
                            <input type="file" id="image" [hidden]=true name="image" ngModel
                                (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                            <label class="upload-label" for="image">
                                <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'agrestatrips.add.select_image' | translate
                                }}
                            </label>
                        </div>
                    </div>
                </mat-card>
            </div>
        </form>
    </div>

    <div class="section-footer" id="flex-end" *ngIf="!loadingFlag" [class.force-margin-bottom]="!loadingFlag" >
    </div>


    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
