import { Component, OnInit } from "@angular/core";
import { TasksService } from "../../_services/tasks.service";
import { ProjectService } from "../../_services/project.service";
import { TranslateService } from "@ngx-translate/core";
import { AuthenticationService } from "../../_services/authentication.service";
import { FormControl, NgForm } from "@angular/forms";
import { Observable, map, of } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { Place } from "../../_models/place";
import { CartripService } from "../../_services/cartrip.service";
import { AgrestatripService } from "src/app/_services/agrestatrip.service";
import { VehicleService } from "src/app/_services/vehicle.service";

enum KmCalcMethod { TOTAL, START_END };

@Component({
    selector: 'app-agrestatrip-add',
    templateUrl: './agrestatrip-add.component.html',
    styleUrls: ['./agrestatrip-add.component.scss']
})
export class AgrestatripAddComponent implements OnInit {
    loadingFlag: boolean = false;
    loadingTasksFlag: boolean = false;

    user: string;
    project: string;
    projects: any[];
    validateProject: boolean = true;
    projectArrayObs: Observable<any[]>;
    projectId: string;
    task: string = '';
    tasks: any[] = [];

    agrestatrips_date: string;

    vehicles: any[] = [];
    vehicle_id: string;

    kmStart: number;
    dateStart: string;
    locationStart: string;
    
    kmEnd: number;
    dateEnd: string;
    locationEnd: string;
    
    kmTotal: number;

    noImage: boolean = true;
    imageError: boolean = false;
    extensionError: boolean = false;

    image: string = '';

    constructor(
        private taskService: TasksService,
        private projectService: ProjectService,
        private translate: TranslateService,
        private authService: AuthenticationService,
        private agrestatripService: AgrestatripService,
        private vehicleService: VehicleService,
        private toastr: ToastrService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadingFlag = true;
        this.getProjects();
        this.getVehicles();
        this.user = this.authService.currentUserValue.username;
    }

    onSubmit (form: NgForm) {
        this.loadingFlag = true;

        const date = new Date().toISOString().replace('T',' ').replace('z', '').slice(0, -5);

        this.agrestatripService.addAgrestatrip(this.projectId, form.value, this.image, date)
          .subscribe({
            next: res => {
              this.loadingFlag = false;
              this.toastr.success(this.translate.instant('agrestatrips.add.success.success_add'), '', { timeOut: 3000 });
              this.router.navigate(['/agrestatrip-list']);
            },
            error: () => {
              this.loadingFlag = false;
              this.toastr.error(this.translate.instant('agrestatrips.add.errors.error_add'), '', { timeOut: 3000 });
            }
          });
    }

    getVehicles() {
      this.vehicleService.getVehicles()
        .subscribe({
          next: res => {
            if (res['vehicles']) {
              this.vehicles = res['vehicles'];
            } else {
              this.toastr.error(this.translate.instant('agrestatrips.add.errors.error_get_vehicles'), '', { timeOut: 3000 });
            }
            this.loadingFlag = false;
          },
          error: err => {
            this.toastr.error(this.translate.instant('agrestatrips.add.errors.error_get_vehicles'), '', { timeOut: 3000 });
            this.loadingFlag = false;
            console.error(err);
          }
        });
    }

    getProjects() {
        this.projectService.getProjects()
          .subscribe(res => {
            this.projects = res['projects'].map(el => {
              let tObj = {};
              tObj['id'] = el.id;
              tObj['name'] = el.code + ' - ' + el.name;
              return tObj;
            });
          },
            (err) => {
              this.loadingFlag = false;
            });
    }

    onSelectionChange(event: any) {
        this.task = '';
        let name = event.option.value;
        let tempProjId = this.projects.filter(el => el.name == name);
        this.projectId = tempProjId[0].id;
        this.getTasksByProjectId(this.projectId);
    }

    onChangeKmCalcMethod () {
      this.kmTotal = null;
      this.kmStart = null;
      this.kmEnd = null;
    }

    imageChanged(event: any) {
      this.image = '';
      this.noImage = true;
      let fileContent = (event.target as HTMLInputElement).files[0];
      if (!fileContent) {
        this.imageError = true;
        return;
      }
  
      let fileName = event.target.files[0].name;
      let fileExtension = fileName.split('.').pop();
  
      fileExtension = fileExtension.toLowerCase();
  
      if ((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png')) {
        this.imageError = false;
        this.extensionError = false;
  
        const fr = new FileReader();
        fr.onload = () => {
          this.image = fr.result.toString();
          this.noImage = false;
        }
        fr.readAsDataURL(fileContent);
  
      } else if (!((fileExtension == 'jpeg') || (fileExtension == 'jpg') || (fileExtension == 'png'))) {
        this.extensionError = true;
      }
    }

    calculateKmTotal() {
      if (this.kmStart === null || this.kmEnd === null) {
        return;
      }

      if (this.kmStart > this.kmEnd) {
        this.kmTotal = 0;
        return;
      }

      this.kmTotal = this.kmEnd - this.kmStart;
    }

    getTasksByProjectId(id: string) {

      this.loadingTasksFlag = true;

        const object = JSON.stringify({
          id: id,
          offset: false
        });
        this.taskService.getTasksByProjectId(object)
          .subscribe(res => {
            this.tasks = res['tasks'] ? res['tasks']: [];
            if(this.tasks.length > 0){
              this.task = this.tasks[0].id;
            }
            // this.loadingFlag = false;
            this.loadingTasksFlag = false;
          },
            (err) => {
              // this.loadingFlag = false;
            this.loadingTasksFlag = false;
            });
    }

    doFilter() {
        this.projectArrayObs = of(this.projects);
        this.projectArrayObs = this.projectArrayObs
          .pipe(
            map(res => this.filter(res))
          )
    
          this.onBlurProject();
    
      }
    
      filter(projects) {
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name.toLowerCase().includes(this.project.toLowerCase()))
    
      }
    
      onBlurProject() {
        const project = this.filterValidate(this.projects);
        if(project.length == 1) {
          this.getTasksByProjectId(project[0].id);
          this.projectId = project[0].id;
          this.validateProject = true;
        } else{
    
          this.validateProject = false;
        }
    
      }

      filterValidate(projects) {
        return projects.filter(project =>
          // used 'includes' here for demo, you'd want to probably use 'indexOf'
          project.name == this.project
        );
      }
}