<div class="main-section higger-width set-height">

    <div class="header-settings">
        <mat-expansion-panel *ngIf="!loadingFlag" class="settings-filters">
            <mat-expansion-panel-header>
              {{'dashboard.panel_config' | translate}}
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
                <div class="check-flex">
                    <mat-checkbox *ngFor="let column of totalColumns; let i = index" [checked]="column.value" (change)="columnChange($event, column)">{{'dashboard.' + column.name | translate}}</mat-checkbox>
                </div>
            </ng-template>
        </mat-expansion-panel>
    </div>


    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag" class="mat-elevation-z8 filter-box margin-top">
      <h3>{{ 'dashboard.title_list' | translate }} - {{ 'dashboard.filter_dashboard' | translate }}</h3>
        <form [formGroup]="searchForm" class="form-filter" *ngIf="searchForm">
          <div class="search-form">
              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('headquarter')}">
                  <mat-label>{{ 'dashboard.headquarter' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.headquarter' | translate }}" formControlName="headquarter" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.name">
                          <span translate> dashboard.{{headquarter.name}}</span>
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('up')}">
                  <mat-label>{{ 'dashboard.up' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.up' | translate }}" formControlName="up" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let value of upsFilter" [value]="value.name">
                          {{value.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': projectClass, 'display': !getFilterValue('project')}"> <!--[ngClass]="{'highlight': projectClass}"-->
                  <mat-label>{{ 'dashboard.project' | translate }}</mat-label>
                  <input type="text" aria-label="{{ 'dashboard.project' | translate }}" matInput formControlName="project"
                      (keyup)="applyFilter()">
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': userClass, 'display': !getFilterValue('user')}">
                  <mat-label>{{ 'dashboard.user' | translate }}</mat-label>
                  <!-- <input type="text" aria-label="{{ 'dashboard.user' | translate }}" matInput formControlName="user"
                      (keyup)="applyFilter()"> -->
                  <mat-select aria-label="{{ 'dashboard.user' | translate }}" formControlName="user" (selectionChange)="applyFilter()" multiple>
                      <mat-option *ngFor="let user of users" [value]="user">
                          {{user}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': taskClass, 'display': !getFilterValue('task')}">
                  <mat-label>{{ 'dashboard.task' | translate }}</mat-label>
                  <input type="text" aria-label="{{ 'dashboard.task' | translate }}" matInput formControlName="task"
                      (keyup)="applyFilter()">
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': payToClass, 'display': !getFilterValue('pay_to')}">
                  <mat-label>{{ 'dashboard.pay_to' | translate }}</mat-label>
                  <input type="text" aria-label="{{ 'dashboard.pay_to' | translate }}" matInput formControlName="pay_to"
                      (keyup)="applyFilter()">
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('module')}">
                  <mat-label>{{ 'dashboard.module' | translate }}</mat-label>
                  <!-- <input type="text" aria-label="{{ 'dashboard.amount_to' | translate }}" matInput
                      formControlName="amountTo" (keyup)="applyFilter()"> -->
                  <mat-select aria-label="{{ 'dashboard.module' | translate }}" formControlName="module" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let value of moduleArray" [value]="value.value">
                          {{value.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('closed')}">
                  <mat-label>{{ 'dashboard.closed' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.closed' | translate }}" formControlName="closed" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let value of closedArray" [value]="value.value">
                          {{value.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('paid')}">
                  <mat-label>{{ 'dashboard.paid' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.paid' | translate }}" formControlName="paid" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let value of paidArray" [value]="value.value">
                          {{value.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': amountFromClass, 'display': !getFilterValue('amount_with_tax')}">
                  <mat-label>{{ 'dashboard.amount_from_ci' | translate }}</mat-label>
                  <input type="text" aria-label="{{ 'dashboard.amount_from_ci' | translate }}" matInput
                      formControlName="amountFrom" (keyup)="applyFilter()">
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': amountToClass, 'display': !getFilterValue('amount_with_tax')}">
                  <mat-label>{{ 'dashboard.amount_to_ci' | translate }}</mat-label>
                  <input type="text" aria-label="{{ 'dashboard.amount_to_ci' | translate }}" matInput
                      formControlName="amountTo" (keyup)="applyFilter()">
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('tax_type')}">
                  <mat-label>{{ 'dashboard.tax_type' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.tax_type' | translate }}" formControlName="tax_type" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let tax_type of tax_types" [value]="tax_type.type">
                          <span *ngIf="tax_type.type != 'Sin valor' && tax_type.type != 'Todos'">{{tax_type.type}} %</span>
                          <span *ngIf="tax_type.type == 'Sin valor' || tax_type.type == 'Todos'">{{tax_type.type}}</span>
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('review')}">
                  <mat-label>{{ 'dashboard.review' | translate }}</mat-label>
                  <mat-select aria-label="{{ 'dashboard.review' | translate }}" formControlName="review" (selectionChange)="applyFilter()">
                      <mat-option *ngFor="let review of reviews" [value]="review.name">
                          {{review.name}}
                      </mat-option>
                  </mat-select>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': dateFromClass, 'display': !getFilterValue('date') }">
                  <mat-label>{{ 'dashboard.date_from' | translate }}</mat-label>
                  <input matInput formControlName="dateFrom" [matDatepicker]="pickerFrom" (dateInput)="applyFilter()" #dateFrom>
                  <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': dateToClass, 'display': !getFilterValue('date') }">
                  <mat-label>{{ 'dashboard.date_to' | translate }}</mat-label>
                  <input matInput formControlName="dateTo" [matDatepicker]="pickerTo" value="dateTo" (dateInput)="applyFilter()" #dateTo>
                  <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerTo></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': datePaidFromClass, 'display': !getFilterValue('time') }">
                  <mat-label>{{ 'dashboard.date_paid_from' | translate }}</mat-label>
                  <input matInput formControlName="datePaidFrom" [matDatepicker]="pickerPaidFrom" (dateInput)="applyFilter()" #datePaidFrom>
                  <mat-datepicker-toggle matSuffix [for]="pickerPaidFrom"></mat-datepicker-toggle>
                  <mat-datepicker #pickerPaidFrom></mat-datepicker>
              </mat-form-field>

              <mat-form-field class="search-form-field" appearance="outline" [ngClass]="{'highlight': datePaidToClass, 'display': !getFilterValue('time')}">
                  <mat-label>{{ 'dashboard.date_paid_to' | translate }}</mat-label>
                  <input matInput formControlName="datePaidTo" [matDatepicker]="pickerPaidTo" value="dateTo" (dateInput)="applyFilter()" #datePaidTo>
                  <mat-datepicker-toggle matSuffix [for]="pickerPaidTo"></mat-datepicker-toggle>
                  <mat-datepicker #pickerPaidTo></mat-datepicker>
              </mat-form-field>

          </div>
          <div class="column">
            <mat-form-field class="field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('fund')}">
                <mat-label>{{ 'dashboard.fund_filter' | translate }}</mat-label>
                <mat-select aria-label="{{ 'dashboard.fund_filter' | translate }}" formControlName="fund" (selectionChange)="applyFilter()">
                    <mat-option *ngFor="let fund of funds" [value]="fund.name">
                        {{fund.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="field" appearance="outline" [ngClass]="{'highlight': true, 'display': !getFilterValue('just')}">
                <mat-label>{{ 'dashboard.just_filter' | translate }}</mat-label>
                <mat-select aria-label="{{ 'dashboard.just_filter' | translate }}" formControlName="just" (selectionChange)="applyFilter()">
                    <mat-option *ngFor="let just of justs" [value]="just.name">
                        {{just.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
          </div>
        </form>



    </div>

    <div *ngIf="!loadingFlag" class="mat-elevation-z8 filter-box margin-top flex-actions">
        <h4 class="header-actions">{{'dashboard.actions' | translate}}</h4>
        <div class="display-flex">
          <button mat-raised-button class="important-button" (click)="openExpendTripDialog()" [disabled]="idsToTripExpends.length == 0">
              {{ 'dashboard.trip_expend' | translate }}
          </button>

          <button mat-raised-button color="accent" type="button" (click)="resetFilters()">
              {{ "dashboard.reset_filters" | translate }}
          </button>
            <button mat-raised-button color="primary" (click)="downloadCSV()">
                {{ 'dashboard.download_csv' | translate }}
            </button>

            <button mat-raised-button color="primary" (click)="downloadFull()">
                {{ 'dashboard.download_full' | translate }}
            </button>
            <div>
                <button mat-raised-button color="accent" [disabled]="showDisabledPaid() == 0" (click)="paidCostDialog()">
                    <mat-icon matListIcon>check_box</mat-icon>
                    {{ 'dashboard.paid_button' | translate }}
                </button>
                <br>
                <mat-hint class="expends-number-message" *ngIf="showDisabledPaid() != 0">{{'dashboard.elements_paid' | translate}} - {{showDisabledPaid()}}</mat-hint>
            </div>
            <div>
                <button mat-raised-button color="warn" [disabled]="showDisabledUnpaid() == 0" (click)="unpaidCostDialog()">
                    <mat-icon matListIcon>check_box_outline_blank</mat-icon>
                    {{ 'dashboard.unpaid_button' | translate }}
                </button>
                <br>
                <mat-hint class="expends-number-message" *ngIf="showDisabledUnpaid() != 0">{{'dashboard.elements_unpaid' | translate}} - {{showDisabledUnpaid()}}</mat-hint>
            </div>

            <button mat-raised-button [disabled]="showDisabledPaid() == 0 && showDisabledUnpaid() == 0" (click)="refreshSelected()">
                <mat-icon matListIcon>refresh</mat-icon>
                {{ 'dashboard.delete_check' | translate }}
            </button>

        </div>
    </div>

    <div [hidden]="loadingFlag" class="overflow-table">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- Columns -->
            <ng-container matColumnDef="headquarter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.headquarter' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="outline" class="wrapper headquarter">
                        <mat-select [(ngModel)]="element.headquarter" [appStopChangePropagation] (selectionChange)="changeCTDialog($event, element.invoice_id, element.cartrip_id, element.overnight_id)">
                          <mat-option *ngFor="let headquarter of headquarters" [value]="headquarter.name" [disabled]="headquarter.name == 'Todos'">
                            <span translate>users.{{headquarter.name}}</span>
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell">{{ 'dashboard.total' | translate }}</td>
            </ng-container>

            <ng-container matColumnDef="up">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.up' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field appearance="outline" class="wrapper">
                        <mat-select [(ngModel)]="element.up" (selectionChange)="changeUpDialog($event, element.project_id)">
                          <mat-option *ngFor="let up of ups" [value]="up.name" [disabled]="up.name == 'Todas'">
                            {{up.name}}
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell"></td>
            </ng-container>

            <ng-container matColumnDef="project">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.project' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.project}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="fund">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.fund_header' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.fund}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="just">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.just_header' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.just}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="pay_to">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.pay_to' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{element.pay_to}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="closed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.closed' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.closed}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>>

            <ng-container matColumnDef="velazquez">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.velazquez_input' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel="never" appearance="outline" class="wrapper velazquez">
                        <!-- DB uses Character varying (50) for that field -->
                        <input matInput [(ngModel)]="element.velazquez_input" (change)="editVelazquezInput($event, element)">
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="provider">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.provider' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                    <mat-form-field floatLabel="never" appearance="outline" class="wrapper velazquez">
                        <!-- DB uses Character varying (50) for that field -->
                        <input matInput [(ngModel)]="element.provider" (change)="editProviderInput($event, element)">
                    </mat-form-field>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="task">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.task' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.task}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.time' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center">{{element.time | date:'shortDate'}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="unique_identifier">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.unique_identifier' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.unique_identifier}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="user">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.user' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.user}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="module">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.module' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.module}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.date' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.date | date:'shortDate'}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="tax_type">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.tax_type' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center">{{element.tax_type}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="review">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.review' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center">{{element.review}}</td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="amount_with_tax">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.amount_with_tax' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.amount_with_tax | currency:'EUR'}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalAmount | currency:'EUR'}}</td>
            </ng-container>

            <ng-container matColumnDef="amount_no_tax">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.amount_no_tax' | translate }} </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.amount_no_tax | currency:'EUR'}} </td>
                <td mat-footer-cell *matFooterCellDef class="footer-cell text-center">{{totalAmountWithoutTax | currency:'EUR'}}</td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.description' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.description}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="paid">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center"> {{ 'dashboard.paid' | translate }}
                </th>
                <td mat-cell *matCellDef="let element" class="text-center"> {{element.paid}} </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'dashboard.select' | translate }}
                     <button type="button" *ngIf="!multiplePaid" (click)="multipleCheckPaid()" mat-icon-button color="accent"  matTooltip="{{ 'dashboard.multiple_paid' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box_outline_blank</mat-icon>
                    </button>
                    <button type="button" *ngIf="multiplePaid" (click)="multipleCheckPaid()" mat-icon-button color="accent"  matTooltip="{{ 'dashboard.multiple_paid' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>check_box</mat-icon>
                    </button>
                </th>
                <td mat-cell *matCellDef="let element" class="text-right">
                    <button class="aling-margin" *ngIf="!element.select" type="button" mat-icon-button color="accent"  matTooltip="{{ 'dashboard.paid_cost' | translate }}" matTooltipPosition="above" (click)="changePaid(element)">
                        <mat-icon matListIcon>check_box_outline_blank</mat-icon>
                    </button>

                    <button class="aling-margin" mat-icon-button *ngIf="element.select" type="button" color="accent"  matTooltip="{{ 'dashboard.unpaid_cost' | translate }}" matTooltipPosition="above" (click)="changePaid(element)">
                        <mat-icon matListIcon>check_box</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="multibutton">

                    <button *ngIf="element.module == 'Pernocta'" mat-icon-button color="accent" routerLink="/overnight-view/{{element.overnight_id}}"
                        matTooltip="{{ 'dashboard.view_overnight' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>

                    <button *ngIf="element.module == 'Km'" mat-icon-button color="accent" routerLink="/cartrip-view/{{element.cartrip_id}}"
                        matTooltip="{{ 'dashboard.view_cartrip' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>

                    <button  mat-icon-button color="accent" routerLink="/invoice-edit/{{element.invoice_id}}"
                        *ngIf="element.module == 'Factura'" matTooltip="{{ 'dashboard.edit_invoice' | translate }}" matTooltipPosition="above">
                        <mat-icon matListIcon>edit</mat-icon>
                    </button>
                </td>
                <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
        </table>

        <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons class="mat-elevation-z8 bottom-buffer">
        </mat-paginator>
    </div>

    <div *ngIf="!loadingFlag" class="download-row" [class.force-margin-bottom]="!loadingFlag"></div>
</div>





