<h1 mat-dialog-title class="dialog-center">{{ 'dashboard.confirmation_title' | translate }}</h1>
<div mat-dialog-content class="dialog-center">
    <p>{{'dashboard.total_paid_one' | translate}}{{data.number}}{{'dashboard.total_paid_two' | translate}}</p>
    <p>{{'dashboard.dialog_paid_text' | translate}}</p>
    <mat-form-field appearance="outline">
        <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
        <input matInput [(ngModel)]="data.system_date" [matDatepicker]="pickerFrom" #dateFrom>
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <mat-label>{{'dashboard.paid_mode' | translate}}</mat-label>
        <mat-select  [(ngModel)]="data.paid_mode">
            <mat-option *ngFor="let value of data.paid_modes" [value]="value">
                {{value}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onCancelClick()" [innerHTML]="'dashboard.cancel' | translate"></button>
    <button mat-raised-button color="accent" [mat-dialog-close]="data" cdkFocusInitial
        [innerHTML]="'dashboard.yes' | translate"></button>
</div>