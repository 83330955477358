import { Component, Inject, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { VehicleService } from "src/app/_services/vehicle.service";
import { VehicleListComponent } from "../vehicle-list/vehicle-list.component";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: 'delete-vehicle-dialog',
    templateUrl: './delete-vehicle-dialog.html',
    styleUrls: ['./vehicle-edit.component.scss']
})
export class DeleteVehicleDialog {
    constructor(
        public ValidateDialogRef: MatDialogRef<DeleteVehicleDialog>,
        @Inject(MAT_DIALOG_DATA) public validateData: DeleteVehicleDialog
    ) { }

    onCancelClick(): void {
        this.ValidateDialogRef.close();
    }
}

@Component({
    selector: 'app-vehicle-edit',
    templateUrl: './vehicle-edit.component.html',
    styleUrls: ['./vehicle-edit.component.scss']
})
export class VehicleEditComponent implements OnInit {
    protected id: string;
    mat: string;
    model: string;

    previousMat: string;
    previousModel: string;

    loadingFlag: boolean = false;
    allowDelete: boolean = false;

    vehicleList!: VehicleListComponent;

    constructor(
        public dialog: MatDialog,
        private activatedRoute: ActivatedRoute,
        private vehicleService: VehicleService,
        private toastr: ToastrService,
        private translate: TranslateService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.loadingFlag = true;
        this.allowDelete = false;
        this.vehicleList = this.vehicleService.getVehicleList();
        
        const routeParams = this.activatedRoute.snapshot.params;
        this.id = routeParams.id;

        this.getVehicleById(this.id);
    }

    getVehicleById(id: string) {
        this.vehicleService.getVehicleById(id)
          .subscribe(res => {
            this.mat = res['mat'];
            this.model = res['model'];
            this.previousMat = this.mat;
            this.previousModel = this.model;
            this.allowDelete = (+res['registers']) === 0;
            this.loadingFlag = false;
          },
            (err) => {
              this.loadingFlag = false;
              this.toastr.error(err, '', { timeOut: 3000 });
            });
      }

      onSubmit(event: any) {
        this.loadingFlag = true;
    
        this.vehicleService.updateVehicle(this.id, event)
          .subscribe(
            (res: any[]) => {
              if (res && res['status'] == 1) {
                this.loadingFlag = false;
                this.toastr.success(this.translate.instant('vehicles.success_vehicle_edit'), '', { timeOut: 3000 });
              } else if (res && res['status'] == 2) {
                this.toastr.error(this.translate.instant('vehicles.error_vehicle_exists'), '', { timeOut: 3000 });
                this.mat = this.previousMat;
                this.model = this.previousModel;
                this.loadingFlag = false;
              } else {
                this.toastr.error(this.translate.instant('vehicles.error_vehicle_edit'), '', { timeOut: 3000 });
                this.mat = this.previousMat;
                this.model = this.previousModel;
                this.loadingFlag = false;
              }
            },
            error => {
              this.toastr.error(error, '', { timeOut: 3000 });
              this.loadingFlag = false;
            }
        );
    }

    deleteVehicleDialog() {
        const dialogRef = this.dialog.open(DeleteVehicleDialog, {
          width: '300px'
        });
    
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.deleteVehicle(this.id);
          }
        });
    }
    
    deleteVehicle(vehicleId: string) {
        this.loadingFlag = true;
    
        this.vehicleService.deleteVehicle(vehicleId)
          .subscribe(
            (res: any[]) => {
              if (res && res['status'] == 1) {
                this.toastr.success(this.translate.instant('vehicles.success_vehicle_delete'), '', { timeOut: 3000 })
                  .onHidden
                  .subscribe(
                    () => {
                      this.router.navigate(['/vehicle-list']);
                    }
                  );
              } else {
                this.toastr.error(this.translate.instant('vehicles.error_vehicle_delete'), '', { timeOut: 3000 });
                this.loadingFlag = false;
              }
            },
            error => {
              this.toastr.error(String(error), '', { timeOut: 3000 });
              this.loadingFlag = false;
            }
          );
    }
}