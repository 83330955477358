<app-nav></app-nav>

<mat-sidenav-container>
    <mat-sidenav class="sidenav" #sidenav mode="side" [(opened)]="showSideNav" [mode]="mode">
        <mat-nav-list dense>
            <mat-list-item *ngIf="currentUser" role="listitem" class="header-item">{{ 'sidenav.expends' | translate
                }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser" routerLink="/manager-list" routerLinkActive="active">
                <mat-icon matListIcon>receipt</mat-icon> {{ 'sidenav.expends' | translate }}
            </a>
            <a mat-list-item *ngIf="currentUser && (role == 1 || role == 3)" routerLink="/manager-dashboard"
                routerLinkActive="active">
                <mat-icon matListIcon>dashboard</mat-icon> {{ 'sidenav.dashboard' | translate }}
            </a>
            <a mat-list-item *ngIf="currentUser" routerLink="/agrestatrip-list" routerLinkActive="active">
                <mat-icon matListIcon>car_crash</mat-icon> {{ 'sidenav.agrestatrips' | translate }}
            </a>
            <mat-expansion-panel hideToggle class='new-order'>
                <mat-expansion-panel-header class="new-expend-header">
                    <mat-icon matListIcon>add</mat-icon> {{ 'sidenav.new_expend' | translate }}
                </mat-expansion-panel-header>
                <a mat-list-item routerLink="/expend-add" [queryParams]="{expend: 'invoice'}">
                    {{ 'sidenav.invoice' | translate }}
                </a>
                <a mat-list-item routerLink="/expend-add" [queryParams]="{expend: 'overnight'}">
                    {{ 'sidenav.overnight' | translate }}
                </a>
                <a mat-list-item routerLink="/expend-add" [queryParams]="{expend: 'cartrip'}">
                    {{ 'sidenav.cartrip' | translate }}
                </a>
                <a mat-list-item routerLink="/expend-add" [queryParams]="{expend: 'agrestatrip'}">
                    {{ 'sidenav.agrestatrip' | translate }}
                </a>
            </mat-expansion-panel>
            <mat-list-item *ngIf="currentUser && (role == 1 || role == 3)" role="listitem" class="header-item">{{
                'sidenav.overnight_item' | translate }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser && (role == 1 || role == 3)" routerLink="/overnight-list"
                routerLinkActive="active">
                <mat-icon matListIcon>receipt</mat-icon> {{ 'sidenav.overnight_item' | translate }}
            </a>
            <mat-list-item *ngIf="currentUser && (role == 1 || role == 3)" role="listitem" class="header-item">{{
                'sidenav.config_header' | translate }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser && (role == 1 || role == 3)" routerLink="/config"
                routerLinkActive="active">
                <mat-icon matListIcon>settings</mat-icon> {{ 'sidenav.config' | translate }}
            </a>
            <mat-list-item *ngIf="currentUser" role="listitem" class="header-item">{{ 'sidenav.project_and_tasks' |
                translate }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser" routerLink="/project-list" routerLinkActive="active">
                <mat-icon matListIcon>ballot</mat-icon> {{ 'sidenav.project_manage' | translate }}
            </a>
            <a mat-list-item *ngIf="currentUser" routerLink="/project-add" routerLinkActive="active">
                <mat-icon matListIcon>add_box</mat-icon> {{ 'sidenav.project_add' | translate }}
            </a>
            <mat-list-item *ngIf="currentUser" role="listitem" class="header-item">{{ 'sidenav.vehicles' | translate
                }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser" routerLink="/vehicle-list" routerLinkActive="active">
                <mat-icon matListIcon>directions_car</mat-icon> {{ 'sidenav.vehicles_manage' | translate }}
            </a>
            <a mat-list-item *ngIf="currentUser" routerLink="/vehicle-add" routerLinkActive="active">
                <mat-icon matListIcon>add_box</mat-icon> {{ 'sidenav.vehicles_add' | translate }}
            </a>
            <mat-list-item *ngIf="currentUser && role == 1" role="listitem" class="header-item">{{ 'sidenav.users' |
                translate }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser && role == 1" routerLink="/user-list" routerLinkActive="active">
                <mat-icon matListIcon>supervised_user_circle</mat-icon> {{ 'sidenav.users_manage' | translate }}
            </a>
            <mat-list-item *ngIf="currentUser && role == 1" role="listitem" class="header-item">{{ 'sidenav.roles' |
                translate }}</mat-list-item>
            <a mat-list-item *ngIf="currentUser && role == 1" routerLink="/role-list" routerLinkActive="active">
                <mat-icon matListIcon>security</mat-icon> {{ 'sidenav.roles_manage' | translate }}
            </a>
            <a mat-list-item *ngIf="currentUser && role == 1" routerLink="/role-add" routerLinkActive="active">
                <mat-icon matListIcon>add_circle</mat-icon> {{ 'sidenav.roles_add' | translate }}
            </a>
        </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>