<app-nav></app-nav>

<div id="recover-view" fxLayout fxLayoutAlign="center center">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div [hidden]="loadingFlag">
        <mat-card class="recover-card">
            <mat-card-header>
                <mat-card-title>{{ 'recover.recover_password' | translate }}</mat-card-title>
            </mat-card-header>

            <mat-card-content>
                <div fxLayout="row" fxLayoutAlign="center center">
                    <div class='recover-form'>
                        <form (ngSubmit)="onSubmit(f)" #f="ngForm">
                            <mat-form-field>
                                <input type="text" matInput ngModel placeholder="{{ 'recover.username' | translate }}"
                                    name="username" required>
                            </mat-form-field>

                            <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center end">
                                <button mat-raised-button type="submit" color="primary" [disabled]="!f.valid">{{
                                    'recover.recover_password_button' | translate }}</button>

                                <button mat-raised-button color="default" routerLink="/login">{{ 'recover.login' |
                                    translate }}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    
    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>