<div class="main-section">
    <div id="loading-spinner" [hidden]="!loadingFlag">
        <div fxLayout="row" fxLayout.md="column" fxLayoutAlign="center center">
            <mat-spinner></mat-spinner>
        </div>
    </div>

    <div *ngIf="!loadingFlag" class="section-header">
        <h1>{{ 'cartrip.title_view' | translate }}</h1>
        <button mat-stroked-button (click)="backClicked()">
            <mat-icon aria-hidden="false" aria-label="chevron_left">chevron_left</mat-icon>
            {{ 'cartrip.back_button' | translate }}
        </button>
    </div>

    <div class="view-container" [hidden]="loadingFlag" [class.force-margin-bottom]="!loadingFlag">
        <form #f="ngForm" (submit)="onSubmit(f)">
            <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                <mat-card fxFlex="50%" class="info-card">
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]='this.user' placeholder="{{ 'cartrip.user' | translate }}"
                                name="user" value="{{user}}" readonly>
                        </mat-form-field>

                        <mat-form-field *ngIf="!showEditInputs()">
                            <mat-label>{{ 'cartrip.date' | translate }}</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="pickerFrom" placeholder="{{ 'cartrip.date' | translate }}" name="date"  readonly>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom" disabled></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom disabled></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field *ngIf="showEditInputs()">
                            <mat-label>{{ 'cartrip.date' | translate }}</mat-label>
                            <input matInput [(ngModel)]="date" [matDatepicker]="pickerFrom" placeholder="{{ 'cartrip.date' | translate }}" name="date"  required>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom></mat-datepicker>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="!showEditInputs()">
                        <mat-form-field>
                            <input type="text" matInput [matAutocomplete]="auto" [(ngModel)]="project"
                                placeholder="{{ 'cartrip.project' | translate }}" name="project" value="{{project}}"
                                readonly>
                            <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption
                                (optionSelected)="onSelectionChange($event)">
                                <mat-option *ngFor="let project of projects" [value]="project.name">
                                    {{project.name}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>

                        <mat-form-field>
                            <mat-label>{{ 'cartrip.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id" disabled>
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.lt-lg="column" *ngIf="showEditInputs()">
                        <mat-form-field>
                            <input matInput [matAutocomplete]="auto"
                                   name="project" #projectField="ngModel"
                                   placeholder="{{ 'cartrip.project' | translate }}"
                                   [(ngModel)]="project" (ngModelChange)="doFilter()" required>
                            <mat-hint class="error-hint" *ngIf="!validateProject">{{'invoices.error_projet_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSelectionChange($event)">
                            <mat-option *ngFor="let project of projectArrayObs | async" [value]="project.name">
                                {{project.name}}
                            </mat-option>
                        </mat-autocomplete>

                        <mat-form-field>
                            <mat-label>{{ 'cartrip.task' | translate }}</mat-label>
                            <mat-select name="task" [(ngModel)]="task">
                                <mat-option *ngFor="let value of tasks" [value]="value.id" >
                                    {{ value.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                        <mat-form-field *ngIf="!showEditInputs()">
                            <input type="text" matInput [(ngModel)]="description" placeholder="{{ 'cartrip.description' | translate }}"
                                name="description" value="{{description}}" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="showEditInputs()">
                            <input type="text" matInput [(ngModel)]="description" placeholder="{{ 'cartrip.description' | translate }}"
                                name="description" value="{{description}}">
                        </mat-form-field>
                        <mat-form-field *ngIf="!showEditInputs()">
                            <mat-label>{{ 'cartrip.place' | translate }}</mat-label>
                            <mat-select  aria-label="{{ 'cartrip.place' | translate }}" [(ngModel)]="placeId" name='placeId'>
                                <mat-option *ngFor="let value of places" [value]="value.id" disabled>
                                    {{'cartrip.places.' + value.name | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="showEditInputs()">
                            <mat-label>{{ 'cartrip.place' | translate }}</mat-label>
                            <mat-select  aria-label="{{ 'cartrip.place' | translate }}" [(ngModel)]="placeId" name='placeId'>
                                <mat-option *ngFor="let value of places" [value]="value.id">
                                    {{'cartrip.places.' + value.name | translate}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                    </div>

                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="!showEditInputs()">

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_km_can"
                                placeholder="{{ 'cartrip.nr_km_can' | translate }}" name="nr_km_can"
                                value="{{nr_km_can}}" readonly>
                        </mat-form-field>

                        <mat-form-field>
                            <input type="text" matInput [(ngModel)]="nr_km_pe" placeholder="{{ 'cartrip.nr_km_pe' | translate }}"
                                name="nr_km_pe" value="{{nr_km_pe}}" readonly>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column" *ngIf="showEditInputs()">

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="nr_km_can" (ngModelChange)="nightsChange()" placeholder="{{ 'cartrip.nr_km_can' | translate }}" name="nr_km_can" value="{{nr_km_can}}">
                        </mat-form-field>

                        <mat-form-field>
                            <input type="number" matInput [(ngModel)]="nr_km_pe" (ngModelChange)="nightsChange()" placeholder="{{ 'cartrip.nr_km_pe' | translate }}" name="nr_km_pe" value="{{nr_km_pe}}">
                        </mat-form-field>
                    </div>
                    <h6 *ngIf="showEditManager()">{{ 'cartrip.manager_editing' | translate }}</h6>
                    <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">

                        <mat-form-field *ngIf="!showEditManager()">
                            <input type="text" matInput ngModel placeholder="{{ 'cartrip.amount_no_tax' | translate }}"
                                name="amount_no_tax" value="{{amount_no_tax | currency:'EUR'}}" readonly>
                        </mat-form-field>
                        <mat-form-field *ngIf="showEditManager()">
                            <input type="number" matInput [(ngModel)]="amount_no_tax" placeholder="{{ 'cartrip.amount_no_tax' | translate }}"
                                name="amount_no_tax" required>
                        </mat-form-field>
                        <div></div>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="!showEditManager()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value" [disabled]="true">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="true"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="true"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="true">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <div fxLayout="row" fxLayoutGap="20px" *ngIf="showEditManager()">

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid' | translate }}" [(ngModel)]="paid" name="paid" (ngModelChange)="paidChange()">
                                <mat-option *ngFor="let paid of paids" [value]="paid.value">
                                    <span>{{paid.name}}</span>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label>{{ 'dashboard.time' | translate }}</mat-label>
                            <input matInput [(ngModel)]="time" name="time" [disabled]="disbledPaid"  [matDatepicker]="pickerFrom" (ngModelChange)="validatePaid()" #dateFrom>
                            <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                            <mat-datepicker #pickerFrom [disabled]="disbledPaid"></mat-datepicker>
                            <mat-hint class="error-hint" *ngIf="!timeValidate">{{'invoices.error_time_selection' | translate}}</mat-hint>
                        </mat-form-field>

                        <mat-form-field class="input-width">
                            <mat-label class="label-position">{{ 'invoices.paid_mode' | translate }}</mat-label>
                            <mat-select aria-label="{{ 'invoices.paid_mode' | translate }}" [(ngModel)]="paid_mode" name="paid_mode" (ngModelChange)="validatePaid()">
                                <mat-option *ngFor="let paid_mode of paid_modes" [value]="paid_mode" [disabled]="disbledPaid">
                                    <span>{{paid_mode}}</span>
                                </mat-option>
                            </mat-select>
                            <mat-hint class="error-hint" *ngIf="!paidModeValidate">{{'invoices.error_paid_mode_selection' | translate}}</mat-hint>
                        </mat-form-field>
                    </div>
                    <br>
                    <div>
                        <br>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayout.md="column">
                            <div class="section-footer" [class.force-margin-bottom]="!loadingFlag" *ngIf="showEditManager() || showEditInputs()">
                                <button type="button" mat-raised-button color="warn" (click)="deleteCartripDialog()">{{ 'cartrip.delete_button' | translate
                                    }}</button>
                            </div>
                            <div class="section-footer">
                              <button mat-raised-button color="primary" type="button" (click)="downloadFull()">
                                  {{ "invoices.download_invoice" | translate }}
                              </button>
                            </div>
                            <div class="section-footer" *ngIf="showEditManager() || showEditInputs()">
                                <button mat-raised-button type="submit" color="accent" [disabled]="!f.valid || !validateProject || !timeValidate || !paidModeValidate">
                                    {{ "cartrip.title_edit" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </mat-card>
                <mat-card fxFlex="50%" class="image-card">
                  <div class="button-flex">
                    <button mat-raised-button class="border-button highlight-button" type="button">{{'invoices.justification' | translate}}</button>
                  </div>
                  <br>
                  <!-- <mat-chip color="accent" *ngIf="!fund" class="chip-position">{{'invoices.invoice_chip' | translate}}</mat-chip> -->
                  <img class="invoice-image" [src]="sourceImg" *ngIf="(!isPDF)">
                  <!-- pdf component -->
                  <ngx-extended-pdf-viewer class="overflow-pdf"
                    [src]="sourceImg"
                    [showToolbar]="true"
                    [showSidebarButton]="true"
                    [showFindButton]="true"
                    [showPagingButtons]="true"
                    [showZoomButtons]="true"
                    [showPresentationModeButton]="true"
                    [showOpenFileButton]="true"
                    [showPrintButton]="true"
                    [showDownloadButton]="true"
                    [showBookmarkButton]="true"
                    [showSecondaryToolbarButton]="true"
                    [showRotateButton]="true"
                    [showHandToolButton]="true"
                    [showScrollingButton]="true"
                    [showSpreadButton]="true"
                    [showPropertiesButton]="true"
                    [useBrowserLocale]="true" style="display: block; height: 500px;" zoom="75%"
                    *ngIf="isPDF">
                  </ngx-extended-pdf-viewer>
                  <div>
                    <p *ngIf="imageError" class="error-p">{{ 'invoices.error_image' | translate }}</p>
                    <p *ngIf="extensionError" class="error-p">{{ 'invoices.error_extension' | translate }}</p>
                    <div class="image-buttons" *ngIf="showEditManager() || showEditInputs()">
                        <input type="file" id="invoiceImage" [hidden]=true name="invoiceImage" ngModel
                            (change)="imageChanged($event)" accept=".jpg, .jpeg, .png">
                        <label class="upload-label" for="invoiceImage">
                            <mat-icon class="upload-icon">cloud_upload</mat-icon> {{ 'invoices.select_image' | translate
                            }}
                        </label>
                        <input type="file" id="invoicePDF" [hidden]=true name="invoicePDF" ngModel
                            (change)="loadPDF($event)" accept=".pdf">
                        <label class="upload-label label-background-pdf" for="invoicePDF">
                            <mat-icon class="upload-icon">picture_as_pdf</mat-icon> {{ 'invoices.select_pdf' | translate
                            }}
                        </label>
                    </div>
                  </div>
              </mat-card>
            </div>
        </form>
    </div>

    <img class="logo-agresta" src="assets/img/agresta-blanco.png" alt="">
</div>
