import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpService {

  private API_URL = environment.API_URL;
  constructor(
    private http: HttpClient
  ) { }

  getUps() {
    return this.http.get(`${this.API_URL}/ups`);
  }
}
