import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { MustMatch } from 'src/app/_helpers/must-match.validator';
import { PasswordService } from 'src/app/_services/password.service';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit {
  protected hash: string;
  loadingFlag: boolean = false;
  error: boolean = true;

  newPasswordForm: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private passwordService: PasswordService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit() {
    let routeParams = this.activatedRoute.snapshot.params;
    this.hash = routeParams.hash;
    this.loadingFlag = true;

    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });

    this.passwordService.hashCheck(this.hash)
      .subscribe(res => {
        if (res && res['status'] == 1) {
          this.error = false;
        } else {
          this.error = true;
        }
        this.loadingFlag = false;
      }, (err: any) => {
        this.error = true;
        this.loadingFlag = false;
      });
  }

  // Convenience getter for easy access to form fields. e.g: f.confirmPassword instead of newPasswordForm.controls.confirmPassword
  get f() {
    return this.newPasswordForm.controls;
  }

  onSubmit() {
    // Stop here if form is invalid
    if (this.newPasswordForm.invalid) {
      return;
    }

    // Call service if success:
    this.loadingFlag = true;

    this.passwordService.newPassword(this.newPasswordForm.value, this.hash)
      .subscribe(
        res => {
          if (res && res['status'] == 1) {
            this.toastr.success(this.translate.instant('password.password_changed'));
            setTimeout(() => {
              this.router.navigate(['/login']);
            }, 3000);
          } else if (res && res['status'] == 2) {
            this.toastr.error(this.translate.instant('password.user_error'));
          } else if (res && res['status'] == 3) {
            this.toastr.error(this.translate.instant('password.user_not_active'));
          }
          else {
            this.toastr.error(this.translate.instant('password.password_changed_error'));
          }
          this.loadingFlag = false;
        },
        err => {
          this.toastr.error(this.translate.instant('password.password_changed_error'));
          this.loadingFlag = false;
        }
      );

  }

}
