import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import { ManagerService } from 'src/app/_services/manager.service';
import { ProjectService } from 'src/app/_services/project.service';
import { UpService } from 'src/app/_services/up.service';

export interface ProjectTable {
  id: number;
  code: string;
  name: string;
  task_amount: string;
  closed: string;
  up: string;
  up_id: any;
  fund: any;
}

@Component({
  selector: 'change-up-dialog',
  templateUrl: 'change-up-dialog.html',
  styleUrls: ['./project-list.component.scss']
})
export class ChangeUpDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<ChangeUpDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: ChangeUpDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'close-project-dialog',
  templateUrl: 'close-project-dialog.html',
  styleUrls: ['./project-list.component.scss']
})
export class CloseProjectDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<CloseProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: CloseProjectDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'open-project-dialog',
  templateUrl: 'open-project-dialog.html',
  styleUrls: ['./project-list.component.scss']
})
export class OpenProjectDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<OpenProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: OpenProjectDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'unfund-project-dialog',
  templateUrl: 'unfund-project-dialog.html',
  styleUrls: ['./project-list.component.scss']
})
export class UnfundProjectDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<UnfundProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: UnfundProjectDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'fund-project-dialog',
  templateUrl: 'fund-project-dialog.html',
  styleUrls: ['./project-list.component.scss']
})
export class FundProjectDialog {
  constructor(
    public ValidateDialogRef: MatDialogRef<FundProjectDialog>,
    @Inject(MAT_DIALOG_DATA) public validateData: FundProjectDialog
  ) { }

  onCancelClick(): void {
    this.ValidateDialogRef.close();
  }
}

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {
  loadingFlag: boolean = false;
  displayedColumns: string[] = ['up', 'code', 'name', 'task_amount', 'fund', 'closed', 'actions'];
  dataSource = new MatTableDataSource<ProjectTable>();
  ups: any[] = [];
  private upsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private ProjectService: ProjectService,
    private managerService: ManagerService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private toastr: ToastrService,
    private router: Router,
    private upService: UpService
  ) {

  }
  ngOnDestroy(): void {
    this.ProjectService.setProjectList(this);
  }

  @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
    this.dataSource.paginator = paginator;
  }

  @ViewChild(MatSort) set content(sort: MatSort) {
    this.dataSource.sort = sort;
  }

  ngOnInit(): void {
    this.loadingFlag = true;
    this.upService.getUps()
      .subscribe(res => {
        this.ups = res['ups'].map(element => {
          if(element.name == 'no_defined'){
            element.name = this.translate.instant('projects.no_defined_up');
          }
          return element;

        });

        this.upsEvent.next(true);
      });
    this.upsEvent.asObservable()
      .subscribe( res => {
        if(res) {
          this.ProjectService.getProjects()
            .subscribe(res => {
              const PROJECT_DATA: ProjectTable[] = res['projects'];
              this.dataSource.data = PROJECT_DATA.map( element => {
                if(element.up == 'no_defined'){
                  element.up = this.translate.instant('projects.no_defined_up');
                }
                return element;
              });
              this.loadingFlag = false;
            },
              (err) => {
                this.loadingFlag = false;
              });
        }
      });

    this.managerService.setVoidFilter();
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  closeProjectDialog(id) {
    const dialogRef = this.dialog.open(CloseProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.closeProjectById(id);
      }
    });
  }

  closeProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.closeProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_closed'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_closed'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_closed'), '', {timeOut: 3000})
      });
  }

  openProjectDialog(id) {
    const dialogRef = this.dialog.open(OpenProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.openProjectById(id);
      }
    });
  }

  openProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.openProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_open'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_open'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_open'), '', {timeOut: 3000})
      });
  }

  unfundProjectDialog(id) {
    const dialogRef = this.dialog.open(UnfundProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unfundProjectById(id);
      }
    });
  }

  unfundProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.unfundProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_unfund'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {

          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_unfund'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_unfund'), '', {timeOut: 3000})
      });
  }

  fundProjectDialog(id) {
    const dialogRef = this.dialog.open(FundProjectDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.fundProjectById(id);
      }
    });
  }

  fundProjectById(id) {
    this.loadingFlag = true;
    this.ProjectService.fundProjectById(id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_fund'), '', {timeOut: 3000});
          this.ngOnInit();
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_fund'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_fund'), '', {timeOut: 3000})
      });
  }

  changeUpDialog(event,id) {
    const dialogRef = this.dialog.open(ChangeUpDialog, {
      width: '300px'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.changeUpToProjectById(event,id);
      }
    });
  }

  changeUpToProjectById(event, id) {
    // this.loadingFlag = true;

    const up = this.ups.filter(element => {
      return element.name == event.value;
    });

    this.ProjectService.changeUPToProjectById(up[0].id,id)
      .subscribe( res => {
        if(res['status']) {
          this.toastr.success(this.translate.instant('projects.success_change'), '', {timeOut: 3000});
          this.ngOnInit();
          this.ProjectService.projectOpenEvent.next(true);
        } else {
          this.loadingFlag = false;
          this.toastr.error(this.translate.instant('projects.error_change'), '', {timeOut: 3000});
        }
      }, error => {
        this.loadingFlag = false;
        this.toastr.error(this.translate.instant('projects.error_change'), '', {timeOut: 3000})
      });
  }
}
