import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { AgrestatripService } from "../../_services/agrestatrip.service";
import { ToastrService } from "ngx-toastr";
import { UpService } from "../../_services/up.service";
import { BehaviorSubject } from "rxjs";
import * as json2csv from 'json2csv';
import * as JSZip from "jszip";
import { saveAs } from 'file-saver';
import { VehicleService } from "../../_services/vehicle.service";

export interface AgrestatripTable {
    agrestatrip_id: number,
    project: string,
    fund: boolean,
    up: string,
    project_id: number,
    task: string,
    task_id: number,
    user: string,
    km_start: number,
    km_end: number,
    total_km: number,
    closed: boolean | string,
    localization_start: string,
    localization_end: string,
    date_start: string,
    date_end: string,
    created_at: string,
    unique_identifier: string,
    vehicle: string,
    vehicle_id: number,
    can_edit: boolean,
    is_admin: boolean,
    is_manager: boolean
}

@Component({
    selector: 'app-agrestatrip-list',
    templateUrl: './agrestatrip-list.component.html',
    styleUrls: ['./agrestatrip-list.component.scss'],
})
export class AgrestatripListComponent implements OnInit, OnDestroy {
    loadingFlag: boolean;
    
    // Variables to store filters data
    user: string = '';
    project: string = '';
    task: string = '';

    vehicle: string = '';

    dateFrom: string = '';
    dateTo: string = '';

    kmFrom: string = '';
    kmTo: string = '';

    totalKm!: number;

    // Filters
    searchForm: FormGroup;

    // Object to insert data into table
    dataSource = new MatTableDataSource<AgrestatripTable>();

    ups: any[] = [];
    private upsEvent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    downloadColumns: string[] = [
        this.translate.instant('dashboard.up'),
        this.translate.instant('dashboard.project'),
        this.translate.instant('dashboard.closed'),
        this.translate.instant('dashboard.task'),
        this.translate.instant('dashboard.user'),
        this.translate.instant('dashboard.vehicle'),
        this.translate.instant('dashboard.start_date'),
        this.translate.instant('dashboard.end_date'),
        this.translate.instant('agrestatrips.localization_start'),
        this.translate.instant('agrestatrips.localization_end'),
        this.translate.instant('agrestatrips.km_start'),
        this.translate.instant('agrestatrips.km_end'),
        this.translate.instant('dashboard.total_km'),
        this.translate.instant('dashboard.unique_identifier'),
        this.translate.instant('agrestatrips.unique_identifier_agrestatrips')
    ];

    closedArray: Object[] = [
        {
            name: this.translate.instant('manager.yes'),
            value: this.translate.instant('manager.yes')
        },
        {
            name: this.translate.instant('manager.no'),
            value: this.translate.instant('manager.no')
        },
        {
            name: this.translate.instant('manager.all'),
            value: ''
        }
    ];

    vehicleArray: Object[];

    closed: string = this.translate.instant('manager.no');

    objDateFrom: Date;
    up: any = 'Todas';
    upsFilter: any[];

    @ViewChild(MatPaginator) set matPaginator(paginator: MatPaginator) {
        this.dataSource.paginator = paginator;
    }

    @ViewChild('dateFrom') dateFromElement: ElementRef;

    @ViewChild('dateTo') dateToElement: ElementRef;

    @ViewChild('kmFrom') kmFromElement: ElementRef;

    @ViewChild('kmTo') kmToElement: ElementRef;

    @ViewChild(MatSort) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    displayedColumns: string[] = [
        'up',
        'project',
        'closed',
        'task',
        'user',
        'vehicle',
        'startDate',
        'endDate',
        'totalKm',
        'actions'
    ];

    constructor(
        private agrestatripService: AgrestatripService,
        private translate: TranslateService,
        private upService: UpService,
        private vehicleService: VehicleService,
        private toastr: ToastrService
    ) {
        if (this.agrestatripService.getIsManagerDashboard()) {
            this.agrestatripService.setVoidFilter();
        }

        let filters: string[] = this.agrestatripService.getFilters();

        this.user = filters[0];
        this.project = filters[1];
        this.task = filters[2];
        this.kmFrom = filters[3];
        this.kmTo = filters[4];
        this.dateFrom = filters[5];
        this.dateTo = filters[6];
        this.up = filters[7] ? filters[7] : 'Todas';
        this.closed = filters[8] ? filters[8] : 'No';
        this.vehicle = filters[9];
    }

    ngOnInit(): void {
        this.agrestatripService.setIsManagerDashboard(false);
        this.loadingFlag = true;

        this.vehicleService.getVehicles()
            .subscribe(res => {
                this.vehicleArray = res["vehicles"].map((vehicle: any) => {
                    return {
                        "name": vehicle.mat + " - " + vehicle.model,
                        "value": vehicle.mat + " - " + vehicle.model
                    };
                });
            });

        this.upService.getUps()
            .subscribe(res => {
                this.ups = res['ups'].map(element => {
                    if (element.name == 'no_defined') {
                        element.name = this.translate.instant('manager.no_defined_up');
                    }
                    return element;
                });

                this.upsFilter = this.ups;
                this.upsFilter.push({ id: this.ups.length.toString(), name: 'Todas' });

                this.upsEvent.next(true);
            });

        this.upsEvent.asObservable()
            .subscribe(res => {
                if (res) {
                    this.getTrips();
                }
            });
    }

    getTrips() {
        this.loadingFlag = true;
        this.agrestatripService.getAgrestatrips()
            .subscribe(res => {
                const EXPEND_DATA: AgrestatripTable[] = res;
                console.log(EXPEND_DATA);
                this.dataSource.data = EXPEND_DATA.sort(function (a, b) {
                    // Turn your strings into dates, and then subtract them
                    // to get a value that is either negative, positive, or zero.
                    return new Date(b.created_at).valueOf() - new Date(a.created_at).valueOf();
                }).map(element => {
                    if (element.up == 'no_defined') {
                        element.up = this.translate.instant('dashboard.no_defined_up');
                    }
                    element.closed = element.closed ? 'No' : 'Sí';

                    return element;
                });

                this.totalKm = EXPEND_DATA.reduce((acc, value) => +acc + +value.total_km, 0);

                this.searchFormInit();
                this.dataSource.filterPredicate = this.getFilterPredicate();
                this.applyFilter();
                this.loadingFlag = false;
                setTimeout(() => {
                    if (this.dateFrom)
                        this.dateFromElement.nativeElement.value = new Date(this.dateFrom).toLocaleDateString('es-ES');
                    if (this.dateTo)
                        this.dateToElement.nativeElement.value = new Date(this.dateTo).toLocaleDateString('es-ES');
                }, 300)
            },
                (err) => {
                    this.loadingFlag = false;
                });
    }

    searchFormInit() {
        this.objDateFrom = new Date(this.dateFrom);
        this.searchForm = new FormGroup({
            up: new FormControl(this.up == '' ? '' : this.up),
            project: new FormControl(this.project == '' ? '' : this.project),
            user: new FormControl(this.user == '' ? '' : this.user),
            task: new FormControl(this.task == '' ? '' : this.task),
            closed: new FormControl(this.closed == '' ? '' : this.closed),
            dateFrom: new FormControl(this.dateFrom == '' ? '' : new Date(this.dateFrom).toLocaleDateString('es-ES')),
            dateTo: new FormControl(this.dateTo == '' ? '' : new Date(this.dateTo).toLocaleDateString('es-ES')),
            vehicle: new FormControl(this.vehicle === '' ? '' : this.vehicle),
            kmFrom: new FormControl(this.kmFrom == '' ? '' : this.kmFrom),
            kmTo: new FormControl(this.kmTo == '' ? '' : this.kmTo)
        });
    }

    applyFilter() {
        const up = this.searchForm.get('up').value;
        const project = this.searchForm.get('project').value;
        const user = this.searchForm.get('user').value;
        const task = this.searchForm.get('task').value;
        const dateFrom = this.searchForm.get('dateFrom').value;
        const dateTo = this.searchForm.get('dateTo').value;
        const kmFrom = this.searchForm.get('kmFrom').value;
        const kmTo = this.searchForm.get('kmTo').value;
        const vehicle = this.searchForm.get('vehicle').value;
        const closed = this.searchForm.get('closed').value;
 
        // '??' means if user is null, then it returns the other option
        // in this case, empty string will be returned if constant is null
        this.user = user ?? '';
        this.project = project ?? '';
        this.task = task ?? '';
        this.kmFrom = kmFrom ?? '';
        this.kmTo = kmTo ?? '';
        this.closed = closed ?? '';
        this.vehicle = vehicle ?? '';
        this.up = up === null ? '' : up;

        if (typeof dateFrom != 'string') {
            this.dateFrom = dateFrom === null ? '' : dateFrom.format('YYYY-MM-DD');
        }


        if (typeof dateTo != 'string') {
            this.dateTo = dateTo === null ? '' : dateTo.format('YYYY-MM-DD');
        }

        // Create string of our searching values and split if by '$'
        const filterValue = this.user + '$' + this.project + '$' + this.task + '$' + this.kmFrom + '$' + this.kmTo + '$' + this.dateFrom + '$' + this.dateTo;
        this.dataSource.filter = filterValue.trim().toLowerCase() + '$' + this.closed + '$' + this.up + '$' + this.vehicle;

        // Recalculate total:
        this.totalKm = this.dataSource.filteredData.reduce((acc, value) => +acc + +value.total_km, 0);
    }

    getFilterPredicate() {
        return (row: AgrestatripTable, filters: string) => {
            // Split string per '$' to array
            const filterArray = filters.split('$');

            const user = filterArray[0];
            const project = filterArray[1];
            const task = filterArray[2];
            let kmFrom = filterArray[3].replace(",", ".");
            let kmTo = filterArray[4].replace(",", ".");
            let dateFrom = filterArray[5];
            let dateTo = filterArray[6];
            let closed = filterArray[7];
            let up = filterArray[8];
            const vehicle = filterArray[9];

            const matchFilter = [];

            // Fetch data from row
            const columnUser = row.user;
            const columnProject = row.project;
            const columnTask = row.task;
            const columnTotalKm = row.total_km;
            const columnDateStart = row.date_start;
            const columnDateEnd = row.date_end;
            const columnClosed = row.closed;
            const columnUp = row.up;
            const columnVehicle = row.vehicle;

            // Verify fetching data by our searching values

            const customFilterUser = columnUser.toLowerCase().includes(user.toLowerCase());
            const customFilterPoject = columnProject.toLowerCase().includes(project.toLowerCase());
            const customFilterTask = columnTask.toLowerCase().includes(task.toLowerCase());
            let customFilterKmFrom;
            let customFilterKmTo;
            let customFilterDateFrom;
            let customFilterDateTo;
            let customFilterClosed;
            let customFilterUp;
            const customFilterVehicle = columnVehicle.toLowerCase().includes(vehicle.toLowerCase());

            // Add maximum and minimum dates if fields are blank
            if (!Date.parse(dateFrom)) {
                dateFrom = '2000-01-01';
            }
            if (!Date.parse(dateTo)) {
                dateTo = new Date().toString();
            }

            // Check km:
            if (+kmFrom <= +columnTotalKm) {
                customFilterKmFrom = true;
            }
            if (+kmTo >= +columnTotalKm) {
                customFilterKmTo = true;
            }

            // Check date limits
            if (
                Date.parse(dateFrom) <= Date.parse(columnDateStart)
                || Date.parse(dateFrom) <= Date.parse(columnDateEnd)
            ) {
                customFilterDateFrom = true;
            }
            // Add miliseconds to just be that dateTo 23:59:59
            if (
                Date.parse(columnDateStart) <= Date.parse(dateTo) + 86399999
                || Date.parse(columnDateEnd) <= Date.parse(dateTo) + 86399999
            ) {
                customFilterDateTo = true;
            }

            if (columnClosed == closed) {
                customFilterClosed = true;
            }

            if (
                columnUp == up
                || up == 'Todas'
            ) {
                customFilterUp = true;
            }

            // Push boolean values into array
            matchFilter.push(customFilterUser);
            matchFilter.push(customFilterPoject);
            matchFilter.push(customFilterTask);
            matchFilter.push(customFilterVehicle);
            if (closed) {
                matchFilter.push(customFilterClosed);
            }
            if (up) {
                matchFilter.push(customFilterUp);
            }
            if (kmFrom) {
                matchFilter.push(customFilterKmFrom);
            }
            if (kmTo) {
                matchFilter.push(customFilterKmTo);
            }
            if (Date.parse(dateFrom)) {
                matchFilter.push(customFilterDateFrom);
            }
            if (Date.parse(dateTo)) {
                matchFilter.push(customFilterDateTo);
            }

            // Return true if all values in array is true
            // else return false
            return matchFilter.every(Boolean);
        };
    }

    getCSV() {
        let data = this.dataSource.filteredData.map(el => {
            let tObj = {};
            tObj[this.translate.instant('dashboard.up')] = el.up;
            tObj[this.translate.instant('dashboard.project')] = el.project;
            tObj[this.translate.instant('dashboard.task')] = el.task;
            tObj[this.translate.instant('dashboard.user')] = el.user;
            tObj[this.translate.instant('dashboard.start_date')] = el.date_start;
            tObj[this.translate.instant('dashboard.end_date')] = el.date_end;
            tObj[this.translate.instant('agrestatrips.localization_start')] = el.localization_start;
            tObj[this.translate.instant('agrestatrips.localization_end')] = el.localization_end;
            tObj[this.translate.instant('agrestatrips.km_start')] = el.km_start;
            tObj[this.translate.instant('agrestatrips.km_end')] = el.km_end;
            tObj[this.translate.instant('dashboard.total_km')] = el.total_km;
            tObj[this.translate.instant('dashboard.unique_identifier')] = el.unique_identifier;
            tObj[this.translate.instant('agrestatrips.unique_identifier_agrestatrips')] = el.unique_identifier + "_agrestatrip";
            tObj[this.translate.instant('dashboard.closed')] = el.closed;
            tObj[this.translate.instant('dashboard.up')] = el.up;
            tObj[this.translate.instant('dashboard.vehicle')] = el.vehicle;
            return tObj;
        });

        let fields = this.downloadColumns;
        let opts = { fields, delimiter: ';', withBOM: true };
        let CSV = json2csv.parse(data, opts);
        let output = new Blob([CSV], { type: 'text/csv;charset=utf-8' });
        return output;
    }

    downloadCSV() {
        this.loadingFlag = true;
        this.toastr.warning(this.translate.instant('agrestatrips.downloading_all_csv'), '', { timeOut: 3000 });

        let output = this.getCSV();
        saveAs(output, 'viajes_vehiculos_agresta.csv');

        this.loadingFlag = false;
        this.toastr.success(this.translate.instant('agrestatrips.downloading_csv_success'), '', { timeOut: 3000 });
    }

    downloadFull() {
        this.loadingFlag = true;
        this.toastr.warning(this.translate.instant('agrestatrips.downloading_csv'), '', { timeOut: 3000 });

        // Get CSV:
        let output = this.getCSV();
        // Create ZIP:
        var zip = new JSZip();
        // Add CSV:
        zip.file('viajes_vehiculos_agresta.csv', output);
        // Create image folder:
        var img = zip.folder("agrestatrips_img");
        // Get all images by Id:
        let ids = this.dataSource.filteredData.map(el => {
            let tObj = [];
            if (el.agrestatrip_id) {
                tObj.push(el.agrestatrip_id);
            }

            return tObj;
        });

        ids = ids.filter(n => n.length != 0);

        this.agrestatripService.downloadAgrestatripImages(ids.toString())
            .subscribe(res => {
                res['images'].forEach(el => {
                    if (!el.image_ok) {
                        return;
                    }

                    let el_string = el.image_url.toString();
                    let ext = el_string.substring(el_string.indexOf("/") + 1, el_string.indexOf(";"));
                    img.file(el.unique_identifier + '_agrestatrip' + '.' + ext, el.image_url.replace(/^data:image\/(png|jpg|jpeg);base64,/, ""), { base64: true });
                });

                // Generate final ZIP file:
                zip.generateAsync({ type: "blob" }).then(function (content) {
                    // see FileSaver.js
                    saveAs(content, "viajes_vehiculos_agresta.zip");
                });

                this.loadingFlag = false;
                this.toastr.success(this.translate.instant('invoices.downloading_csv_success'), '', { timeOut: 3000 });
            }, () => {
                this.loadingFlag = false;
                this.toastr.error(this.translate.instant('invoices.downloading_csv_error'), '', { timeOut: 6000 });
            });
    }

    resetFilters() {
        this.searchForm.get('user').setValue('');
        this.searchForm.get('project').setValue('');
        this.searchForm.get('task').setValue('');
        this.searchForm.get('kmFrom').setValue('');
        this.searchForm.get('kmTo').setValue('');
        this.searchForm.get('dateFrom').setValue(null);
        this.searchForm.get('dateTo').setValue(null);
        this.searchForm.get('closed').setValue('No');
        this.searchForm.get('vehicle').setValue('');
        this.searchForm.get('up').setValue('Todas');
        this.applyFilter();
    }

    ngOnDestroy(): void {
        this.agrestatripService.setFilters(
            this.user,
            this.project,
            this.task,
            this.kmFrom,
            this.kmTo,
            this.dateFrom,
            this.dateTo,
            this.up,
            this.closed,
            this.vehicle
        );
    }
}